import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { REQUESTS_API } from "@/router/BusinessApi";
import { RequestModel } from "@/store/models/request/RequestModel";
import { diagLog } from "@/core/helpers/GlobalHelper";
import NotificationService from "@/buying-teams/services/NotificationService";
import ApiService from "@/core/services/ApiService";

@Module
export default class RequestsModule extends VuexModule {
  toggleRequestStatusLoading = ''

  get getToggleRequestStatusLoading() {
    return this.toggleRequestStatusLoading
  }

  @Mutation
  TOGGLE_REQUEST_STATUS_LOADING(value) {
    this.toggleRequestStatusLoading = value
  }

  @Action
  async createRequest(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(REQUESTS_API.CREATE_REQUEST, data)
        .then(res => {
          diagLog('CREATE_REQUEST = ', res);
          resolve(res.data)
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async updateRequest(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(REQUESTS_API.UPDATE_REQUEST, data)
        .then(res => {
          diagLog('UPDATE_REQUEST = ', res);
          resolve(res.data)
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async getRequests(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(REQUESTS_API.GET_REQUESTS, data)
        .then(res => {
          this.context.commit('SAVE_BUSINESS_REQUESTS_FILTER', res.data.filter)
          resolve(res.data.list.map(el => new RequestModel(el)))
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async getRequestDetails(id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(REQUESTS_API.GET_REQUEST_BY_ID, {
        params: { intent_id: id }
      })
        .then(res => {
          if (res.data) {
            resolve(new RequestModel(res.data))
          } else {
            resolve(null)
          }
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async toggleRequestStatus(params) {
    this.context.commit('TOGGLE_REQUEST_STATUS_LOADING', params.id)
    ApiService.setHeader();

    return new Promise((resolve, reject) => {
      ApiService.post(REQUESTS_API.TOGGLE_REQUEST_STATUS, params)
        .then(res => {
          this.context.commit('TOGGLE_REQUEST_STATUS_LOADING', '')
          resolve(res.status === 200)
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          this.context.commit('TOGGLE_REQUEST_STATUS_LOADING', '')
          reject()
        });
    })
  }
}
