import { RouteGuards } from "@/router/RouteGuards";
import {BusinessIntentsTypeEnum} from "@/store/enums/BusinessIntentsTypeEnum";

const businessRoutes = [
  {
    path: "/business/dashboard",
    name: "business-dashboard",
    component: () => import('@/buying-teams/pages/business/dashboard/BusinessDashboard.vue'),
    redirect: '/business/dashboard/overview',
    children: [
      {
        path: "/business/dashboard/overview",
        name: "business-dashboard-overview",
        component: () =>  import('@/buying-teams/pages/business/dashboard/pages/Overview.vue'),
      },
      {
        path: "/business/dashboard/banks",
        name: "business-dashboard-banks",
        component: () =>  import('@/buying-teams/pages/business/dashboard/pages/Banks.vue'),
      },
      {
        path: "/business/dashboard/product-areas",
        name: "business-dashboard-product-areas",
        component: () =>  import('@/buying-teams/pages/business/dashboard/pages/ProductAreas.vue'),
      },
      {
        path: "/business/dashboard/countries",
        name: "business-dashboard-countries",
        component: () =>  import('@/buying-teams/pages/business/dashboard/pages/Countries.vue'),
      },
      {
        path: "/business/dashboard/product-areas/:productArea",
        name: "business-dashboard-specific-product-areas",
        component: () =>  import('@/buying-teams/pages/business/dashboard/pages/SpecificProductArea.vue'),
      },
      {
        path: "/business/dashboard/banks/:id",
        name: "business-dashboard-specific-banks",
        component: () =>  import('@/buying-teams/pages/business/dashboard/pages/SpecificBank.vue'),
      },
      {
        path: "/business/dashboard/countries/:country",
        name: "business-dashboard-specific-countries",
        component: () =>  import('@/buying-teams/pages/business/dashboard/pages/SpecificCountry.vue'),
      },
      {
        path: "/business/dashboard/compare/banks",
        name: "business-compare-banks",
        component: () => import('@/buying-teams/pages/business/dashboard/pages/BusinessCompareBanks.vue'),
      },
    ]
  },
  {
    path: "/business/questions/:id",
    name: "business-dedicated-questions",
    component: () => import('@/buying-teams/pages/business/question/DedicatedQuestion.vue'),
  },
  {
    path: "/business/feedback/requests",
    name: "business-requests",
    component: () => import("@/buying-teams/pages/business/requests/BusinessRequests.vue")
  },
  {
    path: "/business/feedback/request/:id",
    name: "request",
    component: () => import("@/buying-teams/pages/business/requests/BusinessSingleRequest.vue")
  },
  {
    path: "/business/feedback/request/create",
    name: "create-request",
    meta: {
      createType: BusinessIntentsTypeEnum.REQUEST
    },
    component: () => import("@/buying-teams/shared-components/request-idea/CreateRequestOrIdea.vue")
  },
  {
    path: "/business/feedback/request/:id/edit",
    name: "edit-request",
    meta: {
      editType: BusinessIntentsTypeEnum.REQUEST
    },
    component: () => import("@/buying-teams/shared-components/request-idea/EditRequestOrIdea.vue")
  },
  {
    path: "/business/feedback/ideas",
    name: "business-ideas",
    component: () => import("@/buying-teams/pages/business/ideas/BusinessIdeas.vue")
  },
  {
    path: "/business/feedback/idea/:id",
    name: "idea",
    component: () => import("@/buying-teams/pages/business/ideas/BusinessSingleIdea.vue")
  },
  {
    path: "/business/feedback/idea/:id/edit",
    name: "edit-idea",
    meta: {
      editType: BusinessIntentsTypeEnum.IDEA
    },
    component: () => import("@/buying-teams/shared-components/request-idea/EditRequestOrIdea.vue")
  },
  {
    path: "/business/feedback/idea/create",
    name: "create-idea",
    meta: {
      createType: BusinessIntentsTypeEnum.IDEA
    },
    component: () => import("@/buying-teams/shared-components/request-idea/CreateRequestOrIdea.vue")
  },
  {
    path: "/business/feedback/feedback-sessions",
    name: "business-feedbacks",
    component: () => import("@/buying-teams/pages/business/feedbacks/BusinessFeedbacks.vue")
  },
  {
    path: "/business/feedback/feedback-session/:id/result",
    name: "feedback",
    component: () => import("@/buying-teams/pages/business/feedbacks/BusinessSingleFeedback.vue")
  },
  {
    path: "/business/feedback/feedback-session/create",
    name: "create-feedback",
    component: () => import("@/buying-teams/pages/business/feedbacks/feedbacks-creation/BusinessFeedbackCreate.vue")
  },
  {
    path: "/business/teams/all",
    name: "business-teams",
    component: () => import("@/buying-teams/pages/business/teams/Teams.vue")
  },
  {
    path: "/business/teams/user/:id",
    name: "business-teams-user",
    component: () => import("@/buying-teams/pages/business/teams/SingleUser.vue")
  },
  {
    path: "/business/reports/custom-reports",
    name: "custom-reports",
    component: () => import("@/buying-teams/pages/business/reports/CustomReports.vue")
  },
  // {
  //   path: "/business/reports/market-reports",
  //   name: "market-reports",
  //   component: () => import("@/buying-teams/pages/business/reports/MarketReports.vue")
  // },
  {
    path: "/business/settings/business-settings",
    redirect: "/business/business-settings/overview",
    name: "settings",
    meta: {
      guards: [RouteGuards.ADMIN]
    },
    component: () => import("@/buying-teams/pages/business/settings/business/BusinessSettings.vue"),
    children: [
      {
        path: "/business/settings/business-settings/overview",
        name: "Overview",
        meta: {
          guards: [RouteGuards.ADMIN]
        },
        component: () => import("@/buying-teams/pages/business/settings/business/Overview.vue")
      },
      {
        path: "/business/settings/business-settings/settings",
        name: "Settings",
        meta: {
          guards: [RouteGuards.ADMIN]
        },
        component: () => import("@/buying-teams/pages/business/settings/business/Settings.vue")
      },
      {
        path: "/business/settings/business-settings/registered-banks",
        name: "RegisteredBanks",
        meta: {
          guards: [RouteGuards.ADMIN]
        },
        component: () => import("@/buying-teams/pages/business/settings/business/RegisteredBanks.vue")
      },
      {
        path: "/business/settings/business-settings/permission-groups",
        name: "business-settings-permission-groups",
        meta: {
          guards: [RouteGuards.ADMIN]
        },
        component: () => import("@/buying-teams/pages/business/settings/business/BusinessPermissionGroups.vue")
      },
      // {
      //   path: "/business/settings/business-settings/notifications",
      //   name: "Notifications",
      //   meta: {
      //     guards: [RouteGuards.ADMIN]
      //   },
      //   component: () => import("@/buying-teams/pages/business/settings/business/Notifications.vue")
      // },
    ]
  },
  {
    path: "/business/settings/business-settings/permission-groups/add",
    name: "business-settings-permission-groups-add",
    meta: {
      guards: [RouteGuards.ADMIN]
    },
    component: () => import("@/buying-teams/pages/business/settings/business/BusinessAddPermissionGroup.vue"),
  },
  {
    path: "/business/settings/business-settings/permission-groups/edit/:id",
    name: "business-settings-permission-groups-edit",
    meta: {
      guards: [RouteGuards.ADMIN]
    },
    component: () => import("@/buying-teams/pages/business/settings/business/BusinessEditPermissionGroup.vue"),
  },
  {
    path: "/business/settings/user-settings",
    redirect: "/business/user-settings/overview",
    name: "user-settings",
    component: () => import("@/buying-teams/pages/business/settings/user/UserSettings.vue"),
    children: [
      {
        path: "/business/settings/user-settings/overview",
        name: "user-setting-overview",
        component: () => import("@/buying-teams/pages/business/settings/user/Overview.vue")
      },
      {
        path: "/business/settings/user-settings/settings",
        name: "user-setting-settings",
        component: () => import("@/buying-teams/pages/business/settings/user/Settings.vue")
      },
      // {
      //   path: "/business/settings/user-settings/notifications",
      //   name: "user-settings-notifications",
      //   component: () => import("@/buying-teams/pages/business/settings/user/Notifications.vue")
      // },
      {
        path: "/business/settings/user-settings/sharing-policy",
        name: "user-setting-sharing-policy",
        component: () => import("@/buying-teams/pages/business/settings/user/SharingPolicy.vue")
      }
    ]
  }
];

// TODO add (business, admin, super admin) guards
businessRoutes.forEach((route: any) => {
  if (!route["meta"]) {
    route["meta"] = {guards: [RouteGuards.AUTH, RouteGuards.BUSINESS]}
  } else {
    if (route["meta"].guards) {
      route["meta"].guards.push(RouteGuards.AUTH, RouteGuards.BUSINESS);
    } else {
      route["meta"].guards = [RouteGuards.AUTH, RouteGuards.BUSINESS];
    }
  }
});

export default businessRoutes;
