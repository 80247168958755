import { BaseBusinessFilterModel } from "@/store/models/base/BaseBusinessFilterModel";

export class BusinessIdeaFilterModel extends BaseBusinessFilterModel {
  display_closed_items: boolean;

  constructor(obj: any) {
    super(obj);

    this.display_closed_items = obj['display_closed_items'] || false
  }
}
