import { DateRangePickerInterface } from "@/store/interfaces/DateTimeInterface";
import { checkIfObject } from "@/core/helpers/GlobalHelper";
import { DEFAULT_TIME_PERIOD } from "@/store/modules/bank/BankFiltersModule";

export class BankOverviewDashboardFilterModel {
    customers: any[];
    time_period: DateRangePickerInterface;
    tiers: number[];
    customers_hq: string[];
    industries: string[];
    countries: string[];
    product_areas: string[];

    constructor(obj: any) {
        this.customers = obj.customers || [];
        this.time_period = checkIfObject(obj.time_period) ? obj.time_period : DEFAULT_TIME_PERIOD;
        this.tiers = obj.tiers || [];
        this.customers_hq = obj.head_office_countries || obj.customers_hq || [];
        this.countries = obj.bank_countries || obj.countries || [];
        this.industries = obj.industries || [];
        this.product_areas = obj.product_areas || [];
    }
}
