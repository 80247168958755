import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { API_TEAMS, PROFILE_API } from "@/router/BusinessApi";
import { diagLog } from "@/core/helpers/GlobalHelper";
import { UserModel } from "@/store/models/UserModel";
import { InvitedUserModel } from "@/store/models/base/InvitedUserModel";
import ApiService from "@/core/services/ApiService";
import NotificationService from "@/buying-teams/services/NotificationService";
import { BusinessSettingsPermissionGroupsModel } from "@/store/models/business/BusinessTeamUserModel";

@Module
export default class BusinessModule extends VuexModule {

  @Action
  async inviteUser(payload) {
    ApiService.setHeader();

    return new Promise((resolve, reject) => {
      ApiService.post(API_TEAMS.INVITE_USER, payload)
        .then(res => {
          resolve(res.data.id);
        })
        .catch(err => {
          console.log("err = ", err.response);
          NotificationService.swalError(err);
          reject();
        });
    });
  }

  @Action
  async getTeamList(filter) {
    ApiService.setHeader();

    return new Promise((resolve, reject) => {
      ApiService.post(API_TEAMS.GET_TEAM_LIST, filter)
        .then(res => {
          diagLog("GET_TEAM_LIST = ", res.data);
          this.context.commit("SAVE_BUSINESS_TEAMS_FILTER", filter.filter);
          const data = {
            team: res.data.team.map(el => new UserModel(el)),
            invited: res.data.invited.map(el => new InvitedUserModel(el)),
            permissionGroups: (res.data?.permission_groups || []).map(item => {
              return new BusinessSettingsPermissionGroupsModel(item);
            })
          }
          resolve(data);
        })
        .catch(err => {
          console.log("err = ", err);
          NotificationService.swalError(err);
          reject();
        });
    });
  }

  @Action
  async getTeamDetails(id) {
    ApiService.setHeader();
    let params = {
      id: id
    };
    return new Promise((resolve, reject) => {
      ApiService.post(API_TEAMS.GET_TEAM_DETAILS, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("err = ", err);
          NotificationService.swalError(err);
          reject();
        });
    });
  }

  @Action
  getTeamUserStats(user_id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(PROFILE_API.PROFILE_STATS, user_id ? { user_id } : {})
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async deleteUser(id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_TEAMS.DELETE_USER, {id: id})
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(err => {
          console.log("err = ", err);
          NotificationService.swalError(err);
          reject();
        });
    });
  }

  @Action
  async unblockUser(id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_TEAMS.UNBLOCK_USER, { id: id })
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(err => {
          console.log("err = ", err);
          NotificationService.swalError(err);
          reject();
        });
    });
  }

  @Action
  async blockUser(id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_TEAMS.BLOCK_USER, { id: id })
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(err => {
          console.log("err = ", err);
          NotificationService.swalError(err);
          reject();
        });
    });
  }

  @Action
  async updateUserDetails(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_TEAMS.UPDATE_USER_DETAILS, data)
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(err => {
          console.log("err = ", err);
          NotificationService.swalError(err);
          reject();
        });
    });
  }
}
