import Swal from "sweetalert2"

const confirmDialogOptions = {
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'question',
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    // confirmButtonColor: '#3085d6',
    // cancelButtonColor: '#f1eeee',
    showCancelButton: true,
    customClass: {
        // container: 'container-class',
        // popup: 'popup-class',
        // header: 'header-class',
        // title: 'title-class',
        // closeButton: 'close-button-class',
        // icon: 'icon-class',
        // image: 'image-class',
        // content: 'content-class',
        // input: 'input-class',
        // actions: 'actions-class',
        // confirmButton: 'confirm-button-class',
        // footer: 'footer-class',
        confirmButton: 'mlm-btn-primary',
        cancelButton: 'mlm-btn-light'
    }
}

const notifyOptions = {
    title: 'Success',
    html: '',
    icon: 'success',
    confirmButtonText: 'ok',
    customClass: {
        confirmButton: 'mlm-btn-primary',
        cancelButton: 'mlm-btn-light'
    }
}


const getConfirmationOption = (options, item) => {
    return (options && options[item]) ? options[item] : confirmDialogOptions[item];
}

const getNotificationOption = (options, item) => {
    return (options && options[item]) ? options[item] : notifyOptions[item];
}

export default class NotificationService {
    static swalNotify(notifyOptions) {
        Swal.fire({
            heightAuto: false,
            title: getNotificationOption(notifyOptions, 'title'),
            html: getNotificationOption(notifyOptions, 'html'),
            icon: getNotificationOption(notifyOptions, 'icon'),
            confirmButtonText: getNotificationOption(notifyOptions, 'confirmButtonText'),
            customClass: getNotificationOption(notifyOptions, 'customClass'),
        })
    }

    static swalConfirmDialog(swalOptions) {
        return Swal.fire({
            heightAuto: false,
            title: getConfirmationOption(swalOptions, 'title'),
            text: getConfirmationOption(swalOptions, 'text'),
            icon: getConfirmationOption(swalOptions, 'icon'),
            showCancelButton: getConfirmationOption(swalOptions, 'icon'),
            confirmButtonText: getConfirmationOption(swalOptions, 'confirmButtonText'),
            cancelButtonText: getConfirmationOption(swalOptions, 'cancelButtonText'),
            customClass: getConfirmationOption(swalOptions, 'customClass'),
        })
    }

    static swalCustom(swalOptions) {
        return Swal.fire(swalOptions);
    }

    static swalError(message) {
        Swal.fire({
            icon: 'error',
            heightAuto: false,
            html: typeof message === 'string' ? message : JSON.stringify(message)
        })
    }
}
