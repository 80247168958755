import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";

export const Constants = {
    BANK_TMP_LOGO: "/media/buying/bank-pic-default-icon.svg",
    BUSINESS_TMP_LOGO: "/media/buying/business-pic-default-icon.svg",
    USER_TMP_LOGO: "/media/buying/profile-default-icon.svg"
};

export const ShareWithConstants = {
    INTERNALLY: "internally",
    WITH_BANK: "with_bank"
};

export const PagesForShowCountOfItems = [
    "feedback/feedback-sessions",
    "feedback/requests",
    "feedback/ideas"
];

export const BookPhoneInterview_Link = "https://meetings.hubspot.com/daniel-lapp/survey-phone-interview";
export const BookIntroMeeting_Link = "https://meetings.hubspot.com/daniel-lapp/platform-meeting-booking";

export const PLATFORM_STATS_COLORS = {
    GREEN: "#2BC490",
    RED: "#E85B52",
    YELLOW: "#FAD03B"
};

export const APP_PAGES = {
    BANK: {
        SINGLE_USER: "business_single_user",
        PROFILE_OVERVIEW: "business_profile_overview"
    },
    BUSINESS: {
        SINGLE_USER: "bank_single_user",
        PROFILE_OVERVIEW: "bank_profile_overview"
    }
};

export const SELECT_BOX_TYPES = {
    FEEDBACK_SESSIONS: 'feedback_sessions',

}

export const COLORS_FOR_OPTIONS = {
  COLOR_1: '#9AA4E5',
  COLOR_2: '#586BDF',
  COLOR_3: '#2941D8',
  COLOR_4: '#00117B'
};

export const CUSTOM_QUESTION_TEXT_ONLY_ANSWER_MIN_LENGTH = 2;
export const CUSTOM_QUESTION_TEXT_ONLY_ANSWER_MAX_LENGTH = 300;

export const CUSTOM_QUESTIONS_VALIDATION = {
    OPTIONS_MAX_LENGTH: 4,
    OPTIONS_MIN_LENGTH: 2,
    ROWS_MAX_LENGTH: 4,
    ROWS_MIN_LENGTH: 1,
    COLUMNS_MAX_LENGTH: 4,
    COLUMNS_MIN_LENGTH: 2,
    MULTIPLE_CHOICE_TEXT_MAX_LENGTH: 30,
    MULTIPLE_CHOICE_GRID_ROW_TEXT_MAX_LENGTH: 60,
    QUESTION_TEXT_MAX_LENGTH: 300,
    MULTIPLE_CHOICE_GRID_DEFAULT_ROW_COUNT: 2,
    MULTIPLE_CHOICE_GRID_DEFAULT_COLUMNS_COUNT: 4,
}

export const QUESTIONS_TABS = {
    BENCHMARK_QUESTIONS: 'benchmark-questions',
    CUSTOM_QUESTIONS: 'custom-questions'
}

export const DEFAULT_PLATFORM_TYPE = PlatformTypeEnum.BANK;

export const SUPPORTED_LANGUAGES = {
    en: {
        icon_path: '/media/buying/icons/languages/English.svg',
        title: 'ENGLISH',
        subTitle: 'English',
    },
    dk: {
        icon_path: '/media/buying/icons/languages/Danish.svg',
        title: 'DANSK',
        subTitle: 'Danish',
    },
    fr: {
        icon_path: '/media/buying/icons/languages/French.svg',
        title: 'FRANÇAIS',
        subTitle: 'French',
    },
    es: {
        icon_path: '/media/buying/icons/languages/Spanish.svg',
        title: 'ESPAÑOL',
        subTitle: 'Spanish',
    },
    de: {
        icon_path: '/media/buying/icons/languages/German.svg',
        title: 'DEUTSCH',
        subTitle: 'German',
    },
}
