const SHARED_API_USER = {
    VERIFY_NEW_EMAIL: 'auth/verify_user_new_email',
}

const CUSTOM_QUESTION = {
    CUSTOM_QUESTION_ADD: 'questions/custom/add',
    CUSTOM_QUESTION_GET: 'questions/custom/all',
    SURVEY_CUSTOM_QUESTION_ADD: 'surveys/questions/custom/add',
}

export {
    SHARED_API_USER,
    CUSTOM_QUESTION,
}
