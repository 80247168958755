import { SURVEY_API } from "@/router/BusinessApi";
import { Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import NotificationService from "@/buying-teams/services/NotificationService";

@Module
export default class SuperAdminSurveysModule extends VuexModule {
    @Action
    async getSuperAdminFeedbacksList(data) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.GET_SUPER_ADMIN_SURVEYS, data)
                .then(res => {
                    // resolve(res.data.list.map(el => new SurveyModel(el)));
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }
}
