import store from "@/store";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { BusinessFilterService } from "@/core/services/BusinessFilterService";
import { BusinessOverviewDashboardFilterModel } from "@/store/models/filter/business/BusinessOverviewDashboardFilterModel";
import { BusinessBankDashboardFilterModel } from "@/store/models/filter/business/BusinessBankDashboardFilterModel";
import { BusinessProductAreaDashboardFilterModel } from "@/store/models/filter/business/BusinessProductAreaDashboardFilterModel";
import { BusinessCountriesDashboardFilterModel } from "@/store/models/filter/business/BusinessCountriesDashboardFilterModel";
import { BusinessSingleBankDashboardFilterModel } from "@/store/models/filter/business/BusinessSingleBankDashboardFilterModel";
import { BusinessSingleProductAreaDashboardFilterModel } from "@/store/models/filter/business/BusinessSingleProductAreaDashboardFilterModel";
import { BusinessSingleCountryDashboardFilterModel } from "@/store/models/filter/business/BusinessSingleCountryDashboardFilterModel";
import { BusinessSingleQuestionFilterModel } from "@/store/models/filter/business/BusinessSingleQuestionFilterModel";
import { BusinessFeedbackSessionFilterModel } from "@/store/models/filter/BusinessFeedbackSessionFilterModel";
import { BusinessRequestFilterModel } from "@/store/models/filter/BusinessRequestFilterModel";
import { BusinessIdeaFilterModel } from "@/store/models/filter/BusinessIdeaFilterModel";
import { BusinessTeamFilterModel } from "@/store/models/filter/BusinessTeamFilterModel";
import { BusinessCommonListEnum } from "@/store/enums/BusinessCommonListEnum";
import { BaseBusinessFilterModel } from "@/store/models/base/BaseBusinessFilterModel";
import { BusinessModel } from "@/store/models/BusinessModel";

interface moveQuestionFiltersInterface {
    type: BusinessCommonListEnum,
    pageFilterData: BaseBusinessFilterModel,
    itemId: any
}

@Module
export default class BusinessFiltersModule extends VuexModule {
    businessDashboardOverviewFiltersState: BusinessOverviewDashboardFilterModel | null = null;
    businessDashboardBanksFiltersState: BusinessBankDashboardFilterModel | null = null;
    businessDashboardProductAreasFiltersState: BusinessProductAreaDashboardFilterModel | null = null;
    businessDashboardCountriesFiltersState: BusinessCountriesDashboardFilterModel | null = null;
    businessDashboardBankSingleFiltersState: BusinessSingleBankDashboardFilterModel | null = null;
    businessDashboardProductAreaSingleFiltersState: BusinessSingleProductAreaDashboardFilterModel | null = null;
    businessDashboardCountrySingleFiltersState: BusinessSingleCountryDashboardFilterModel | null = null;
    businessQuestionSingleFiltersState: BusinessSingleQuestionFilterModel | null = null;
    businessFeedbackSessionFiltersState: BusinessFeedbackSessionFilterModel | null = null;
    businessRequestsFiltersState: BusinessRequestFilterModel | null = null;
    businessIdeasFiltersState: BusinessIdeaFilterModel | null = null;
    businessTeamFiltersState: BusinessTeamFilterModel | null = null;

    get getBusinessFiltersState() {
        return {
            dashboardOverview: this.businessDashboardOverviewFiltersState,
            dashboardBanks: this.businessDashboardBanksFiltersState,
            dashboardProductAreas: this.businessDashboardProductAreasFiltersState,
            dashboardCountries: this.businessDashboardCountriesFiltersState,
            dashboardSingleBank: this.businessDashboardBankSingleFiltersState,
            dashboardSingleProductArea: this.businessDashboardProductAreaSingleFiltersState,
            dashboardSingleCountry: this.businessDashboardCountrySingleFiltersState,
            singleQuestion: this.businessQuestionSingleFiltersState,
            feedbackSession: this.businessFeedbackSessionFiltersState,
            requests: this.businessRequestsFiltersState,
            ideas: this.businessIdeasFiltersState,
            teams: this.businessTeamFiltersState,
        }
    }

    get businessBaseFilterDefaultState() {
        const businessData: BusinessModel = store.getters.business;
        return {
            bank_ids: store.getters.businessBanks?.map(bk => bk.id) || [],
            countries: store.getters.businessCountries || [],
            product_areas: store.getters.businessProductAreas || [],
            time_period: businessData.created_at
                ? { start_date: businessData.created_at, end_date: new Date() }
                : { start_date: "", end_date: "" },
        }
    }

    get singleQuestionSurveyLoaded() {
        const filters = BusinessFilterService.getSingleQuestionFilters();
        return filters && (this.businessQuestionSingleFiltersState && this.businessQuestionSingleFiltersState.survey_ids.length)
    }

    @Action
    initBusinessFiltersData(type: BusinessFiltersTypeEnum) {
        this.context.commit('SAVE_BUSINESS_INIT_FILTERS', type)
    }

    @Action
    initBusinessMainPagesFilters() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.DASHBOARD_OVERVIEW);
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.REQUESTS);
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.FEEDBACK_SESSION);
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.IDEAS);
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.TEAMS);
    }

    @Action
    moveQuestionFilters({ type, pageFilterData, itemId }: moveQuestionFiltersInterface) {
        let filter = {
            bank_ids: pageFilterData.bank_ids || this.businessBaseFilterDefaultState.bank_ids,
            countries: pageFilterData.countries || this.businessBaseFilterDefaultState.countries,
            survey_ids: [],
            time_period: pageFilterData.time_period || this.businessBaseFilterDefaultState.time_period,
        };
        switch (type) {
            case BusinessCommonListEnum.BANKS:
                filter.bank_ids = [itemId];
                break;
            case BusinessCommonListEnum.COUNTRIES:
                filter.countries = [itemId];
        }
        this.context.commit("SAVE_BUSINESS_SINGLE_QUESTION_FILTER", filter);
        return filter;
    }

    @Mutation
    SAVE_BUSINESS_INIT_FILTERS(type: BusinessFiltersTypeEnum) {
        let payload: any = {...store.getters.businessBaseFilterDefaultState};
        switch (type) {
            case BusinessFiltersTypeEnum.DASHBOARD_OVERVIEW: {
                const filters = BusinessFilterService.getDashboardOverviewFilters();
                const isFiltersAlreadyExist = !!this.businessDashboardOverviewFiltersState;
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeDashboardOverviewFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessDashboardOverviewFiltersState = new BusinessOverviewDashboardFilterModel(payload);
                }
                break;
            }
            case BusinessFiltersTypeEnum.DASHBOARD_BANKS: {
                const filters = BusinessFilterService.getDashboardBanksFilters();
                const isFiltersAlreadyExist = !!this.businessDashboardBanksFiltersState;
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeDashboardBanksFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessDashboardBanksFiltersState = new BusinessBankDashboardFilterModel(payload);
                }

                break;
            }
            case BusinessFiltersTypeEnum.DASHBOARD_PRODUCT_AREAS: {
                const filters = BusinessFilterService.getDashboardProductAreasFilters();
                const isFiltersAlreadyExist = !!this.businessDashboardProductAreasFiltersState;
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeDashboardProductAreasFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessDashboardProductAreasFiltersState = new BusinessProductAreaDashboardFilterModel(payload);
                }
                break;
            }
            case BusinessFiltersTypeEnum.DASHBOARD_COUNTRIES: {
                const filters = BusinessFilterService.getDashboardCountriesFilters();
                const isFiltersAlreadyExist = !!this.businessDashboardCountriesFiltersState;
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeDashboardCountriesFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessDashboardCountriesFiltersState = new BusinessCountriesDashboardFilterModel(payload);
                }
                break;
            }
            case BusinessFiltersTypeEnum.DASHBOARD_SINGLE_BANK: {
                const filters = BusinessFilterService.getDashboardSingleBankFilters();
                const isFiltersAlreadyExist = !!this.businessDashboardBankSingleFiltersState;
                delete payload.bank_ids;
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeDashboardSingleBankFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessDashboardBankSingleFiltersState = new BusinessSingleBankDashboardFilterModel(payload);
                }
                break;
            }
            case BusinessFiltersTypeEnum.DASHBOARD_SINGLE_PRODUCT_AREA: {
                const filters = BusinessFilterService.getDashboardSingleProductAreaFilters();
                const isFiltersAlreadyExist = !!this.businessDashboardProductAreaSingleFiltersState;
                delete payload.product_areas;
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeDashboardSingleProductAreaFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessDashboardProductAreaSingleFiltersState = new BusinessSingleProductAreaDashboardFilterModel(payload);
                }
                break;
            }
            case BusinessFiltersTypeEnum.DASHBOARD_SINGLE_COUNTRY: {
                const filters = BusinessFilterService.getDashboardSingleCountryFilters();
                const isFiltersAlreadyExist = !!this.businessDashboardCountrySingleFiltersState;
                delete payload.countries;
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeDashboardSingleCountryFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessDashboardCountrySingleFiltersState = new BusinessSingleCountryDashboardFilterModel(payload);
                }
                break;
            }
            case BusinessFiltersTypeEnum.SINGLE_QUESTION: {
                const filters = BusinessFilterService.getSingleQuestionFilters();
                const isFiltersAlreadyExist = !!this.businessQuestionSingleFiltersState;
                delete payload.product_areas;
                payload.survey_ids = [];
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeSingleQuestionFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessQuestionSingleFiltersState = new BusinessSingleQuestionFilterModel(payload);
                }
                break;
            }
            case BusinessFiltersTypeEnum.FEEDBACK_SESSION: {
                const filters = BusinessFilterService.getFeedbackSessionFilter();
                const isFiltersAlreadyExist = !!this.businessFeedbackSessionFiltersState;
                payload.time_period = '*';
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeFeedbackSessionFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessFeedbackSessionFiltersState = new BusinessFeedbackSessionFilterModel(payload);
                }

                break;
            }
            case BusinessFiltersTypeEnum.REQUESTS: {
                const filters = BusinessFilterService.getRequestFilter();
                const isFiltersAlreadyExist = !!this.businessRequestsFiltersState;
                payload.time_period = '*';
                payload.display_closed_items = false;
                payload.priority = store.getters.priority?.map(p => p.value);
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeRequestFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessRequestsFiltersState = new BusinessRequestFilterModel(payload);
                }

                break;
            }
            case BusinessFiltersTypeEnum.IDEAS: {
                const filters = BusinessFilterService.getIdeaFilter();
                const isFiltersAlreadyExist = !!this.businessIdeasFiltersState;
                payload.time_period = '*';
                payload.display_closed_items = false;
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeIdeaFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessIdeasFiltersState = new BusinessIdeaFilterModel(payload);
                }

                break;
            }
            case BusinessFiltersTypeEnum.TEAMS: {
                const filters = BusinessFilterService.getTeamFilter();
                const isFiltersAlreadyExist = !!this.businessTeamFiltersState;
                payload.time_period = '*';
                if (filters) {
                    payload = filters;
                } else {
                    BusinessFilterService.storeTeamFilter(payload);
                }

                if (!isFiltersAlreadyExist) {
                    this.businessTeamFiltersState = new BusinessTeamFilterModel(payload);
                }

                break;
            }
        }
    }

    @Mutation
    SAVE_BUSINESS_OVERVIEW_DASHBOARD_FILTER(filters) {
        this.businessDashboardOverviewFiltersState = new BusinessOverviewDashboardFilterModel(filters);
        BusinessFilterService.storeDashboardOverviewFilter(filters);
    }

    @Mutation
    SAVE_BUSINESS_BANKS_DASHBOARD_FILTER(filters) {
        this.businessDashboardBanksFiltersState = new BusinessBankDashboardFilterModel(filters);
        BusinessFilterService.storeDashboardBanksFilter(filters);
    }

    @Mutation
    SAVE_BUSINESS_PRODUCT_AREAS_DASHBOARD_FILTER(filters) {
        this.businessDashboardProductAreasFiltersState = new BusinessProductAreaDashboardFilterModel(filters);
        BusinessFilterService.storeDashboardProductAreasFilter(filters);
    }

    @Mutation
    SAVE_BUSINESS_COUNTRIES_DASHBOARD_FILTER(filters) {
        this.businessDashboardCountriesFiltersState = new BusinessCountriesDashboardFilterModel(filters);
        BusinessFilterService.storeDashboardCountriesFilter(filters);
    }

    @Mutation
    SAVE_BUSINESS_SINGLE_BANK_DASHBOARD_FILTER(filters) {
        this.businessDashboardBankSingleFiltersState = new BusinessSingleBankDashboardFilterModel(filters);
        BusinessFilterService.storeDashboardSingleBankFilter(filters);
    }

    @Mutation
    SAVE_BUSINESS_SINGLE_PRODUCT_AREA_DASHBOARD_FILTER(filters) {
        this.businessDashboardProductAreaSingleFiltersState = new BusinessSingleProductAreaDashboardFilterModel(filters);
        BusinessFilterService.storeDashboardSingleProductAreaFilter(filters);
    }

    @Mutation
    SAVE_BUSINESS_SINGLE_COUNTRY_DASHBOARD_FILTER(filters) {
        this.businessDashboardCountrySingleFiltersState = new BusinessSingleCountryDashboardFilterModel(filters);
        BusinessFilterService.storeDashboardSingleCountryFilter(filters);
    }

    @Mutation
    SAVE_BUSINESS_SINGLE_QUESTION_FILTER(filters) {
        this.businessQuestionSingleFiltersState = new BusinessSingleQuestionFilterModel(filters);
        BusinessFilterService.storeSingleQuestionFilter(filters);
    }

    @Mutation
    SAVE_BUSINESS_FEEDBACK_SESSION_FILTER(filters) {
        this.businessFeedbackSessionFiltersState = new BusinessFeedbackSessionFilterModel(filters);
        BusinessFilterService.storeFeedbackSessionFilter(filters);
    }

    @Mutation
    SAVE_BUSINESS_REQUESTS_FILTER(filters) {
        this.businessRequestsFiltersState = new BusinessRequestFilterModel(filters);
        BusinessFilterService.storeRequestFilter(filters);
    }

    @Mutation
    SAVE_BUSINESS_IDEAS_FILTER(filters) {
        this.businessIdeasFiltersState = new BusinessIdeaFilterModel(filters);
        BusinessFilterService.storeIdeaFilter(filters);
    }

    @Mutation
    SAVE_BUSINESS_TEAMS_FILTER(filters) {
        this.businessTeamFiltersState = new BusinessTeamFilterModel(filters);
        BusinessFilterService.storeTeamFilter(filters);
    }

    @Mutation
    RESET_FILTERS_WITH_PRODUCT_AREAS() {
        this.businessDashboardOverviewFiltersState = null;
        this.businessDashboardBanksFiltersState = null;
        this.businessDashboardProductAreasFiltersState = null;
        this.businessDashboardCountriesFiltersState = null;
        this.businessDashboardBankSingleFiltersState = null;
        this.businessDashboardCountrySingleFiltersState = null;
        this.businessFeedbackSessionFiltersState = null;
        this.businessRequestsFiltersState = null;
        this.businessIdeasFiltersState = null;
        this.businessTeamFiltersState = null;

        BusinessFilterService.destroyDashboardOverviewFilter();
        BusinessFilterService.destroyDashboardBanksFilters();
        BusinessFilterService.destroyDashboardProductAreasFilters();
        BusinessFilterService.destroyDashboardCountriesFilters();
        BusinessFilterService.destroyDashboardSingleBankFilters();
        BusinessFilterService.destroyDashboardSingleCountryFilters();
        BusinessFilterService.destroyFeedbackSessionFilter();
        BusinessFilterService.destroyRequestFilter();
        BusinessFilterService.destroyIdeaFilter();
        BusinessFilterService.destroyTeamFilter();
    }
}
