import { Constants } from "@/core/config/constants";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";

export class UserModelMin {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  icon_path: string;
  job_title: string;
  role: string;

  constructor(obj: any) {
    this.id = obj.id || obj.user_id;
    this.email = obj.email;
    this.first_name = obj.first_name;
    this.last_name = obj.last_name;
    this.full_name = obj['full_name'] || this.first_name + " " + this.last_name;
    this.icon_path = obj.icon_path || Constants.USER_TMP_LOGO;
    this.job_title = obj.job_title;
    this.role = obj.is_admin ? UserRoleEnum.ADMIN : UserRoleEnum.USER;
  }

  getFullName() {
    return this.first_name + ' ' + this.last_name;
  }

  get name() {
    return this.getFullName();
  }
}
