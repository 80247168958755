import { BaseModel } from "@/store/models/base/BaseModel";
import { BusinessBankModel } from "@/store/models/BusinessBankModel";
import { Constants } from "@/core/config/constants";
import { UserSettingsModel } from "@/store/models/UserSettingsModel";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";

export class UserModel extends BaseModel {
  business_banks: BusinessBankModel[];
  banks: BusinessBankModel[];
  business_id: Number;
  email: String;
  first_name: String;
  country: String;
  is_admin: Boolean;
  is_bank_user: Boolean;
  is_blocked: Boolean;
  is_deleted: Boolean;
  is_owner: Boolean;
  is_setup_finished: Boolean;
  is_super_admin: Boolean;
  avatar: String;
  job_title: String;
  last_login_time: Date;
  last_name: String;
  full_name: String;
  product_areas: String[];
  role: String;
  utc_offset: Number;
  feedback: any;
  settings: any
  permissions: Set<string> = new Set();
  language: String;
  icon_path: String;

  constructor(obj: any) {
    super(obj);

    this.business_banks = obj["business_banks"] ? obj["business_banks"].map(bank => new BusinessBankModel(bank)) : [];
    this.banks = obj["banks"] ? obj["banks"].map(bank => new BusinessBankModel(bank)) : [];
    this.business_id = obj["business_id"];
    this.country = obj["country"];
    this.email = obj["email"];
    this.first_name = obj.first_name;
    this.is_admin = obj["is_admin"];
    this.is_bank_user = obj["is_bank_user"];
    this.is_blocked = obj["is_blocked"];
    this.is_deleted = obj["is_deleted"];
    this.is_owner = obj["is_owner"];
    this.is_setup_finished = obj["is_setup_finished"];
    this.is_super_admin = obj["is_super_admin"];
    this.avatar = obj["avatar"];
    this.job_title = obj["job_title"];
    this.last_login_time = new Date(obj["last_login_time"]);
    this.last_name = obj["last_name"];
    this.full_name = obj['full_name'] || this.first_name + " " + this.last_name;
    this.product_areas = obj["product_areas"];
    this.role = obj["role"];
    this.utc_offset = obj["utc_offset"];
    this.icon_path = obj["icon_path"] ? obj["icon_path"] : Constants.USER_TMP_LOGO;
    this.feedback = obj["feedback"] || null;
    this.language = obj["language"] || '';
  }

  get isAdmin() {
    return this.is_admin && this.role === UserRoleEnum.ADMIN;
  }

  addBank(data: any) {
    this.business_banks.push(new BusinessBankModel(data));
  }

  removeBank(bankId) {
    this.business_banks = this.business_banks.filter(bk => bk.id !== bankId);
  }

  getFullName() {
    return this.first_name + ' ' + this.last_name;
  }

  updateSettings(userSettingsData: any) {
    this.settings = new UserSettingsModel(userSettingsData);
  }

  setPermissions(permissions) {
    this.permissions = new Set(permissions);
  }

  can(permission) {
    return this.permissions && this.permissions.has(permission);
  }

  selfUpdate(data) {
    for (let [key, value] of Object.entries(data)) {
      this[key] = value;
      if (key === 'business_banks' || key === 'banks') {
        this[key] = data[key].map(bank => new BusinessBankModel(bank));
      }
      if (!data.icon_path) this.icon_path = Constants.USER_TMP_LOGO;
    }
  }
}
