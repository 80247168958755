import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { BreadcrumbModel } from "@/store/models/BreadcrumbModel";

interface Breadcrumb {
  title: string | null;
  subTitle: string | null;
  counter: number | string | null;
  logo: string | null;
}

interface StoreInfo {
  breadcrumbs: Breadcrumb;
}

@Module
export default class BreadcrumbsModule extends VuexModule implements StoreInfo {
  breadcrumbs = {} as BreadcrumbModel;

  /**
   * Get breadcrumb object for current page
   * @returns object
   */
  get getBreadcrumbs(): Breadcrumb {
    return this.breadcrumbs;
  }

  @Mutation
  [Mutations.SET_BREADCRUMB_MUTATION](payload: any) {
    this.breadcrumbs = new BreadcrumbModel(payload);
  }

  @Action
  [Actions.SET_BREADCRUMB_ACTION](payload: any) {
    this.context.commit(Mutations.SET_BREADCRUMB_MUTATION, payload);
  }
}
