import { DateRangePickerInterface } from "@/store/interfaces/DateTimeInterface";
import { checkIfObject } from "@/core/helpers/GlobalHelper";
import { DEFAULT_TIME_PERIOD } from "@/store/modules/bank/BankFiltersModule";

export class BankSingleCustomersFilterModel {
    business_id: number;
    time_period: DateRangePickerInterface;
    product_areas: string[];
    countries: string[];
    survey_ids: any[];

    constructor(obj: any) {
        this.business_id = obj.business_id;
        this.time_period = checkIfObject(obj.time_period) ? obj.time_period : DEFAULT_TIME_PERIOD;
        this.product_areas = obj.product_areas || [];
        this.countries = obj.countries || [];
        this.survey_ids = obj.survey_ids || [];
    }
}
