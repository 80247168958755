import { createApp } from "vue";
import App from "./App.vue";

import vClickOutside from "click-outside-vue3"

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { SentryService } from "@/core/services/SentryService";

// Google Analytics
import VueGtag from "vue-gtag-next";

import "@/core/plugins/prismjs";
import "bootstrap";

const app = createApp(App);

if (process.env.VUE_APP_SENTRY_LOGS_ENABLED === 'true') SentryService.init(app, router)
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(vClickOutside)
app.use(VueGtag, {
  property: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID
  }
});

ApiService.init(app);
initApexCharts(app);
initVeeValidate();
initInlineSvg(app);

app.use(i18n);

app.mount("#app");
