import store from "@/store";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { BankFilterService } from "@/core/services/BankFilterService";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { diagLog } from "@/core/helpers/GlobalHelper";
import { DataService } from "@/core/services/DataService";
import { UserModel } from "@/store/models/UserModel";
import { BusinessModel } from "@/store/models/BusinessModel";
import { BankModel } from "@/store/models/bank/BankModel";

class InitialDataResolver {
  public static async init(platformType: PlatformTypeEnum) {
    store.commit(Mutations.SET_PLATFORM_CONFIGS, platformType)

    diagLog('platformConfigs => ', store.getters.platformConfigs);

    switch (platformType) {
      case PlatformTypeEnum.BUSINESS:
        await InitialDataResolver.fetchBusinessInitialData();
        break;
      case PlatformTypeEnum.BANK:
        await InitialDataResolver.fetchBankInitialData();
        break;
    }
    await InitialDataResolver.fetchStaticData(StaticDataEnum.COUNTRIES);
  }

  public static async fetchBankInitialData() {
    await store.dispatch("fetchBankInitialData")
        .then(res => {
          store.getters.bank.updateSettings(res.bank.settings);
          store.getters.currentUser.updateSettings(res.user_settings);
          store.getters.currentUser.setPermissions(res.permissions);
        });
  }

  public static async verifyAuthUser(platformType: PlatformTypeEnum) {
    const {user, company} = await store.dispatch(Actions.VERIFY_AUTH_USER);

    DataService.storeAuthUserData(new UserModel(user));

    switch (platformType) {
      case PlatformTypeEnum.SUPER_ADMIN:
        diagLog('verifyAuthUser: ===> TYPE: SUPER ADMIN !!!!');
        // store.commit(Mutations.SET_AUTH);
        // store.commit(Mutations.SET_USER, user);
        return;
      case PlatformTypeEnum.BUSINESS:
        diagLog('verifyAuthUser: ===> TYPE: BUSINESS !!!!');
        // store.commit(Mutations.SET_AUTH);
        // store.commit(Mutations.SET_USER, user);
        // store.commit("SAVE_BUSINESS_INFO", company);
          DataService.storeBusinessData(new BusinessModel(company));
        return;
      case PlatformTypeEnum.BANK:
        diagLog('verifyAuthUser: ===> TYPE: BANK !!!!');
        // store.commit(Mutations.SET_AUTH);
        // store.commit(Mutations.SET_USER, user);
        // store.commit("SAVE_BANK_INFO", company);
        DataService.storeBankData(new BankModel(company));
        return;
    }
  }

  public static async fetchStaticData(type) {
    await store.dispatch('fetchStaticData', type);
  }

  public static async fetchBusinessInitialData() {
    await store.dispatch("fetchBusinessInitialData")
        .then(res => {
          store.getters.business.updateSettings(res.business.settings);
          store.getters.currentUser.updateSettings(res.user_settings);
          store.getters.currentUser.setPermissions(res.permissions);
        });
  }

  public static async fetchBankDashboardData() {
    await store.dispatch('initFiltersData', { type: BankFiltersTypeEnum.DASHBOARD });
    store.commit('SAVE_FILTER_LOADED', false);
  }

  public static async fetchUserSettings() {
    await store.dispatch("fetchUserSettings");
  }

  public static async fetchBankUserSettings() {
    await store.dispatch("fetchBankUserSettings");
  }

  public static async fetchBankSettings() {
    await store.dispatch("getBankSettings");
  }
}

export default InitialDataResolver;
