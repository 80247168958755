import moment from "moment";
import { translateGlobal } from "@/core/helpers/GlobalHelper";
import { CustomerInviteStatuses } from "@/store/enums/bank/CustomerInviteStatuses";
import { Constants } from "@/core/config/constants";

export class CustomerInvitationsContainer {
    invitationsData: CustomerInvitationsModel[] = [];
    customerUsersData: CustomerInvitationsModel[] | null = null;

    constructor(obj) {
        if (obj) {
            for (const property in obj) {
                this.invitationsData.push(new CustomerInvitationsModel(obj[property]));
            }
        }
    }

    setCustomerUsersData(data: any) {
        if (data && data.length) {
            this.customerUsersData = data.map(user => new CustomerInvitationsUserModel(user));
        } else {
            this.customerUsersData = null;
        }
    }
}

export class CustomerInvitationsModel {
    business_name: string;
    business_id: string;
    icon_path: string;
    invites: CustomerInvitationsUserModel[];
    accepted_count: number = 0;

    constructor(obj) {
        this.business_name = obj.business_name;
        this.business_id = obj.business_id;
        this.icon_path = obj.icon_path ? obj.icon_path : Constants.BUSINESS_TMP_LOGO;
        this.invites = obj.invites.map(inv => new CustomerInvitationsUserModel(inv));
        this.accepted_count = this.invites.filter((inv) => inv.status_id === CustomerInviteStatuses.ACCEPTED).length;
    }
}

class CustomerInvitationsUserModel {
    created_by: number;
    email: string;
    invite_accepted_at: any;
    invite_sent_at: any;
    status: { text: string, className: string } | null;
    status_id: number;
    is_update_loading: boolean = false;
    first_name: string;
    last_name: string;
    icon_path: string;

    constructor(obj) {
        this.created_by = obj.created_by;
        this.email = obj.email || '';
        this.first_name = obj.first_name;
        this.last_name = obj.last_name;
        this.icon_path = obj.icon_path ? obj.icon_path : Constants.USER_TMP_LOGO;
        this.invite_accepted_at = obj.invite_accepted_at;
        this.invite_sent_at = obj.invite_sent_at ? moment(obj.invite_sent_at).format('DD/MM/YYYY') : null;
        this.status_id = obj.status;
        this.status = this.getStatus(obj.status);
    }

    get getFullName() {
        if (!this.first_name && !this.last_name) {
            return '- -'
        } else {
            return `${this.first_name} ${this.last_name}`
        }
    }

    get isResendDisabled() {
        return this.status_id === CustomerInviteStatuses.ACCEPTED || this.status_id === CustomerInviteStatuses.BOUNCED;
    }

    get isDeleteDisabled() {
        return this.status_id === CustomerInviteStatuses.ACCEPTED || this.status_id === CustomerInviteStatuses.BOUNCED;
    }

    getStatus(status) {
        switch (status) {
            case CustomerInviteStatuses.PENDING:
                return {
                    text: translateGlobal('Pending'),
                    className: 'invite-status-pending'
                }
            case CustomerInviteStatuses.ACCEPTED:
                return {
                    text: translateGlobal('Accepted'),
                    className: 'invite-status-accepted'
                }
            case CustomerInviteStatuses.BOUNCED:
                return {
                    text: translateGlobal('Bounced'),
                    className: 'invite-status-bounced'
                }
        }

        return null;
    }

    updateStatus(status) {
      this.status_id = status;
      this.status = this.getStatus(status)
    }

    setLoading(status: boolean = true) {
        this.is_update_loading = status
    }
}
