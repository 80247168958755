import { BankOverviewDashboardFilterModel } from "@/store/models/filter/bank/BankOverviewDashboardFilterModel";
import { BankProductAreaOverviewFilterModel } from "@/store/models/filter/bank/BankProductAreaOverviewFilterModel";
import { BankIdeaFilterModel } from "@/store/models/filter/bank/BankIdeaFilterModel";
import { BankRequestFilterModel } from "@/store/models/filter/bank/BankRequestFilterModel";
import { BankFeedbackFilterModel } from "@/store/models/filter/bank/BankFeedbackFilterModel";
import { BankFiltersDataModel } from "@/store/models/filter/bank/BankFiltersDataModel";
import { BankSingleQuestionsFilterModel } from "@/store/models/filter/bank/BankSingleQuestionsFilterModel";
import { BankCustomersFilterModel } from "@/store/models/filter/bank/BankCustomersFilterModel";
import { BankSingleCustomersFilterModel } from "@/store/models/filter/bank/BankSingleCustomersFilterModel";
import { BankSingleSurveyFilterModel } from "@/store/models/filter/bank/BankSingleSurveyFilterModel";
import { BankCountryOverviewFilterModel } from "@/store/models/filter/bank/BankCountryOverviewFilterModel";

const BANK_OVERVIEW_DASHBOARD_FILTER_KEY = "bankOverviewDashboardFilters";
const COUNTRIES_DASHBOARD_FILTER_KEY = "countriesDashboardFilters";
const PRODUCT_AREAS_DASHBOARD_FILTER_KEY = "productAreasDashboardFilters";
const BANK_PRODUCT_AREA_OVERVIEW_FILTER_KEY = "BankProductAreaOverviewFilter";
const BANK_COUNTRY_OVERVIEW_FILTER_KEY = "BankCountryOverviewFilter";
const BANK_IDEA_FILTER_KEY = "bankIdeaFilters";
const BANK_REQUEST_FILTER_KEY = "bankRequestFilters";
const BANK_FEEDBACK_FILTER_KEY = "bankFeedbackFilters";
const BANK_FILTERS_DATA_KEY = "bankFiltersData";
const BANK_SINGLE_QUESTION_FILTER_KEY = "bankSingleQuestionFilters";
const BANK_CUSTOMERS_FILTER_KEY = "bankCustomersFilters";
const BANK_SINGLE_CUSTOMERS_FILTER_KEY = "bankSingleCustomersFilters";
const BANK_SINGLE_SURVEY_FILTER_KEY = "bankSingleSurveyFilters";

const storage = localStorage;

export class BankFilterService {
    static storeBankOverviewDashboardFilter(filters: BankOverviewDashboardFilterModel) {
        storage.setItem(BANK_OVERVIEW_DASHBOARD_FILTER_KEY, JSON.stringify(filters));
    }

    static getBankOverviewDashboardFilter() {
        let filters =  storage.getItem(BANK_OVERVIEW_DASHBOARD_FILTER_KEY);
        if (filters) return JSON.parse(filters);
        return null;
    }

    static destroyBankOverviewDashboardFilter() {
        storage.removeItem(BANK_OVERVIEW_DASHBOARD_FILTER_KEY);
    }

    static storeCountriesDashboardFilter(filters) {
        storage.setItem(COUNTRIES_DASHBOARD_FILTER_KEY, JSON.stringify(filters));
    }

    static getCountriesDashboardFilter() {
        let filters: any = storage.getItem(COUNTRIES_DASHBOARD_FILTER_KEY);
        if (filters) return JSON.parse(filters);
        return null;
    }

    static destroyCountriesDashboardFilter() {
        storage.removeItem(COUNTRIES_DASHBOARD_FILTER_KEY);
    }

    static storeProductAreasDashboardFilter(filters) {
        storage.setItem(PRODUCT_AREAS_DASHBOARD_FILTER_KEY, JSON.stringify(filters));
    }

    static getProductAreasDashboardFilter() {
        let filters: any = storage.getItem(PRODUCT_AREAS_DASHBOARD_FILTER_KEY);
        if (filters) return JSON.parse(filters);
        return null;
    }

    static destroyProductAreasDashboardFilter() {
        storage.removeItem(PRODUCT_AREAS_DASHBOARD_FILTER_KEY);
    }

    static storeBankProductAreaOverviewFilter(filters: BankProductAreaOverviewFilterModel) {
        storage.setItem(BANK_PRODUCT_AREA_OVERVIEW_FILTER_KEY, JSON.stringify(filters));
    }

    static getBankProductAreaOverviewFilter() {
        let filters: any = storage.getItem(BANK_PRODUCT_AREA_OVERVIEW_FILTER_KEY);
        if (filters) return JSON.parse(filters);
        return null;
    }

    static destroyBankProductAreaOverviewFilter() {
        storage.removeItem(BANK_PRODUCT_AREA_OVERVIEW_FILTER_KEY);
    }

    static storeBankCountryOverviewFilter(filters: BankCountryOverviewFilterModel) {
        storage.setItem(BANK_COUNTRY_OVERVIEW_FILTER_KEY, JSON.stringify(filters));
    }

    static getBankCountryOverviewFilter() {
        let filters: any = storage.getItem(BANK_COUNTRY_OVERVIEW_FILTER_KEY);
        if (filters) return JSON.parse(filters);
        return null;
    }

    static destroyBankCountryOverviewFilter() {
        storage.removeItem(BANK_COUNTRY_OVERVIEW_FILTER_KEY);
    }

    static storeBankIdeaFilter(filters: BankIdeaFilterModel) {
        storage.setItem(BANK_IDEA_FILTER_KEY, JSON.stringify(filters));
    }

    static getBankIdeaFilter(): BankIdeaFilterModel | null {
        let filters: any = storage.getItem(BANK_IDEA_FILTER_KEY);
        if (filters) filters = new BankIdeaFilterModel(JSON.parse(filters))

        return filters;
    }

    static destroyBankIdeaFilter() {
        storage.removeItem(BANK_IDEA_FILTER_KEY);
    }

    static storeBankRequestFilter(filters: BankRequestFilterModel) {
        storage.setItem(BANK_REQUEST_FILTER_KEY, JSON.stringify(filters));
    }

    static getBankRequestFilter(): BankRequestFilterModel | null {
        let filters: any = storage.getItem(BANK_REQUEST_FILTER_KEY);
        if (filters) filters = new BankRequestFilterModel(JSON.parse(filters))

        return filters;
    }

    static destroyBankRequestFilter() {
        storage.removeItem(BANK_REQUEST_FILTER_KEY);
    }

    static storeBankFeedbackFilter(filters: BankFeedbackFilterModel) {
        storage.setItem(BANK_FEEDBACK_FILTER_KEY, JSON.stringify(filters));
    }

    static getBankFeedbackFilter(): BankFeedbackFilterModel | null {
        let filters: any = storage.getItem(BANK_FEEDBACK_FILTER_KEY);
        if (filters) filters = new BankFeedbackFilterModel(JSON.parse(filters))

        return filters;
    }

    static destroyFeedbackFilter() {
        storage.removeItem(BANK_FEEDBACK_FILTER_KEY);
    }

    static storeBankSingleQuestionFilter(filters: BankSingleQuestionsFilterModel) {
        storage.setItem(BANK_SINGLE_QUESTION_FILTER_KEY, JSON.stringify(filters));
    }

    static getBankSingleQuestionFilter(): BankSingleQuestionsFilterModel | null {
        let filters: any = storage.getItem(BANK_SINGLE_QUESTION_FILTER_KEY);
        if (filters) filters = new BankSingleQuestionsFilterModel(JSON.parse(filters))

        return filters;
    }

    static destroySingleQuestionFilter() {
        storage.removeItem(BANK_SINGLE_QUESTION_FILTER_KEY);
    }

    static storeBankCustomersFilter(filters: BankCustomersFilterModel) {
        storage.setItem(BANK_CUSTOMERS_FILTER_KEY, JSON.stringify(filters));
    }

    static getBankCustomersFilter(): BankCustomersFilterModel | null {
        let filters: any = storage.getItem(BANK_CUSTOMERS_FILTER_KEY);
        if (filters) filters = new BankCustomersFilterModel(JSON.parse(filters))

        return filters;
    }

    static destroyCustomersFilter() {
        storage.removeItem(BANK_CUSTOMERS_FILTER_KEY);
    }

    static storeBankSingleCustomersFilter(filters: BankSingleCustomersFilterModel) {
        storage.setItem(BANK_SINGLE_CUSTOMERS_FILTER_KEY, JSON.stringify(filters));
    }

    static getBankSingleCustomersFilter(): BankSingleCustomersFilterModel | null {
        let filters: any = storage.getItem(BANK_SINGLE_CUSTOMERS_FILTER_KEY);
        if (filters) filters = new BankSingleCustomersFilterModel(JSON.parse(filters))

        return filters;
    }

    static destroyBankSingleCustomersFilter() {
        storage.removeItem(BANK_SINGLE_CUSTOMERS_FILTER_KEY);
    }

    static storeBankSingleSurveyFilter(filters: BankSingleSurveyFilterModel) {
        storage.setItem(BANK_SINGLE_SURVEY_FILTER_KEY, JSON.stringify(filters));
    }

    static getBankSingleSurveyFilter(): BankSingleSurveyFilterModel | null {
        let filters: any = storage.getItem(BANK_SINGLE_SURVEY_FILTER_KEY);
        if (filters) filters = new BankSingleSurveyFilterModel(JSON.parse(filters))

        return filters;
    }

    static destroyBankSingleSurveyFilter() {
        storage.removeItem(BANK_SINGLE_SURVEY_FILTER_KEY);
    }

    static storeBankFiltersData(filters: BankFiltersDataModel) {
        storage.setItem(BANK_FILTERS_DATA_KEY, JSON.stringify(filters));
    }

    static getBankFiltersData(): BankFiltersDataModel | null {
        let filters: any = storage.getItem(BANK_FILTERS_DATA_KEY);
        if (filters) filters = new BankFiltersDataModel(JSON.parse(filters))

        return filters;
    }

    static destroyBankFiltersData() {
        storage.removeItem(BANK_FILTERS_DATA_KEY);
    }

    static removeAllFilters() {
        this.destroyBankOverviewDashboardFilter();
        this.destroyBankProductAreaOverviewFilter();
        this.destroyBankIdeaFilter();
        this.destroyBankRequestFilter();
        this.destroyFeedbackFilter();
        this.destroyBankFiltersData();
        this.destroySingleQuestionFilter();
        this.destroyCustomersFilter();
        this.destroyBankSingleCustomersFilter();
        this.destroyBankSingleSurveyFilter();
        this.destroyCountriesDashboardFilter();
        this.destroyProductAreasDashboardFilter();
        this.destroyBankCountryOverviewFilter();
    }

}
