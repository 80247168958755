import { Constants } from "@/core/config/constants";
import { BaseModel } from "@/store/models/base/BaseModel";

export class BankCustomerModel extends BaseModel {
  icon_path: string;
  business_name: string;
  product_areas: string[];
  countries: string[];
  industries: string[];
  stats: any;
  tier: number;
  business_hq: string;

  constructor(obj: any) {
    super(obj);

    this.icon_path = obj['icon_path'] ? obj['icon_path'] : Constants.BUSINESS_TMP_LOGO;
    this.business_name = obj['business_name'] ? obj['business_name'] : null;
    this.product_areas = obj['product_areas'] ? obj['product_areas'] : [];
    this.countries = obj['countries'] ? obj['countries'] : [];
    this.industries = obj['industries'] ? obj['industries'] : [];
    this.stats = obj['stats'] ? obj['stats'] : {
      score: obj.score ? obj.score : null,
      importance: obj.importance ? obj.importance : null,
      gap: obj.gap ? obj.gap : null
    };
    this.tier = obj['tier'] && obj['tier'] !== 'Unknown' ? obj['tier'] : 0;
    this.business_hq = obj['business_hq'] ? obj['business_hq'] : obj['hq'] ? obj['hq'] : null;
  }
}
