
export class PermissionModel {
  can_open: boolean;
  can_close: boolean;
  can_edit: boolean;
  can_delete: boolean;

  constructor(obj: any) {
    this.can_open = obj['can_open'] || false;
    this.can_close = obj['can_close'] || false;
    this.can_edit = obj['can_edit'] || false;
    this.can_delete = obj['can_delete'] || false;
  }
}
