import ApiService from "@/core/services/ApiService";
import NotificationService from "@/buying-teams/services/NotificationService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SURVEY_API } from "@/router/BankApi";
import { BankFeedbackModel } from "@/store/models/bank/feedback/BankFeedbackModel";
import { BankFilterService } from "@/core/services/BankFilterService";
import { BankFeedbackFilterModel } from "@/store/models/filter/bank/BankFeedbackFilterModel";
import { BankSingleFeedbackContainer } from "@/store/models/bank/BankSingleFeedbackContainer";
import { BankSingleQuestionsFilterModel } from "@/store/models/filter/bank/BankSingleQuestionsFilterModel";
import { BankSingleSurveyFilterModel } from "@/store/models/filter/bank/BankSingleSurveyFilterModel";

@Module
export default class BankSurveysModule extends VuexModule {
    bankFeedbackFilterData = {} as BankFeedbackFilterModel;
    bankFeedbacks: BankFeedbackModel[] = [];
    bankFeedbacksLoading: boolean = true;
    bankSingleQuestionFilterData = {} as BankSingleQuestionsFilterModel;
    bankSingleSurveyFilterData = {} as BankSingleSurveyFilterModel;

    get getBankFeedbackFilterData() {
        return BankFilterService.getBankFeedbackFilter()
    }

    get getBankFeedbacksData() {
        return this.bankFeedbacks;
    }

    get getBankFeedbacksLoading() {
        return this.bankFeedbacksLoading;
    }

    get getBankSingleQuestionFilterData() {
        return this.bankSingleQuestionFilterData;
    }

    get getBankSingleSurveyFilterData() {
        return this.bankSingleSurveyFilterData;
    }

    @Mutation
    SAVE_BANK_FEEDBACK_FILTER(filterData) {
        this.bankFeedbackFilterData = new BankFeedbackFilterModel(filterData);
        BankFilterService.storeBankFeedbackFilter(this.bankFeedbackFilterData);
    }

    @Mutation
    SAVE_BANK_SINGLE_QUESTION_FILTER(filterData) {
        this.bankSingleQuestionFilterData = new BankSingleQuestionsFilterModel(filterData);
        BankFilterService.storeBankSingleQuestionFilter(this.bankSingleQuestionFilterData);
    }

    @Mutation
    SAVE_BANK_FEEDBACK_DATA(data: BankFeedbackModel[]) {
        this.bankFeedbacks = data;
    }

    @Mutation
    SAVE_BANK_FEEDBACK_LOADING(value: boolean) {
        this.bankFeedbacksLoading = value;
    }

    @Mutation
    SAVE_BANK_SINGLE_SURVEY_FILTER(filterData) {
        this.bankSingleSurveyFilterData = new BankSingleSurveyFilterModel(filterData);
        BankFilterService.storeBankSingleSurveyFilter(this.bankSingleSurveyFilterData);
    }

    @Action
    getSingleQuestion({question_id, filter, is_custom}) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.GET_SINGLE_QUESTION, {
                question_id,
                filter,
                is_custom
            })
                .then(res => {
                    if (res.status === 200) {
                        this.context.commit('SAVE_BANK_SINGLE_QUESTION_FILTER', { ...filter, question_id, is_custom })
                        resolve(res.data)
                    } else {
                        reject()
                    }
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject()
                });
        })
    }

    @Action
    getBankFeedbacksSessions(data) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.GET_FEEDBACKS_LIST, data)
                .then(res => {
                    const feedbacks = res.data.map(el => new BankFeedbackModel(el));
                    this.context.commit('SAVE_BANK_FEEDBACK_FILTER', data.filter)
                    this.context.commit('SAVE_BANK_FEEDBACK_DATA', feedbacks)
                    resolve(feedbacks)
                })
                .catch(error => {
                    console.log(error, '---error');
                    NotificationService.swalError(error);
                    reject()
                }).finally(() => {
                this.context.commit('SAVE_BANK_FEEDBACK_LOADING', false)
            });
        })
    }

    @Action
    getBankFeedbacksSessionDetails(payload) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.GET_FEEDBACKS_DETAILS, payload)
                .then(res => {
                    this.context.commit('SAVE_BANK_SINGLE_SURVEY_FILTER', {
                        ...payload.filter,
                        survey_id: payload.survey_id
                    });
                    resolve(new BankSingleFeedbackContainer(res.data));
                })
                .catch(error => {
                    console.log(error, '---error');
                    NotificationService.swalError(error);
                    reject()
                });
        })
    }

    @Action
    createBankSurveysDraft(data) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.SAVE_DRAFT, {
                ...data,
                session_end: Math.floor(new Date(data.session_end).getTime() / 1000),
                session_start: Math.floor(new Date(data.session_start).getTime() / 1000)
            })
                .then(res => {
                    resolve(res)
                })
                .catch(error => {
                    console.log(error, '---error');
                    NotificationService.swalError(error);
                    reject()
                });
        })
    }

    @Action
    async bankQuestionsToggle(questionInfo) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.QUESTIONS_TOGGLE, questionInfo)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject()
                });
        })
    }

    @Action
    getMatchedBusinesses(payload) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(`${SURVEY_API.MATCHED_BUSINESSES}`, payload)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject()
                });
        })
    }

    @Action
    getBusinessDetails({business_id}) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(`${SURVEY_API.GET_BUSINESS_DETAILS}`, {business_id})
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject()
                });
        })
    }

    @Action
    businessAddByEmail(payload) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(`${SURVEY_API.BUSINESS_ADD_BY_EMAIL}`, payload)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject()
                });
        })
    }

    @Action
    async bankAddParticipants({users, survey_id}) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.ADD_PARTICIPANTS, {
                users,
                survey_id
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject()
                });
        })
    }

    @Action
    async bankSurveysSubmit(data) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.SURVEYS_SUBMIT, data)
                .then(res => {
                    resolve(res)
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject()
                });
        })
    }

    @Action
    async bankFeedbackAddCustomer(data) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.SURVEYS_ADD_CUSTOMER, data)
                .then(res => {
                    resolve(res)
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject()
                });
        })
    }
}
