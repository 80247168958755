import BankFollowOperationEnum from "@/store/enums/bank/BankFollowOperationEnum";

export class BankFiltersDataModel {
  tiers: string;
  customers_hq: string[];
  countries: string[];
  industries: string[];
  product_areas: string[];

  constructor(obj: any) {
    this.tiers = obj['tiers'] || [];
    this.customers_hq = obj['head_office_countries'] || obj['customers_hq'] || [];
    this.countries = obj['bank_countries'] || obj['countries'] || [];
    this.industries = obj['industries'] || [];
    this.product_areas = obj['product_areas'] || [];
  }

  addProductArea(productArea: string) {
    let prs = new Set(this.product_areas);
    prs.add(productArea);
    this.product_areas = Array.from(prs.values());
  }

  removeProductArea(productArea: string) {
    let prs = new Set(this.product_areas);
    if (prs.has(productArea)) prs.delete(productArea);
    this.product_areas = Array.from(prs.values());
  }

  addCountry(country: string) {
    let cs = new Set(this.countries);
    cs.add(country);
    this.countries = Array.from(cs.values());
  }

  removeCountry(country: string) {
    let cs = new Set(this.countries);
    if (cs.has(country)) cs.delete(country);
    this.countries = Array.from(cs.values());
  }

  addRemoveCountry (operation: BankFollowOperationEnum, country: string) {
    switch (operation) {
      case BankFollowOperationEnum.ADD:
        this.addCountry(country);
        break;
      case BankFollowOperationEnum.REMOVE:
        this.removeCountry(country);
        break;
    }
  }

  addRemoveProductArea (operation: BankFollowOperationEnum, productArea: string) {
    switch (operation) {
      case BankFollowOperationEnum.ADD:
        this.addProductArea(productArea);
        break;
      case BankFollowOperationEnum.REMOVE:
        this.removeProductArea(productArea);
        break;
    }
  }
}
