import { RequestPriorityEnum } from "@/store/enums/request/RequestPriorityEnum";
import { translateGlobal } from "@/core/helpers/GlobalHelper";

export class RequestPriorityModel {
  priorityName: string;
  priorityClass: string;
  code: number

  constructor(priorityCode: number) {
    this.code = priorityCode;
    this.priorityName = translateGlobal(RequestPriorityEnum[priorityCode]);
    this.priorityClass = this.definePriorityClass(priorityCode);
  }

  definePriorityClass(priorityCode: number) {
    let className = "" as string;

    switch (priorityCode) {
      case RequestPriorityEnum.LOW:
        className = 'secondary';
        break;
      case RequestPriorityEnum.NORMAL:
        className = 'success';
        break;
      case RequestPriorityEnum.HIGH:
        className = 'warning';
        break;
      case RequestPriorityEnum.CRITICAL:
        className = 'danger';
        break;
    }

    return className;
  }
}
