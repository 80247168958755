import { DateRangePickerInterface } from "@/store/interfaces/DateTimeInterface";

export class BusinessSingleQuestionFilterModel {
    bank_ids: number[];
    countries: string[];
    survey_ids: number[];
    time_period: DateRangePickerInterface;

    constructor(obj: any) {
        this.bank_ids = obj['bank_ids'];
        this.countries = obj['countries'];
        this.survey_ids = obj['survey_ids'];
        this.time_period = obj['time_period'];
    }
}
