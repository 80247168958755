import i18n from "@/core/plugins/i18n";
import { PlatformEnum } from "@/store/enums/PlatformEnum";
import moment from "moment";

export const arrayMerge = (arr1: any[], arr2: any[]): any[] => {
  return arr1.concat(arr2);
};

export const translateGlobal = (key: String): string => {
  return i18n.global.t(key);
};

export const checkIfObject = (value: any) => {
    return (typeof value === 'object' && value !== null) && Object.keys(value).length;
}

export const getPlatform = (): string | null => {
  let OS = null as string | null;
  let agent = navigator.userAgent;

  if (agent.includes("Win")) OS = PlatformEnum.WEB;
  if (agent.includes("Mac")) OS = PlatformEnum.WEB;
  if (agent.includes("Linux")) OS = PlatformEnum.WEB;
  if (agent.includes("Android")) OS = PlatformEnum.ANDROID;
  if (agent.includes("iPhone")) OS = PlatformEnum.IOS;

  return OS;
};

export const createAndOpenLink = (link: string, target: string | null = null): void => {
  const a = document.createElement("a");
  a.href = link;

  if (target) a.target = target;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const getHidedEmail = (email: string) => {
  const firstElement = email.split("@")[0];
  const secondElement = email.split("@")[1];

  if (firstElement.length > 1 && firstElement.length < 3) {
    return email;
  }
  const firstElementChanged = firstElement
    .split("")
    .map((el, index) => (index > 1 ? "*" : el))
    .join("");

  return [firstElementChanged, secondElement].join("@");
};

export const getStatusClass = (value, pg = null) => {
  value = parseFloat(value);
  if (value === 0) {
    return 'status-success'
  } else if (value <= 2.49) {
    return 'status-danger'
  } else if( value >= 2.50  && value  <= 3.49) {
    return 'status-warning'
  } else if (value >= 3.50) {
    return 'status-success'
  }
}

export const getGapStatusClass = (value: string | number) => {
  if (typeof value !== 'number' && !value) return '';
  return value >= 0 ? 'status-success' : 'status-danger';
}

export const getOrdered = (data: any[], byKey: string,  type: string = 'desc') => {
  if (!data) return [];
  let result = data

  return result.sort((a, b) => (Number(a[byKey]) > Number(b[byKey]) ? -1 : 1))
}

export const mergeDedupe = (arr: any) => {
  return Array.from(new Set([].concat(...arr)));
}

export const numberDecimalsFormat = (value, decimals: number = 2) => {
  if (!value) return '0.00';
  return parseFloat(value).toFixed(decimals);
}

// like this: 02.02.22 at 1:00 PM GMT +0400
export const dateFormatInTimeZone = (date) => {
  let momentDate = moment(date);
  let timeZone = moment.parseZone(date).utcOffset() / 60;
  return `
    <span>${momentDate.format('DD.MM.YY')}</span> ${i18n.global.t('at')}
    <span>${momentDate.format('LT')}</span>
    GMT ${timeZone >= 0 ? '+'+timeZone : timeZone}
  `;
}

export const sortByAlphabetically = (data, key = '') => {
  if (key) {
    return data.sort((a,b) => a[key].localeCompare(b[key]));
  } else {
    return data.sort((a,b) => a.localeCompare(b));
  }
}


export const diagLog = (obj, ...args) => {
  const DIAG_LOGS_ENABLED = process.env.VUE_APP_DIAG_LOGS_ENABLED === 'true';
  if(DIAG_LOGS_ENABLED) {
    console.log(
      `${obj}: `,
      ...args
    );
  }
}

export const diagLogClass = (obj, ...args) => {
  const DIAG_LOGS_ENABLED = process.env.VUE_APP_DIAG_LOGS_ENABLED === 'true';
  if(DIAG_LOGS_ENABLED) {
    console.log(
      `${obj.constructor.name}: `,
      ...args
    );
  }
}

export const getFileFromUrl = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    if (!url) {
      reject();
    }
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        resolve(blob);
      } else {
        reject(new Error('Image not found'));
      }
    };
    xhr.onerror = (e) => {
      reject(e);
    }
    xhr.send();
  });
}

export const downloadBlob = (blob, name = 'file.svg') => {
  if (
    window.navigator &&
    window.navigator.msSaveOrOpenBlob
  ) return window.navigator.msSaveOrOpenBlob(blob);

  const data = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = data;
  link.download = name;

  // this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
}

export const fetchFiles = async (countries) => {
  let downloadedCount = 0;
  let failedCount = 0;
  for (let i = 0; i < countries.length; i ++) {
    let country = countries[i]

    try {
      let countryName = country.split(' ')
      countryName[0] = countryName[0].toLowerCase();
      countryName.map((cn, index) => {
        if (index === 0) {
          return cn;
        } else {
          return cn.charAt(0).toUpperCase() + cn.slice(1);
        }
      })

      console.log(`Fetching data for -- ${country.toUpperCase()} --`);
      let file = await getFileFromUrl(`https://www.amcharts.com/lib/3/maps/svg/${countryName}High.svg`);
      console.log(file);
      console.log(`Fetched data for -- ${country.toUpperCase()} --`, file);

      console.log('--------------------------------------------------');

      console.log(`Creating file for -- ${country.toUpperCase()}`);
      downloadBlob(file, `${countryName}.svg`)
      console.log(`File for -- ${country.toUpperCase()} downloaded as ${countryName}.svg`);
      downloadedCount++;
    } catch (e) {
      console.error(`Failed to fetch svg for ${country}`);
      failedCount++
    }
  }

  console.warn('REPORT => ', {
    downloadedCount,
    failedCount
  });

};

export const globalFilterValidator = (filters = {}) => {
  return Object.values(filters).filter(Array.isArray).every(arr => arr.length > 0);
}
