import { Action, Module, VuexModule } from "vuex-module-decorators";
import { SurveyModel } from "@/store/models/SurveyModel";
import { SURVEY_API } from "@/router/BusinessApi";
import { diagLog } from "@/core/helpers/GlobalHelper";
import NotificationService from "@/buying-teams/services/NotificationService";
import ApiService from "@/core/services/ApiService";
import { sleep } from "@/core/utils/Utils";
import { MOCK_SURVEY } from "@/core/mock/MOCK_DATA";

@Module
export default class SurveyModule extends VuexModule {
    @Action
    async saveDraftData(draftData) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.SAVE_DRAFT, draftData)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async questionsToggle(questionInfo) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.QUESTIONS_TOGGLE, questionInfo)
                .then(res => {
                    diagLog("QUESTIONS_TOGGLE = ", res);
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async deleteCustomQuestion(questionInfo) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.DELETE_CUSTOM_QUESTIONS, questionInfo)
                .then(res => {
                    diagLog("DELETE_CUSTOM_QUESTIONS = ", res);
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async updateCustomQuestion(questionInfo) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.UPDATE_CUSTOM_QUESTIONS, questionInfo)
                .then(res => {
                    diagLog("UPDATE_CUSTOM_QUESTIONS = ", res);
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async createFeedback(data) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.CREATE_FEEDBACK, data)
                .then(res => {
                    diagLog("CREATE_REQUEST = ", res);
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async getFeedbacksList(data) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.GET_FEEDBACKS_LIST, data)
                .then(res => {
                    this.context.commit("SAVE_BUSINESS_FEEDBACK_SESSION_FILTER", res.data.filter);
                    resolve(res.data.list.map(el => new SurveyModel(el)));
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async getSurveyById(surveyId) {
        ApiService.setHeader();
        const params = {
            survey_id: surveyId
        };

        return new Promise((resolve, reject) => {
            ApiService.query(SURVEY_API.GET_SURVEY_BY_ID, { params })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }


    @Action
    async getSurveyStats(survey_id) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.query(SURVEY_API.GET_SURVEY_STATS, {
                params: { survey_id }
            })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async getSurveyQuestionById(question_id) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.query(SURVEY_API.GET_SURVEY_QUESTION_DETAILS, {
                params: {
                    survey_question_id: question_id
                }
            })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async replyToSurvey(data) {
        ApiService.setHeader();
        diagLog("REPLY DATA", data);
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.SAVE_REPLY_SURVEY, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async skipQuestion(data) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.SKIP_QUESTION, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async getSurveyDataForReplay(id) {
        return new Promise((resolve, reject) => {
            ApiService.query(SURVEY_API.GET_SURVEY_GET_NO_AUTH, {
                params: { id }
            })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async updateNoAuthBusiness(data) {
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.SAVE_SURVEY_NO_AUTH, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async replyToSurveyNoAuth(data) {
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.SAVE_REPLY_SURVEY_NO_AUTH, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async skipQuestionNoAuth(data) {
        return new Promise((resolve, reject) => {
            ApiService.post(SURVEY_API.SKIP_QUESTION_NO_AUTH, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    getSurveysStatsNoAuth(data_id) {
        return new Promise((resolve, reject) => {
            ApiService.query(SURVEY_API.GET_SURVEY_STATS_NO_AUTH, {
                params: { data_id }
            })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    getSurveysStatsAuth(survey_id) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.query(SURVEY_API.GET_SURVEY_STATS_AUTH, {
                params: { survey_id }
            })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }
}
