import CryptoJS from "crypto-js";
import { ERROR_TYPES, ErrorHandlerService } from "@/core/services/ErrorHandlerService";

const ENCRYPTION_KEY = process.env.VUE_APP_DATA_ENCRYPTION_KEY;

export class AESService {
  /**
   * FOR DEV
   */
  static decodeJson = (data: any) => {
    try {
      let result = JSON.parse(data);

      if (result) return result;
    } catch (e) {
      ErrorHandlerService.handleError(ERROR_TYPES.INVALID_DATA);
    }
  };

  static encodeJson = (data: any) => {
    return JSON.stringify(data);
  };

  /**
   * FOR PROD
   */
  // static decodeJson = (data: any) => {
  //   try {
  //     let result = JSON.parse(AESService.decrypt(data));
  //
  //     if (result) return result;
  //   } catch (e) {
  //     ErrorHandlerService.handleError(ERROR_TYPES.INVALID_DATA);
  //   }
  // };
  //
  // static encodeJson = (data: any) => {
  //   return AESService.encrypt(JSON.stringify(data));
  // };

  static encrypt = (str: string): string => {
    let key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    let src = CryptoJS.enc.Utf8.parse(str);
    let encrypted = CryptoJS.AES.encrypt(src, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });

    return encrypted.toString();
  };

  static decrypt = (str: string): string => {
    let key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    let decrypt = CryptoJS.AES.decrypt(str, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });

    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  };
}

/**
 * VERSION 2
 */
// const decodeJson = (data: any) => {
//   try {
//     let result = JSON.parse(atob(data));
//
//     if (result) return result
//   } catch (e) {
//     ErrorHandlerService.handleError(ERROR_TYPES.INVALID_DATA);
//   }
// }
//
// const encodeJson = (data: any) => {
//   return btoa(JSON.stringify(data));
// }
