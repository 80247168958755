import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { DataService } from "@/core/services/DataService";

interface IPlatformLinks {
    Privacy: string;
    Terms: string;
    Contactus: string;
    KnowledgeBase: string;
    Cookies: string;
    Guides: string;
    GDPRExport: string;
}

interface IPlatformLogos {
    asideLogo: string;
    whiteLogo: string;
    blueLogo: string;
}

export class PlatformConfigsModel {
    platformName: string;
    platformDomain: string;
    platformLogos: IPlatformLogos;
    platformLinks: IPlatformLinks;
    platformEmails: object;

    constructor(platformType?: PlatformTypeEnum) {
        if (!platformType) platformType = DataService.getPlatformType();

        switch (platformType) {
            case PlatformTypeEnum.BUSINESS:
            case PlatformTypeEnum.BANK:
            default:
                this.platformName = 'CXFacts';
                this.platformDomain = 'https://cxfacts.com/';
                this.platformLogos = {
                    asideLogo: '/media/logos/cxfacts/aside-logo-cxfacts.svg',
                    whiteLogo: '/media/logos/cxfacts/white-logo-cxfacts.png',
                    blueLogo: '/media/logos/cxfacts/blue-logo-cxfacts.png',
                };
                this.platformEmails = {
                    ContactEmail: 'hello@cxfacts.com'
                };
                break;
        }

        this.platformLinks = {
            Privacy: this.platformDomain + "privacy/",
            Terms: this.platformDomain + "terms/",
            Contactus: this.platformDomain + "contactus/",
            KnowledgeBase: this.platformDomain + "bank/knowledge-base/",
            Cookies: this.platformDomain + "cookies/",
            Guides: this.platformDomain + "business/knowledgebase/",
            GDPRExport: this.platformDomain + "privacy/submission-for-deletion/"
        };
    }
}
