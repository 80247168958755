import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import NotificationService from "@/buying-teams/services/NotificationService";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";
import { BusinessBankModel } from "@/store/models/BusinessBankModel";
import { RequestPriorityEnum } from "@/store/enums/request/RequestPriorityEnum";
import {ShareWithConstants} from "@/core/config/constants";
import { CountryUtil } from "@/core/utils/CountryUtil";

@Module
export default class StaticDataModule extends VuexModule {
  allCountriesData = [] as String[];
  allBanksData = new Map<Number, BusinessBankModel>();
  timePeriodsData = [] as any[];
  phasesData = [] as String[];
  globalRevenuesData = [] as Object[];
  employeesCountsData = [] as String[];
  industriesData = [] as String[];
  allProductAreasData = [] as String[];
  allJobTitlesData = [] as String[];

  get allBanks() {
    return Array.from(this.allBanksData.values());
  }

  get allProductAreas() {
    // return [
    //   'Trade Finance, Guarantees',
    //   'Trade Finance, Document Credits (LC)',
    //   'Trade Finance, Collections (CAD)',
    //   'The Bank/Corporate Banking',
    //   'Cash Management',
    //   'Corporate Cards',
    //   'Foreign Exchange',
    //   'Leasing',
    //   'Working Capital, Supply Chain Financing',
    //   'Working Capital, Factoring',
    //   'ESG'
    // ];

    return this.allProductAreasData;
  }

  get allIndustries() {
    // return [
    //   'Energy & Utilities',
    //   'Materials',
    //   'Industrials',
    //   'Consumer Goods',
    //   'Health Care & Pharma',
    //   'Transportation',
    //   'Shipping',
    //   'Financial Sector',
    //   'Information Technology',
    //   'Communication Services',
    //   'Real Estate',
    //   'Public Sector'
    // ];

    return this.industriesData;
  }

  get employeesCounts() {
    // return [
    //   '< 500',
    //   '500 - 1000',
    //   '1000 - 5000',
    //   '5000 - 10000',
    //   '10000 - 50000',
    //   '> 50000'
    // ];

    return this.employeesCountsData;
  }

  get globalRevenues() {
    // return [
    //   "< 50mn",
    //   "50 - 100mn",
    //   "100 - 500mn",
    //   "500mn - 1bn",
    //   "1 - 5bn",
    //   "> 5BN"
    // ];

    return this.globalRevenuesData;
  }

  get phases() {
    return [
      "Advisory & Sales",
      "Delivery",
      "Daily Use"
    ];

    // return this.phasesData;
  }

  get priority() {
    return [
      { label: "Low", value: RequestPriorityEnum.LOW },
      { label: "Normal", value: RequestPriorityEnum.NORMAL },
      { label: "High", value: RequestPriorityEnum.HIGH },
      { label: "Critical", value: RequestPriorityEnum.CRITICAL },
    ];
  }

  get share_with() {
    return [
      { label: "Internally", value: ShareWithConstants.INTERNALLY },
      { label: "With Bank", value: ShareWithConstants.WITH_BANK }
    ]
  }

  get allRepeatSession() {
    return ['monthly', 'quarterly', 'half-yearly', 'yearly'];
  }

  get allCountries() {
    return this.allCountriesData;
  }

  get jobTitles() {
    return this.allJobTitlesData;
  }

  get supportedLocales() {
    return [
      // {
      //   key: 'en',
      //   flag: "/media/flags/united-states.svg",
      //   name: "English",
      // },
      // {
      //   key: 'fr',
      //   flag: "/media/flags/france.svg",
      //   name: "French",
      // },
    ]
  }

  get timePeriods() {
    return [
      { title: "Current month", value: "current_month" },
      { title: "Previous month", value: "previous_month" },
      { title: "Current quarter", value: "current_quarter" },
      { title: "Previous quarter", value: "previous_quarter" },
      { title: "Current year", value: "current_year" },
      { title: "Previous year", value: "previous_year" },
      { title: "All", value: "*" }
    ];

    // return this.timePeriodsData;
  }

  get tierList() {
    return [
      {
        label: 'Tier 1',
        value: 1,
        globalRevenue: '< €1 Bn',
      },
      {
        label: 'Tier 2',
        value: 2,
        globalRevenue: '€51 Mn - €1 Bn',
      },
      {
        label: 'Tier 3',
        value: 3,
        globalRevenue: '> €50 Mn',
      }
    ]
  }

  @Mutation
  SAVE_COUNTRIES(payload) {
    this.allCountriesData = payload;
  }

  @Mutation
  SAVE_BANKS(payload) {
    // payload.forEach(bank => {
    //   this.allBanksData.set(bank.id, new BusinessBankModel(bank));
    // })
    // console.log('bank payload = ', payload);
    this.allBanksData = payload;
  }

  @Mutation
  SAVE_PRODUCT_AREAS(payload) {
    this.allProductAreasData = payload;
  }

  @Mutation
  SAVE_INDUSTRIES(payload) {
    this.industriesData = payload;
  }

  @Mutation
  SAVE_TIME_PERIODS(payload) {
    this.timePeriodsData = payload;
  }

  @Mutation
  SAVE_REVENUES(payload) {
    this.globalRevenuesData = payload;
  }

  @Mutation
  SAVE_PHASES(payload) {
    this.phasesData = payload;
  }

  @Mutation
  SAVE_EMPLOYEE_COUNTS(payload) {
    this.employeesCountsData = payload;
  }
  @Mutation
  SAVE_JOB_TITLES(payload) {
    this.allJobTitlesData = payload;
  }

  @Action
  async fetchStaticDataAPI(keyword: string | null) {
    ApiService.setHeader();
    const params = {
      category: keyword,
      version: 0
    };

    return new Promise(async (resolve, reject) => {
      await ApiService.query("/static_data", {params})
        .then(res => {
          if (res.data && res.data.data) {
            switch (res.data.category) {
              case StaticDataEnum.PRODUCT_AREAS:
                this.context.commit("SAVE_PRODUCT_AREAS", res.data.data);
                break;
              case StaticDataEnum.COUNTRIES:
                let countries = require('./../../core/static-data/countries.json');
                this.context.commit("SAVE_COUNTRIES", countries);
                break;
              case StaticDataEnum.INDUSTRIES:
                this.context.commit("SAVE_INDUSTRIES", res.data.data);
                break;
              case StaticDataEnum.EMPLOYEE_COUNTS:
                this.context.commit("SAVE_EMPLOYEE_COUNTS", res.data.data);
                break;
              case StaticDataEnum.REVENUES:
                let revenues: any = []
                res.data.data.map(el => revenues.push({label: el + ' EUR', value: el}))

                this.context.commit("SAVE_REVENUES", revenues);
                break;
              case StaticDataEnum.JOB_TITLES:
                this.context.commit("SAVE_JOB_TITLES", res.data.data);
                break;
              case StaticDataEnum.PHASES:
                this.context.commit("SAVE_PHASES", res.data.data);
                break;
              case StaticDataEnum.BANKS:
                this.context.commit("SAVE_BANKS", res.data.data);
                break;
              default:
                NotificationService.swalError('Add static data type ' + res.data.category);
                break;
            }
          }
          resolve(res);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        });
    })

  }

  @Action
  async fetchStaticData(keyword: string | null) {
    await this.context.dispatch('setStaticData', keyword);
  }

  @Action
  async setStaticData(keyword: string | null) {
    switch (keyword) {
      case StaticDataEnum.PRODUCT_AREAS:
        const productAreas = require('./../../core/static-data/product-areas.json');
        this.context.commit("SAVE_PRODUCT_AREAS", productAreas);
        break;
      case StaticDataEnum.COUNTRIES:
        const countries = require('./../../core/static-data/countries.json');
        this.context.commit("SAVE_COUNTRIES", countries);
        CountryUtil.init(countries);
        break;
      case StaticDataEnum.INDUSTRIES:
        const industries = require('./../../core/static-data/industries.json');
        this.context.commit("SAVE_INDUSTRIES", industries);
        break;
      case StaticDataEnum.EMPLOYEE_COUNTS:
        const employeeCount = require('./../../core/static-data/employee-count.json');
        this.context.commit("SAVE_EMPLOYEE_COUNTS", employeeCount);
        break;
      case StaticDataEnum.REVENUES:
        const revenues = require('./../../core/static-data/revenues.json');
        let revenuesData: any = []
        revenues.map(el => revenuesData.push({label: el + ' EUR', value: el}))
        this.context.commit("SAVE_REVENUES", revenuesData);
        break;
      case StaticDataEnum.JOB_TITLES:
        this.context.commit("SAVE_JOB_TITLES", []);
        break;
      case StaticDataEnum.PHASES:
        const phases = require('./../../core/static-data/phases.json');
        this.context.commit("SAVE_PHASES", phases);
        break;
      case StaticDataEnum.BANKS:
        let banks = await this.context.dispatch('fetchStaticDataAPI', StaticDataEnum.BANKS);
        // const banks = require('./../../core/static-data/banks.json');
        this.context.commit("SAVE_BANKS", banks.data.data);
        break;
      default:
        NotificationService.swalError('Add static data type ' + keyword);
        break;
    }


  }
}
