
const registerRoutes = [
  {
    path: "/register/company-info",
    name: "business-register/company-info",
    component: () =>
      import("@/buying-teams/pages/authentication/setup/business/BusinessCompanySetup.vue"),
  },
  {
    path: "/register/personal-info",
    name: "business-register/personal-info",
    component: () =>
      import("@/buying-teams/pages/authentication/setup/business/BusinessPersonalInfoSetup.vue"),
  },
  {
    path: "/register/ext-company-info",
    name: "business-register/ext-company-info",
    component: () =>
        import("@/buying-teams/pages/authentication/setup/business/InvBusinessCompanySetup.vue"),
  },
  {
    path: "/register/ext-personal-info",
    name: "business-register/ext-personal-info",
    component: () =>
        import("@/buying-teams/pages/authentication/setup/business/InvBusinessPersonalInfoSetup.vue"),
  },
];

export default registerRoutes;
