import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { BusinessBankModel } from "@/store/models/BusinessBankModel";
import { Mutations } from "@/store/enums/StoreEnums";
import { BusinessModel } from "@/store/models/BusinessModel";
import store from "@/store";
import { BUSINESS_API } from "@/router/BusinessApi";
import { SurveyModel } from "@/store/models/SurveyModel";
import { BusinessDashboardFilterModel } from "@/store/models/filter/BusinessDashboardFilterModel";
import { BusinessFilterService } from "@/core/services/BusinessFilterService";
import { DataService } from "@/core/services/DataService";
import axios from "axios";
import NotificationService from "@/buying-teams/services/NotificationService";
import CircleChartModel from "@/store/models/CircleChartModel";
import {diagLog, getOrdered, translateGlobal} from "@/core/helpers/GlobalHelper";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import {BusinessPageTypes} from "@/store/enums/business/BusinessPageTypes";

@Module
export default class BusinessModule extends VuexModule {
  dashboardData = {} as any;
  businessBanksData = [] as BusinessBankModel[];
  businessData = DataService.getBusinessData();
  businessProductAreasData = [] as string[];
  businessAllowedProductAreasData = [] as string[];
  businessCountriesData = [] as String[];
  businessLiveSurveysData = [] as SurveyModel[];
  businessDashboardFilterData = null as BusinessDashboardFilterModel | null;
  dashboardLoading = false;
  businessInfo = {} as any;

  get businessDashboardData() {
    return this.dashboardData;
  }

  get businessBankIds() {
    return this.businessBanks.map(el => el.id);
  }

  get businessBanks() {
    return this.businessBanksData;
  }

  get getDashboardLoading() {
    return this.dashboardLoading;
  }

  get businessProductAreas() {
    return this.businessProductAreasData;
  }

  get businessAllowedProductAreas() {
    const currentUser = store.getters.currentUser;
    return currentUser.isAdmin
      ? this.businessProductAreasData
      : this.businessAllowedProductAreasData;
  }

  get businessCountries() {
    return this.businessCountriesData;
  }

  get businessLiveSurveys() {
    return this.businessLiveSurveysData;
  }

  get businessDashboardFilter() {
    return this.businessDashboardFilterData;
  }

  get business() {
    return this.businessData;
  }

  get getBusinessInfoData() {
    return this.businessInfo;
  }

  @Mutation
  ADD_COUNTRIES_TO_BUSINESS(payload: string[]) {
    payload.forEach((ct: string) => {
      if (!this.businessCountriesData.includes(ct)) this.businessCountriesData.push(ct)
    })
  }

  @Mutation
  ADD_BANK_COUNTRIES(payload: string[]) {
    this.businessCountriesData = payload
  }

  @Mutation
  ADD_BANK_TO_BUSINESS(bank: any) {
    let existingBank = this.businessBanksData.find(u_bank => u_bank.id === bank.id);
    if (!existingBank) {
      this.businessBanksData.push(new BusinessBankModel(bank))
    }
  }

  @Mutation
  REMOVE_BANK_TO_BUSINESS(payload) {
    this.businessBanksData = payload
  }

  @Mutation
  ADD_AREA_TO_BUSINESS({areas, isAdmin}: any) {
    let productAreas: string[] = this.businessProductAreasData;
    if (isAdmin) {
      areas.forEach(val => {
        if (!productAreas.includes(val)) {
          productAreas.push(val)
        }
      })
    } else {
      productAreas = areas
    }

    store.commit('RESET_FILTERS_WITH_PRODUCT_AREAS');
    this.businessProductAreasData = productAreas.sort((a, b) => a.localeCompare(b))
  }

  @Mutation
  SAVE_BUSINESS_INITIAL_DATA(payload) {
    // store banks
    this.businessBanksData = payload.banks.map(bank => new BusinessBankModel(bank))
    // store product areas
    this.businessProductAreasData = payload.user_product_areas;
    // store allowed product areas
    this.businessAllowedProductAreasData = payload.allowed_product_areas;
    // store business countries
    this.businessCountriesData = payload.bank_countries;
  }

  @Mutation
  SAVE_DATA(payload) {
    //store live surveys
    this.businessLiveSurveysData = payload.surveys.map(survey => new SurveyModel(survey));

    // Dashboard source product area data
    let productAreaLow: any = [];
    let productAreaHigh: any = [];
    let highestNegativeGap: any = [];
    let highestPositiveGap: any = [];

    let source_product_area_data: any[] = payload.source_product_area_data;
    if (source_product_area_data) {
      source_product_area_data = source_product_area_data.map(val => {
        return new CircleChartModel({
          importance: val.scores.importance,
          score: val.scores.score,
          scores: val.scores.scores,
          gap: val.scores.score - val.scores.importance,
          product_area: val.name
        })
      })

      productAreaHigh = getOrdered([...source_product_area_data], 'score')
      productAreaLow = [...productAreaHigh].reverse()

      highestPositiveGap = getOrdered([...source_product_area_data], 'gap')
      highestNegativeGap = [...highestPositiveGap].reverse()
    }

    this.dashboardData = {
      bank_score: payload.bank_score ? new CircleChartModel({...{}, ...payload.bank_score, ...{bank_name: translateGlobal('All Banks')}}) : null,
      product_area_tab_scores: !source_product_area_data ?
        null :
        {
          top_score: productAreaHigh,
          bottom_score: productAreaLow,
          positive_gap: highestPositiveGap,
          negative_gap: highestNegativeGap
        },
      banks_tab_scores: !payload.banks_data ?
        null :
        {
          top_score: payload.banks_data.map(ts => new CircleChartModel({...{}, ...{bank_name: ts.name, ...ts.scores}, ...ts})).reverse(),
          bottom_score: payload.banks_data.map(ts => new CircleChartModel({...{}, ...{bank_name: ts.name, ...ts.scores}, ...ts})),
        },

      requests: payload.requests ? payload.requests : null,
      ideas: payload.ideas ? payload.ideas : []
    };
  }

  @Mutation
  SAVE_DASHBOARD_FILTER(filterData) {
    this.businessDashboardFilterData = new BusinessDashboardFilterModel(filterData);
    BusinessFilterService.storeDashboardFilter(this.businessDashboardFilterData);
  }

  @Mutation
  SAVE_BUSINESS_INFO(businessData) {
    this.businessData = new BusinessModel(businessData);
    DataService.storeBusinessData(this.businessData);
  }

  @Mutation
  SET_DASHBOARD_LOADING(value) {
    this.dashboardLoading = value;
  }

  @Mutation
  SET_BUSINESS_INFO(value) {
    this.businessInfo = value;
  }

  @Action
  async fetchBusinessInitialData() {
    this.context.commit('SET_DASHBOARD_LOADING', false);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(BUSINESS_API.BUSINESS_INITIAL_DATA)
          .then(res => {
            diagLog("SAVE_BUSINESS_INITIAL_DATA = ", res);
            this.context.commit(Mutations.SET_AUTH, res.data.business);
            this.context.commit("SAVE_BUSINESS_INITIAL_DATA", res.data);
            store.dispatch('initBusinessMainPagesFilters');

            resolve(res.data);
          })
          .catch(error => {
            NotificationService.swalError(error);
            reject();
          }).finally(()=>{
        this.context.commit('SET_DASHBOARD_LOADING', false);
      })
    })

  }

  @Action
  async fetchBusinessDashboardData(filters) {
    ApiService.setHeader();
    const params = {
      filter: filters ? new BusinessDashboardFilterModel(filters) : filters,
    };
    this.context.commit('SET_DASHBOARD_LOADING', true);
    return new Promise((resolve, reject) => {
      ApiService.post(BUSINESS_API.GET_DASHBOARD_DATA, params)
        .then(res => {
          diagLog("GET_DASHBOARD_DATA = ", res);
          this.context.commit("SAVE_DATA", res.data);
          this.context.commit("SAVE_BUSINESS_OVERVIEW_DASHBOARD_FILTER", res.data.filter);
          store.dispatch('initBusinessMainPagesFilters');

          resolve(res.data);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        }).finally(()=>{
        this.context.commit('SET_DASHBOARD_LOADING', false);
      })
    });
  }

  @Action
  async fetchBusinessDashboardQuestions(payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(BUSINESS_API.BUSINESS_SURVEY_QUESTIONS, payload)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            NotificationService.swalError(error);
            reject();
          })
    })
  }

  @Action
  async fetchBusinessDashboardDataLevelTwo({ filter, type }) {
    return new Promise((resolve, reject) => {
      ApiService.post(BUSINESS_API.BUSINESS_BANKS_DATA, {filter, type})
        .then(res => {
          switch (type) {
            case BusinessPageTypes.BANK:
              this.context.commit("SAVE_BUSINESS_BANKS_DASHBOARD_FILTER", filter);
              break;
            case BusinessPageTypes.COUNTRY:
                this.context.commit("SAVE_BUSINESS_COUNTRIES_DASHBOARD_FILTER", filter);
                break;
            case BusinessPageTypes.PRODUCT_AREA:
                this.context.commit("SAVE_BUSINESS_PRODUCT_AREAS_DASHBOARD_FILTER", filter);
              break;
          }
          resolve(res.data);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    });
  }

  @Action
  async updateBusinessDetails(data) {
    return new Promise((resolve, reject) => {
        ApiService.post(BUSINESS_API.UPDATE_BUSINESS_DATA, data)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject();
          });
      });
  }

  @Action
  async imageUpload(files) {
    return new Promise(async (resolve) => {
      let url = ''
      ApiService.setHeader()
      let resp = {}
      await ApiService.post('/images/upload/init', null).then(res => {
        resp = res.data
        url = res.data.uploadURL
      })

      delete axios.defaults.headers.common["Authorization"];
      await axios.put(url, files[0])
      ApiService.setHeader()

      resolve(resp)
    })
  }

  @Action
  async getBusinessInfo() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(BUSINESS_API.BUSINESS_STATS)
        .then(res => {
          this.context.commit('SET_BUSINESS_INFO', res.data);
          resolve(res);
        })
        .catch(error => {
          console.error(error);
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async fetchSpecificBankData({ filter, bankId }) {
    return new Promise((resolve, reject) => {
      ApiService.post(BUSINESS_API.SPECIFIC_BANK_PAGE, {filter, bank_id: bankId})
        .then(res => {
            this.context.commit("SAVE_BUSINESS_SINGLE_BANK_DASHBOARD_FILTER", filter);
            resolve(res.data);
        })
        .catch(error => {
          console.error(error);
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async fetchSpecificCountryData({ filter, country }) {
    return new Promise((resolve, reject) => {
      ApiService.post(BUSINESS_API.SPECIFIC_COUNTRY_PAGE, {filter, country})
        .then(res => {
            this.context.commit("SAVE_BUSINESS_SINGLE_COUNTRY_DASHBOARD_FILTER", filter);
            resolve(res.data);
        })
        .catch(error => {
          console.error(error);
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async fetchSpecificProductAreaData({ filter, productArea }) {
    return new Promise((resolve, reject) => {
      ApiService.post(BUSINESS_API.SPECIFIC_PRODUCT_AREA_PAGE, {filter, product_area: productArea})
        .then(res => {
            this.context.commit("SAVE_BUSINESS_SINGLE_PRODUCT_AREA_DASHBOARD_FILTER", filter);
            resolve(res.data);
        })
        .catch(error => {
          console.error(error);
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async fetchSpecificQuestionData({ filter, questionId, isCustom }) {
    return new Promise((resolve, reject) => {
      ApiService.post(BUSINESS_API.SPECIFIC_QUESTION_PAGE, {filter, question_id: questionId, is_custom: isCustom})
        .then(res => {
            this.context.commit("SAVE_BUSINESS_SINGLE_QUESTION_FILTER", filter);
            resolve(res.data);
        })
        .catch(error => {
          console.error(error);
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async fetchSpecificQuestionComments(payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(BUSINESS_API.SPECIFIC_QUESTION_COMMENTS, payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          console.error(error);
          NotificationService.swalError(error);
          reject();
        });
    })
  }


}
