enum BankFiltersTypeEnum {
  DASHBOARD = 'dashboard',
  PRODUCT_AREAS = 'product_areas',
  PRODUCT_AREA_OVERVIEW = 'product_area',
  COUNTRY_OVERVIEW = 'country',
  COUNTRIES = 'countries',
  SINGLE_QUESTION = 'single_question',
  REQUEST = 'request',
  IDEA = 'idea',
  CUSTOMERS = 'customers',
  SURVEY = 'survey',
  SINGLE_SURVEY = 'single_survey',
  SINGLE_CUSTOMER = 'single_customer',
}

export default BankFiltersTypeEnum;
