import { BusinessDashboardFilterModel } from "@/store/models/filter/BusinessDashboardFilterModel";
import { BusinessRequestFilterModel } from "@/store/models/filter/BusinessRequestFilterModel";
import { BusinessFeedbackSessionFilterModel } from "@/store/models/filter/BusinessFeedbackSessionFilterModel";
import { BusinessIdeaFilterModel } from "@/store/models/filter/BusinessIdeaFilterModel";
import { BusinessTeamFilterModel } from "@/store/models/filter/BusinessTeamFilterModel";
import store from "@/store";
import { BusinessBankModel } from "@/store/models/BusinessBankModel";
import { DataService } from "@/core/services/DataService";

const DASHBOARD_FILTER_KEY = 'businessDashboardFilters';
const REQUEST_FILTER_KEY = 'businessRequestFilters';
const FEEDBACK_SESSION_FILTER_KEY = 'businessFeedbackSessionFilters';
const IDEA_FILTER_KEY = 'businessIdeaFilters';
const TEAM_FILTER_KEY = 'businessTeamFilters';

const DASHBOARD_OVERVIEW_FILTER_KEY = 'businessDashboardOverviewFilters';
const DASHBOARD_BANKS_FILTER_KEY = 'businessDashboardBanksFilters';
const DASHBOARD_PRODUCT_AREAS_FILTER_KEY = 'businessDashboardProductAreasFilters';
const DASHBOARD_COUNTRIES_FILTER_KEY = 'businessDashboardCountriesFilters';
const DASHBOARD_SINGLE_BANK_FILTER_KEY = 'businessDashboardSingleBankFilters';
const DASHBOARD_SINGLE_PRODUCT_AREA_FILTER_KEY = 'businessDashboardSingleProductAreaFilters';
const DASHBOARD_SINGLE_COUNTRY_FILTER_KEY = 'businessDashboardSingleCountryFilters';
const SINGLE_QUESTION_FILTER_KEY = 'businessSingleQuestionFilters';

const storage = localStorage

export class BusinessFilterService {
    static needSave: boolean = true;
    static storeDashboardOverviewFilter(filters: any) {
        if (!this.needSave) return;
        storage.setItem(DASHBOARD_OVERVIEW_FILTER_KEY, JSON.stringify(filters));
    }

    static getDashboardOverviewFilters() {
        let filters: any = storage.getItem(DASHBOARD_OVERVIEW_FILTER_KEY);
        if (filters) filters = JSON.parse(filters);

        return filters;
    }

    static destroyDashboardOverviewFilter() {
        storage.removeItem(DASHBOARD_OVERVIEW_FILTER_KEY);
    }

    static storeDashboardBanksFilter(filters: any) {
        if (!this.needSave) return;
        storage.setItem(DASHBOARD_BANKS_FILTER_KEY, JSON.stringify(filters));
    }

    static getDashboardBanksFilters() {
        let filters: any = storage.getItem(DASHBOARD_BANKS_FILTER_KEY);
        if (filters) filters = JSON.parse(filters);

        return filters;
    }

    static destroyDashboardBanksFilters() {
        storage.removeItem(DASHBOARD_BANKS_FILTER_KEY);
    }

    static storeDashboardProductAreasFilter(filters: any) {
        if (!this.needSave) return;
        storage.setItem(DASHBOARD_PRODUCT_AREAS_FILTER_KEY, JSON.stringify(filters));
    }

    static getDashboardProductAreasFilters() {
        let filters: any = storage.getItem(DASHBOARD_PRODUCT_AREAS_FILTER_KEY);
        if (filters) filters = JSON.parse(filters);

        return filters;
    }

    static destroyDashboardProductAreasFilters() {
        storage.removeItem(DASHBOARD_PRODUCT_AREAS_FILTER_KEY);
    }

    static storeDashboardCountriesFilter(filters: any) {
        if (!this.needSave) return;
        storage.setItem(DASHBOARD_COUNTRIES_FILTER_KEY, JSON.stringify(filters));
    }

    static getDashboardCountriesFilters() {
        let filters: any = storage.getItem(DASHBOARD_COUNTRIES_FILTER_KEY);
        if (filters) filters = JSON.parse(filters);

        return filters;
    }

    static destroyDashboardCountriesFilters() {
        storage.removeItem(DASHBOARD_COUNTRIES_FILTER_KEY);
    }

    static storeDashboardSingleBankFilter(filters: any) {
        if (!this.needSave) return;
        storage.setItem(DASHBOARD_SINGLE_BANK_FILTER_KEY, JSON.stringify(filters));
    }

    static getDashboardSingleBankFilters() {
        let filters: any = storage.getItem(DASHBOARD_SINGLE_BANK_FILTER_KEY);
        if (filters) filters = JSON.parse(filters);

        return filters;
    }

    static destroyDashboardSingleBankFilters() {
        storage.removeItem(DASHBOARD_SINGLE_BANK_FILTER_KEY);
    }

    static storeDashboardSingleProductAreaFilter(filters: any) {
        if (!this.needSave) return;
        storage.setItem(DASHBOARD_SINGLE_PRODUCT_AREA_FILTER_KEY, JSON.stringify(filters));
    }

    static getDashboardSingleProductAreaFilters() {
        let filters: any = storage.getItem(DASHBOARD_SINGLE_PRODUCT_AREA_FILTER_KEY);
        if (filters) filters = JSON.parse(filters);

        return filters;
    }

    static destroyDashboardSingleProductAreaFilters() {
        storage.removeItem(DASHBOARD_SINGLE_PRODUCT_AREA_FILTER_KEY);
    }

    static storeDashboardSingleCountryFilter(filters: any) {
        if (!this.needSave) return;
        storage.setItem(DASHBOARD_SINGLE_COUNTRY_FILTER_KEY, JSON.stringify(filters));
    }

    static getDashboardSingleCountryFilters() {
        let filters: any = storage.getItem(DASHBOARD_SINGLE_COUNTRY_FILTER_KEY);
        if (filters) filters = JSON.parse(filters);

        return filters;
    }

    static destroyDashboardSingleCountryFilters() {
        storage.removeItem(DASHBOARD_SINGLE_COUNTRY_FILTER_KEY);
    }

    static storeSingleQuestionFilter(filters: any) {
        if (!this.needSave) return;
        storage.setItem(SINGLE_QUESTION_FILTER_KEY, JSON.stringify(filters));
    }

    static getSingleQuestionFilters() {
        let filters: any = storage.getItem(SINGLE_QUESTION_FILTER_KEY);
        if (filters) filters = JSON.parse(filters);

        return filters;
    }

    static destroySingleQuestionFilters() {
        storage.removeItem(SINGLE_QUESTION_FILTER_KEY);
    }

    static storeDashboardFilter(filters: BusinessDashboardFilterModel) {
        storage.setItem(DASHBOARD_FILTER_KEY, JSON.stringify(filters));
    }

    static getDashboardFilter() {
        return storage.getItem(DASHBOARD_FILTER_KEY);
    }

    static destroyDashboardFilter() {
        storage.removeItem(DASHBOARD_FILTER_KEY);
    }

    static storeRequestFilter(filters: BusinessRequestFilterModel) {
        storage.setItem(REQUEST_FILTER_KEY, JSON.stringify(filters));
    }

    static getRequestFilter(): BusinessRequestFilterModel | null {
        let filters: any = storage.getItem(REQUEST_FILTER_KEY);
        if (filters) filters = new BusinessRequestFilterModel(JSON.parse(filters))

        return filters;
    }

    static destroyRequestFilter() {
        storage.removeItem(REQUEST_FILTER_KEY);
    }

    static storeFeedbackSessionFilter(filters: BusinessFeedbackSessionFilterModel) {
        storage.setItem(FEEDBACK_SESSION_FILTER_KEY, JSON.stringify(filters));
    }

    static getFeedbackSessionFilter(): BusinessFeedbackSessionFilterModel | null {
        let filters: any = storage.getItem(FEEDBACK_SESSION_FILTER_KEY);
        if (filters) filters = new BusinessFeedbackSessionFilterModel(JSON.parse(filters))

        return filters;
    }

    static destroyFeedbackSessionFilter() {
        storage.removeItem(FEEDBACK_SESSION_FILTER_KEY);
    }

    static storeIdeaFilter(filters: BusinessIdeaFilterModel) {
        storage.setItem(IDEA_FILTER_KEY, JSON.stringify(filters));
    }

    static getIdeaFilter(): BusinessIdeaFilterModel | null {
        let filters: any = storage.getItem(IDEA_FILTER_KEY);
        if (filters) filters = new BusinessIdeaFilterModel(JSON.parse(filters))

        return filters;
    }

    static destroyIdeaFilter() {
        storage.removeItem(IDEA_FILTER_KEY);
    }

    static storeTeamFilter(filters: BusinessTeamFilterModel) {
        storage.setItem(TEAM_FILTER_KEY, JSON.stringify(filters));
    }

    static getTeamFilter(): BusinessTeamFilterModel | null {
        let filters: any = storage.getItem(TEAM_FILTER_KEY);
        if (filters) filters = new BusinessTeamFilterModel(JSON.parse(filters))

        return filters;
    }

    static destroyTeamFilter() {
        storage.removeItem(TEAM_FILTER_KEY);
    }

    static getBusinessFiltersData() {
        // This function returns all filters data
        let dashboardFilter = store.getters.getBusinessFiltersState.dashboardOverview
        let feedbackSessionFilter = store.getters.getBusinessFiltersState.feedbackSession
        let requestFilter = store.getters.getBusinessFiltersState.requests
        let ideaFilter = store.getters.getBusinessFiltersState.ideas

        return {
            dashboardFilter,
            feedbackSessionFilter,
            requestFilter,
            ideaFilter
        }
    }

    static commitNewFilters(dashboard, feedbackSession, request, idea) {
        store.commit('SAVE_BUSINESS_OVERVIEW_DASHBOARD_FILTER', dashboard)
        store.commit('SAVE_BUSINESS_FEEDBACK_SESSION_FILTER', feedbackSession)
        store.commit('SAVE_BUSINESS_REQUESTS_FILTER', request)
        store.commit('SAVE_BUSINESS_IDEAS_FILTER', idea)
    }

    static addNewBankInFilters(newBank: BusinessBankModel) {
        store.commit('ADD_COUNTRIES_TO_BUSINESS', newBank.countries)
        let {dashboardFilter, feedbackSessionFilter, requestFilter, ideaFilter} = this.getBusinessFiltersData()
        let bankCountries = store.getters.businessCountries

        dashboardFilter.bank_ids.push(newBank.id)
        feedbackSessionFilter.bank_ids.push(newBank.id)
        requestFilter.bank_ids.push(newBank.id)
        ideaFilter.bank_ids.push(newBank.id)

        dashboardFilter.countries = bankCountries
        feedbackSessionFilter.countries = bankCountries
        requestFilter.countries = bankCountries
        ideaFilter.countries = bankCountries

        store.commit('ADD_BANK_TO_BUSINESS', newBank)
        this.commitNewFilters(dashboardFilter, feedbackSessionFilter, requestFilter, ideaFilter)
    }

    static deleteBankInFilters(bankData, editedItem) {
        let {dashboardFilter, feedbackSessionFilter, requestFilter, ideaFilter} = this.getBusinessFiltersData()
        let banks = store.getters.currentUser.business_banks;
        let user = store.getters.currentUser
        let userData = {...user};
        banks.splice(banks.indexOf(bankData), 1)
        let banksIds = banks.map(item => item.id)

        userData.business_banks = banks;
        DataService.storeAuthUserData(userData);

        dashboardFilter.bank_ids = banksIds
        feedbackSessionFilter.bank_ids = banksIds
        requestFilter.bank_ids = banksIds
        ideaFilter.bank_ids = banksIds
        store.commit('REMOVE_BANK_TO_BUSINESS', banks)
        this.commitNewFilters(dashboardFilter, feedbackSessionFilter, requestFilter, ideaFilter)
        this.mergeBanksCountries(bankData, editedItem)
    }

    static mergeBanksCountries(bankData, editedItem = []) {
        let {dashboardFilter, feedbackSessionFilter, requestFilter, ideaFilter} = this.getBusinessFiltersData()
        let countries: object[] = [...bankData.countries]
        let allBanks = store.getters.currentUser.business_banks
        allBanks.forEach(val => {
            countries = [...countries, ...val.countries]
        })

        if (editedItem.length) {
            // Check if we need to delete the country from the list
            editedItem.forEach(val => {
                let includedItemsCount = countries.filter(x => x === val).length
                if (includedItemsCount === 1) {
                    countries = countries.filter(item => item !== val)
                }
            })
        }

        // Remove duplicates from country list
        let mergedCountries = Array.from(new Set(countries))

        dashboardFilter.countries = mergedCountries
        feedbackSessionFilter.countries = mergedCountries
        requestFilter.countries = mergedCountries
        ideaFilter.countries = mergedCountries

        // Update new country list
        store.commit('ADD_BANK_COUNTRIES', mergedCountries)
        this.commitNewFilters(dashboardFilter, feedbackSessionFilter, requestFilter, ideaFilter)
    }

    static removeAllFilters() {
        this.destroyDashboardFilter();
        this.destroyRequestFilter();7
        this.destroyFeedbackSessionFilter();
        this.destroyIdeaFilter();
        this.destroyTeamFilter();
        this.destroyDashboardOverviewFilter();
        this.destroyDashboardBanksFilters();
        this.destroyDashboardProductAreasFilters();
        this.destroyDashboardCountriesFilters();
        this.destroyDashboardSingleBankFilters();
        this.destroyDashboardSingleProductAreaFilters();
        this.destroyDashboardSingleCountryFilters();
        this.destroySingleQuestionFilters();
    }

}
