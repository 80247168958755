import {Module, VuexModule, Action, Mutation} from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { IDEAS_API } from "@/router/BankApi";
import NotificationService from "@/buying-teams/services/NotificationService";
import {BankIdeaModel} from "@/store/models/bank/idea/BankIdeaModel";
import {BankIdeaFilterModel} from "@/store/models/filter/bank/BankIdeaFilterModel";
import {BankFilterService} from "@/core/services/BankFilterService";

@Module
export default class BankIdeasModule extends VuexModule {
  bankIdeaFilterData = {} as BankIdeaFilterModel;
  bankIdeas: BankIdeaModel[] = [];
  bankIdeasLoading: boolean = true;

  get bankIdeaFilter() {
    return BankFilterService.getBankIdeaFilter();
  }

  get getBankIdeasData() {
    return this.bankIdeas;
  }

  get getBankIdeasLoading() {
    return this.bankIdeasLoading;
  }

  @Mutation
  SAVE_BANK_IDEA_FILTER(filterData) {
    this.bankIdeaFilterData = new BankIdeaFilterModel(filterData);
    BankFilterService.storeBankIdeaFilter(this.bankIdeaFilterData);
  }

  @Mutation
  SAVE_BANK_IDEA_DATA(data: BankIdeaModel[]) {
    this.bankIdeas = data;
  }

  @Mutation
  SAVE_BANK_IDEA_LOADING(value: boolean) {
    this.bankIdeasLoading = value;
  }

  @Action
  getBankIdeas(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(IDEAS_API.GET_IDEAS, data)
        .then(res => {
          const ideas = res.data.data.map(el => new BankIdeaModel(el));
          this.context.commit('SAVE_BANK_IDEA_FILTER', data.filter)
          this.context.commit('SAVE_BANK_IDEA_DATA', ideas)
          resolve(ideas)
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        }).finally(() => {
          this.context.commit('SAVE_BANK_IDEA_LOADING', false)
      });
    })
  }

  @Action
  async getBankIdeaDetails(id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(IDEAS_API.GET_IDEA_BY_ID, `?intent_id=${id}`)
        .then(res => {
          if (res.data) {
            resolve(new BankIdeaModel(res.data));
          }
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }
}
