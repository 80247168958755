import {BaseBankFilterModel} from "@/store/models/base/BaseBankFilterModel";

export class BankIdeaFilterModel extends BaseBankFilterModel {
  show_closed_items: boolean;

  constructor(obj: any) {
    super(obj);

    this.show_closed_items = obj['show_closed_items'] || false
  }
}
