
const BANK_API = {
  GET_BANK_OVERVIEW_DASHBOARD_DATA: 'bank/dashboard',
  GET_BANK_COMPARE_PRODUCT_AREAS: "bank/product_area",
  GET_BANK_COUNTRY_OVERVIEW_DATA: "bank/dashboard/sub_menus/countries",
  GET_BANK_COUNTRY_OVERVIEW_QUESTIONS_DATA: "bank/questions",
  GET_BANK_DETAILS: "bank/details",
  UPDATE_BANK_DETAILS: "bank/update",
  GET_SUB_MENUS_DATA: "/bank/dashboard/sub_menus",
  BANK_INITIAL_DATA: 'bank/user_info',
  GET_USERS_DATA: 'bank/team',
}

const BANK_PROFILE_API = {
  UPDATE_PROFILE: '/bank/user/update',
  EDIT_FOLLOWS: 'bank/user/follows/edit',
  GET_BANK_USER: 'bank/user'
}

const CUSTOMERS_API = {
  GET_LIST: 'bank/customers',
  GET_DETAILS: 'bank/customer',
  INVITE_CUSTOMER: 'bank/customers/invite',
  CUSTOMER_INVITATIONS: 'bank/customers/invites',
  CUSTOMER_INVITE_UPDATE: 'bank/customers/invite/update',
  CUSTOMER_USERS: 'bank/customer/users',
}

const SURVEY_API = {
  GET_SINGLE_QUESTION: 'bank/question',
  GET_FEEDBACKS_LIST: 'bank/intents',
  GET_FEEDBACKS_DETAILS: 'bank/survey/stats',
  SAVE_DRAFT: 'surveys/create_draft',
  QUESTIONS_TOGGLE: 'surveys/questions/toggle',
  MATCHED_BUSINESSES: 'surveys/matched_businesses',
  ADD_PARTICIPANTS: 'surveys/add_participants',
  SURVEYS_SUBMIT: 'surveys/submit',
  SURVEYS_ADD_CUSTOMER: 'surveys/add_customers_by_email',
  GET_BUSINESS_DETAILS: "business/details",
  BUSINESS_ADD_BY_EMAIL: "business/add_user_by_email",
}

const API_TEAMS = {
  INVITE_USER: 'bank/teams/invite',
  TEAME_USER_UPDATE: 'bank/team/update'
}

const REQUESTS_API = {
  GET_REQUESTS: 'bank/intents',
  GET_REQUEST_BY_ID: 'bank/intent'
}

const IDEAS_API ={
  GET_IDEAS: 'bank/intents',
  GET_IDEA_BY_ID: 'bank/intent'
}

const SIGN_UP = {
  POST_NEW_PASSWORD: 'bank/password/change',
  POST_USER_UPDATE: 'bank/user/update',
  POST_USER_SETUP: 'bank/user/setup',
}

const NOTIFICATIONS_API = {
  GET_USER_SETTINGS: '/settings/user/get',
  UPDATE_USER_SETTINGS: 'settings/user/update',
  GET_BANK_SETTINGS: 'settings/bank/get',
  UPDATE_BANK_SETTINGS: 'settings/bank/update',
}

const BANK_FILTERS = {
  POST_FETCH_BANK_FILTERS: 'bank/filters/fetch',
}

const PERMISSION_API = {
  DELETE_PERMISSION_GROUP: 'permissions/group/delete',
  ADD_PERMISSION_GROUP: 'permissions/group/create',
  EDIT_PERMISSION_GROUP: 'permissions/group/edit',
  GET_PERMISSION_GROUP: 'permissions/group',
  GET_PERMISSION_GROUPS_LIST: 'permissions/groups',
}

export {
  BANK_API,
  SURVEY_API,
  CUSTOMERS_API,
  API_TEAMS,
  REQUESTS_API,
  IDEAS_API,
  SIGN_UP,
  NOTIFICATIONS_API,
  BANK_PROFILE_API,
  BANK_FILTERS,
  PERMISSION_API
}
