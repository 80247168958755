import { Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import NotificationService from "@/buying-teams/services/NotificationService";
import { API_TEAMS, BANK_API } from "@/router/BankApi";
import { UserModelMin } from "@/store/models/shared/UserModelMin";

@Module
export default class BankTeamModule extends VuexModule {

  @Action
  async inviteUserForBank(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_TEAMS.INVITE_USER, payload)
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(err => {
          console.log("err = ", err);
          NotificationService.swalError(err);
          reject();
        });
    });
  }

  @Action
  async updateTeamUser(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_TEAMS.TEAME_USER_UPDATE, payload)
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(err => {
          console.log("err = ", err);
          NotificationService.swalError(err);
          reject();
        });
    });
  }

  @Action
  async getUsersList() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      // TODO change this API
      ApiService.get(BANK_API.GET_USERS_DATA)
        .then(res => {
          const data = res.data.data ?? [];
          resolve(data.map(u => new UserModelMin(u)));
        })
        .catch(err => {
          console.log("err = ", err);
          NotificationService.swalError(err);
          reject();
        });
    });
  }
}
