import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { NOTIFICATIONS_API } from "@/router/BusinessApi";
import NotificationService from "@/buying-teams/services/NotificationService";
import store from "@/store";


@Module
export default class NotificationsModule extends VuexModule {
  businessSettingsLoading: boolean = true;
  userSettingsLoading: boolean = true;

  get getBusinessSettingsLoading() {
    return this.businessSettingsLoading
  }

  get getUserSettingsLoading() {
    return this.userSettingsLoading
  }

  @Mutation
  SET_BUSINESS_SETTINGS_LOADING(value) {
    this.businessSettingsLoading = value
  }

  @Mutation
  SET_USER_SETTINGS_LOADING(value) {
    this.userSettingsLoading = value
  }

  @Action
  async fetchUserSettings() {
    this.context.commit('SET_USER_SETTINGS_LOADING', true);

    let user = store.getters.currentUser;
    let business = store.getters.business;
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
      ApiService.get(NOTIFICATIONS_API.GET_USER_SETTINGS)
        .then(res => {
          user.updateSettings(res.data);
          resolve(res.status === 200);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        })
        .finally(() => {
          this.context.commit('SET_USER_SETTINGS_LOADING', false);
        })
    })
  }

  @Action
  async getBusinessSettings() {
    this.context.commit('SET_BUSINESS_SETTINGS_LOADING', true);

    return new Promise((resolve, reject) => {
      ApiService.get(NOTIFICATIONS_API.GET_BUSINESS_SETTINGS)
        .then(res => {
          this.context.getters.business.updateSettings(res.data)
          resolve(res);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        })
        .finally(() => {
          this.context.commit('SET_BUSINESS_SETTINGS_LOADING', false);
        })
    })
  }

  @Action
  async updateBusinessSettings(payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(NOTIFICATIONS_API.UPDATE_BUSINESS_SETTINGS, payload)
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  updateUserSettings(payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(NOTIFICATIONS_API.UPDATE_USER_SETTINGS, payload)
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        });
    })
  }
}
