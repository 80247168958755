import { BaseModel } from "@/store/models/base/BaseModel";
import { BankModelMin } from "@/store/models/bank/BankModelMin";
import { Constants } from "@/core/config/constants";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import { BusinessPermissionGroup } from "@/store/models/business/permissions/BusinessPermissionGroup";

export class BusinessTeamUserModel extends BaseModel {
    business_id: number;
    country: string;
    email: string;
    first_name: string;
    last_name: string;
    icon_path: string;
    role: string;
    is_admin: boolean;
    job_title: string;
    product_areas: string[];
    business_banks: BankModelMin[];
    is_blocked: boolean;
    feedback: object;
    stats: BusinessTeamUserStatsModel;
    product_area_data: object;
    visible_product_areas: string[] = [];
    permissions: BusinessPermissionGroup;

    constructor(obj: any) {
        super(obj);

        this.business_id = obj.business_id;
        this.country = obj.country;
        this.email = obj.email;
        this.first_name = obj.first_name;
        this.last_name = obj.last_name;
        this.job_title = obj.job_title;
        this.icon_path = obj.icon_path || Constants.USER_TMP_LOGO;
        this.role = obj.is_admin ? UserRoleEnum.ADMIN : UserRoleEnum.USER;
        this.is_admin = obj.is_admin;
        this.is_blocked = obj.is_blocked;
        this.feedback = new BusinessTeamUserFeedbackModel(obj.feedback);
        this.product_areas = obj.product_areas || [];
        this.business_banks = obj.business_banks ? obj.business_banks.map(b => new BankModelMin(b)) : [];
        this.stats = new BusinessTeamUserStatsModel(obj.stats);
        this.product_area_data = obj.stats.product_area_data;
        this.permissions = new BusinessPermissionGroup(obj.permissions);
    }

    getFullName() {
        return this.first_name + ' ' + this.last_name;
    }

    setProductAreas(product_areas: string[]) {
        this.visible_product_areas = [ ...product_areas ];
    }

    get getPermissionGroupName() {
        return this.permissions && this.permissions.isGroup ? this.permissions.name : '';
    }
}

class BusinessTeamUserFeedbackModel {
    answers: number;
    ideas: number;
    requests: number;
    sessions: number;

    constructor(obj: any) {
        this.answers = obj.answers || 0;
        this.ideas = obj.ideas || 0;
        this.requests = obj.requests || 0;
        this.sessions = obj.sessions || 0;
    }
}

class BusinessTeamUserStatsModel {
    score: number;
    replies: number;
    intents_count: number;

    constructor(obj: any) {
        this.score = Number(obj.score);
        this.replies = Number(obj.replies);
        this.intents_count = Number(obj.intents_count);
    }
}

export class BusinessSettingsPermissionGroupsModel extends BaseModel {
    countries: number;
    banks: number;
    name: string;
    productAreas: number;
    users: number;

    constructor(data: any) {
        super(data);

        this.countries = data.countries;
        this.banks = data.banks;
        this.name = data.name;
        this.productAreas = data.product_areas;
        this.users = data.users;
    }
}
