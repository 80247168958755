export class UserSettingsModel {
  sharing: any = {
    pi_share_fullname: {enabled: 0},
    pi_share_jt: {enabled: 0},
    pi_share_pp: {enabled: 0},
    pi_share_cnt: {enabled: 0},
  };
  notifications: any = {
    new_market_report: {email: 0, push: 0},
    new_feedback_session: {email: 0, push: 0},
    new_request: {email: 0, push: 0},
    new_idea: {email: 0, push: 0},
    new_comment: {email: 0, push: 0}
  }

  constructor(userSettingsData: any) {
    this.notifications = this.defineUserNotifications(userSettingsData.notifications);
    this.sharing = this.defineUserSharingPolicy(userSettingsData.sharing);
  }

  defineUserNotifications(userNotifications: any) {
    let notifications = {};

    for (let notificationKey in this.notifications) {
      if (userNotifications[notificationKey]) {
        notifications[notificationKey] = userNotifications[notificationKey];
      }
    }

    return notifications;
  }

  defineUserSharingPolicy(userSharing: any) {
    let sharing = {};

    for (let key in this.sharing) {
      if (userSharing[key]) {
        sharing[key] = { enabled: userSharing[key].enabled };
      }
    }

    return sharing;
  }
}
