import { BaseModel } from "@/store/models/base/BaseModel";
import { BusinessBankModel } from "@/store/models/BusinessBankModel";
import moment from "moment";
import { translateGlobal } from "@/core/helpers/GlobalHelper";
import { SurveyStatusesEnum } from "@/store/enums/SurveyStatusesEnum";
import {BankModelMin} from "@/store/models/bank/BankModelMin";
import {UserModel} from "@/store/models/UserModel";

export const SurveyStatuses = {
  UPCOMING: 'Upcoming',
  LIVE: 'Live',
  EXPIRING_SOON: 'Expiring soon',
  EXPIRED: 'Expired',
  COMPLETED: 'Completed'
}

export class SurveyModel extends BaseModel {
  title: String;
  description: String;
  product_areas: String[];
  session_start: Date;
  session_end: Date;
  is_completed: Boolean;
  is_internal: Boolean;
  phases: String[];
  answered_questions: Number;
  total_questions: Number;
  banks_data: BankModelMin[] | [];
  is_live: Boolean;
  status: Object;
  countries: String[];
  is_bank_survey: boolean = false;
  creator: UserModel | null;

  constructor(obj: any) {
    super(obj);
    this.title = obj['title'];
    this.description = obj['description'];
    this.product_areas = obj['product_areas'];
    this.session_start = new Date(obj['start_time'] * 1000);
    this.session_end = new Date(obj['end_time'] * 1000);
    this.is_completed = obj['is_completed']
    this.is_internal = obj['is_internal']
    this.phases = obj['phases'];
    this.answered_questions = obj['answered_questions'];
    this.total_questions = obj['total_questions'] || 0;
    this.is_live = moment() < moment(this.session_end);
    this.status = this.getStatus(obj['intent_status']);
    this.countries = obj['countries'];
    this.is_bank_survey = obj['is_bank_survey'];
    this.creator = obj['creator'] ?  new UserModel(obj['creator']) : null;
    this.banks_data = obj['banks_data'] ? obj['banks_data'].map((bank: any) => new BankModelMin(bank)) : [];
  }

  getStatus(intent_status: string) {
    switch (intent_status) {
      case SurveyStatusesEnum.LIVE:
        return {statusName: translateGlobal(SurveyStatuses.LIVE), statusClass: 'live'};
      case SurveyStatusesEnum.EXPIRED:
        return {statusName: translateGlobal(SurveyStatuses.EXPIRED), statusClass: 'secondary'};
      case SurveyStatusesEnum.EXPIRES_SOON:
        return {statusName: translateGlobal(SurveyStatuses.EXPIRING_SOON), statusClass: 'danger'};
      case SurveyStatusesEnum.UPCOMING:
        return {statusName: translateGlobal(SurveyStatuses.UPCOMING), statusClass: 'primary'};
      case SurveyStatusesEnum.COMPLETED:
        return {statusName: translateGlobal(SurveyStatuses.COMPLETED), statusClass: 'success'};
      default:
        return {statusName: '', statusClass: ''};
    }
  }
}
