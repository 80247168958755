import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
export class SentryService {
  public static init(app, router): void {
    Sentry.init({
      app,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      environment: process.env.VUE_APP_SENTRY_LOG_ENVIRONMENT,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [process.env.VUE_APP_APPLICATION_URL, /^\//],
        }),
      ],
      tracesSampleRate: 1.0,
      normalizeDepth: 5,
      normalizeMaxBreadth: 10000
    });
  }

  public static captureException(errorResponse: any) {
    let errResp = new SentryErrorModel(errorResponse);
    Sentry.setExtra('info', errResp);
  }
}
export class SentryErrorModel {
  name: string;
  responseStatus: number;
  responseData: any;
  requestPayload: any;
  responseObject: any;

  constructor(obj: any) {
    this.name = `Error_${new Date().getTime()}`
    this.responseObject = obj;
    this.responseStatus = obj.status;
    this.responseData = obj.data;
    this.requestPayload = obj.config.data ? JSON.parse(obj.config.data) : 'No payload detected';
  }
}
