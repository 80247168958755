import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { DataService } from "@/core/services/DataService";
import { BankModel } from "@/store/models/bank/BankModel";
import ApiService from "@/core/services/ApiService";
import NotificationService from "@/buying-teams/services/NotificationService";
import { BANK_API, CUSTOMERS_API } from "@/router/BankApi";
import { BankOverviewDashboardFilterModel } from "@/store/models/filter/bank/BankOverviewDashboardFilterModel";
import { BankFilterService } from "@/core/services/BankFilterService";
import {diagLog, getOrdered} from "@/core/helpers/GlobalHelper";
import CircleChartModel from "@/store/models/CircleChartModel";
import {BankCustomerModel} from "@/store/models/bank/BankCustomerModel";
import {BankProductAreaOverviewFilterModel} from "@/store/models/filter/bank/BankProductAreaOverviewFilterModel";
import {CustomerInvitationsContainer} from "@/store/models/bank/CustomerInvitationsContainer";
import {redirectToRouteWithName} from "@/buying-teams/helpers/UrlHelper";
import { BankFiltersDataModel } from "@/store/models/filter/bank/BankFiltersDataModel";
import {BankCustomersFilterModel} from "@/store/models/filter/bank/BankCustomersFilterModel";
import {BankSingleCustomersFilterModel} from "@/store/models/filter/bank/BankSingleCustomersFilterModel";
import {DashboardProductAreaCountriesFilterModel} from "@/store/models/filter/bank/DashboardProductAreaCountriesFilterModel";
import {BankPageTypes} from "@/store/enums/bank/BankPageTypes";
import { BankCountryOverviewFilterModel } from "@/store/models/filter/bank/BankCountryOverviewFilterModel";
import { BUSINESS_API } from "@/router/BusinessApi";
import { Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";

@Module
export default class BankModule extends VuexModule {
  dashboardData = {} as any;
  bankData = DataService.getBankData();
  bankOverviewDashboardFilterData = {} as BankOverviewDashboardFilterModel;
  productAreaOverviewFilterData = {} as BankProductAreaOverviewFilterModel;
  countryOverviewFilterData = {} as BankCountryOverviewFilterModel;
  countriesDashboardFilterData = {} as DashboardProductAreaCountriesFilterModel;
  productAreasDashboardFilterData = {} as DashboardProductAreaCountriesFilterModel;
  bankFiltersData = {} as BankFiltersDataModel;
  bankCustomers = [] as BankCustomerModel[];
  isFilterLoaded: boolean = true;
  bankCustomersFilterData = {} as BankCustomersFilterModel;
  bankSingleCustomersFilterData = {} as BankSingleCustomersFilterModel;

  get bank() {
    return this.bankData;
  }

  get bankDashboardData() {
    return this.dashboardData;
  }

  get getBankCustomers() {
    return this.bankCustomers
  }

  get bankOverviewDashboardFilter() {
    return this.bankOverviewDashboardFilterData
  }

  get productAreaOverviewDashboardFilter() {
    return this.productAreaOverviewFilterData
  }

  get countryOverviewDashboardFilter() {
    return this.countryOverviewFilterData;
  }

  get countriesDashboardFilter() {
    return this.countriesDashboardFilterData
  }

  get productAreasDashboardFilter() {
    return this.productAreasDashboardFilterData
  }

  get getIsFilterLoaded() {
    return this.isFilterLoaded
  }

  get getBankFiltersData() {
    return this.bankFiltersData;
  }

  get getBankCustomersFilterData() {
      return this.bankCustomersFilterData;
  }

  get getBankSingleCustomersFilterData() {
      return this.bankSingleCustomersFilterData;
  }

  @Mutation
  SAVE_BANK_FILTERS_DATA(filtersData) {
    this.bankFiltersData = new BankFiltersDataModel(filtersData);
    if (filtersData.needSave) {
      BankFilterService.storeBankFiltersData(this.bankFiltersData);
    }
  }

  @Mutation
  SAVE_BANK_OVERVIEW_DASHBOARD_DATA(payload) {
    let productAreasStatsTop: any = [];
    let productAreasStatsLow: any = [];
    let dashboardRequests: any = {};
    let dashboardIdeas: any = [];

    let productAreasTotalStats: CircleChartModel = new CircleChartModel({ score: 0, importance: 0 });
    if (payload.data.product_areas_stats) {
      let productAreasStats = payload.data.product_areas_stats.map(val => new CircleChartModel(val));
      productAreasStatsTop = getOrdered([...productAreasStats], 'score');
      productAreasStatsLow = [...productAreasStatsTop].reverse();
    }
    if (payload.data.total_stats) {
      productAreasTotalStats = new CircleChartModel(payload.data.total_stats);
    }
    if (payload.requests) {
      dashboardRequests = payload.requests
    }
    if (payload.ideas) {
      dashboardIdeas = payload.ideas
    }
    this.dashboardData = {
      ...payload.data,
      productAreasTotalStats,
      productAreasStatsTop,
      productAreasStatsLow,
      dashboardRequests,
      dashboardIdeas
    };
    diagLog('SAVE_BANK_OVERVIEW_DASHBOARD_DATA = ', this.dashboardData);
  }

  @Mutation
  SAVE_FILTER_LOADED(value) {
    this.isFilterLoaded = value;
  }

  @Mutation
  SAVE_BANK_INFO(payload) {
    this.bankData = new BankModel(payload);
    diagLog('', this.bankData);
    DataService.storeBankData(this.bankData);
  }

  @Mutation
  SAVE_BANK_OVERVIEW_DASHBOARD_FILTER(filterData) {
    this.bankOverviewDashboardFilterData = new BankOverviewDashboardFilterModel(filterData);
    BankFilterService.storeBankOverviewDashboardFilter(this.bankOverviewDashboardFilterData);
  }

  @Mutation
  SAVE_PRODUCT_AREA_OVERVIEW_FILTER(filterData) {
    this.productAreaOverviewFilterData = new BankProductAreaOverviewFilterModel(filterData);
    BankFilterService.storeBankProductAreaOverviewFilter(this.productAreaOverviewFilterData);
  }

  @Mutation
  SAVE_COUNTRY_OVERVIEW_FILTER(filterData) {
    this.countryOverviewFilterData = new BankCountryOverviewFilterModel(filterData);
    BankFilterService.storeBankCountryOverviewFilter(this.countryOverviewFilterData);
  }

  @Mutation
  SAVE_COUNTRIES_DASHBOARD_FILTER(filterData) {
    this.countriesDashboardFilterData = new DashboardProductAreaCountriesFilterModel(filterData);
    BankFilterService.storeCountriesDashboardFilter(this.countriesDashboardFilterData);
  }

  @Mutation
  SAVE_PRODUCT_AREAS_DASHBOARD_FILTER(filterData) {
    this.productAreasDashboardFilterData = new DashboardProductAreaCountriesFilterModel(filterData);
    BankFilterService.storeProductAreasDashboardFilter(this.productAreasDashboardFilterData);
  }

  @Mutation
  SAVE_CUSTOMERS_DATA(payload) {
    this.bankCustomers = payload.map(cs => new BankCustomerModel(cs))
  }

    @Mutation
    SAVE_BANK_CUSTOMERS_FILTER(filterData) {
        this.bankCustomersFilterData = new BankCustomersFilterModel(filterData);
        BankFilterService.storeBankCustomersFilter(this.bankCustomersFilterData);
    }

    @Mutation
    SAVE_BANK_SINGLE_CUSTOMERS_FILTER(filterData) {
        this.bankSingleCustomersFilterData = new BankSingleCustomersFilterModel(filterData);
        BankFilterService.storeBankSingleCustomersFilter(this.bankSingleCustomersFilterData);
    }

    @Action
    async fetchBankInitialData() {
      this.context.commit('SET_DASHBOARD_LOADING', false);
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(BANK_API.BANK_INITIAL_DATA)
            .then(res => {
              diagLog("SAVE_BANK_INITIAL_DATA = ", res);
              this.context.commit(Mutations.SET_AUTH, res.data.business);
              this.context.commit('SET_BANK_PRODUCT_AREAS', res.data?.bank_product_areas);
              this.context.commit('SET_BANK_COUNTRIES', res.data?.bank_countries);
              this.context.commit('SET_BANK_CUSTOMERS', res.data?.customers);
              resolve(res.data);
            })
            .catch(error => {
              NotificationService.swalError(error);
              reject();
            }).finally(()=>{
          this.context.commit('SET_DASHBOARD_LOADING', false);
        })
      })

    }

  @Action
  async fetchBankOverviewDashboardData(filters) {
    ApiService.setHeader();
    const params = {
      filter: filters ? new BankOverviewDashboardFilterModel(filters) : filters,
    };

    return new Promise((resolve, reject) => {
      ApiService.post(BANK_API.GET_BANK_OVERVIEW_DASHBOARD_DATA, params)
        .then(res => {
          this.context.commit("SAVE_BANK_FILTERS_DATA", params.filter);

          diagLog("GET_BANK_OVERVIEW_DASHBOARD_DATA = ", res);
          this.context.commit('SAVE_BANK_OVERVIEW_DASHBOARD_DATA', res.data)
          this.context.commit("SAVE_BANK_OVERVIEW_DASHBOARD_FILTER", params.filter);
          this.bankData?.updateProductAreas(res.data.data)
          let customers = res.data.data.customer_stats ?
            res.data.data.customer_stats.map(cs =>{
              return {id: cs.id, business_name: cs.business_name, icon_path: cs.icon_path}
            }) : [];
          this.bankData?.adCustomers(customers);
          resolve(res.data);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        }).finally(()=>{
        // this.context.commit('SET_DASHBOARD_LOADING', false);
      })
    });
  }

  @Action
  async fetchBankCompareProductAreas({productArea, isDetailed = false, customerId = null, filter = null}) {
    const params: any = {
      product_area: productArea,
      is_detailed: isDetailed
    }
    if (customerId) {
      params.customer_id = customerId
    }
    if (filter) {
      params.filter = filter;
    }
    return new Promise((resolve, reject) => {
      ApiService.post(BANK_API.GET_BANK_COMPARE_PRODUCT_AREAS, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    });
  }

  @Action
  fetchProductAreaOverview({product_area = '', customer_id = null, filter = null}) {
    ApiService.setHeader();

    let filters: any = {...new BankProductAreaOverviewFilterModel(filter)};

    if (customer_id) {
      filters.customers = [customer_id];
    }
    const params = {
      product_area,
      is_detailed: true,
      filter: customer_id ? {...filters, customers: [customer_id]} : filters,
    }

    return new Promise((resolve, reject) => {
      ApiService.post(BANK_API.GET_BANK_COMPARE_PRODUCT_AREAS, params)
        .then(res => {
          this.context.commit('SAVE_PRODUCT_AREA_OVERVIEW_FILTER', params.filter);
          resolve(res.data);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    });
  }

  @Action
  fetchCountryOverview({country, filter = null}) {
    ApiService.setHeader();
    const params = { country, filter };

    // @ts-ignore
    delete params.filter.countries;

    return new Promise((resolve, reject) => {
      ApiService.post(BANK_API.GET_BANK_COUNTRY_OVERVIEW_DATA, params)
        .then(res => {
          this.context.commit('SAVE_COUNTRY_OVERVIEW_FILTER', params.filter);
          resolve(res.data);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    });
  }

  @Action
  fetchCountryOverviewQuestions(payload) {
    ApiService.setHeader();

    return new Promise((resolve, reject) => {
      ApiService.post(BANK_API.GET_BANK_COUNTRY_OVERVIEW_QUESTIONS_DATA, payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    });
  }

  @Action
  async fetchBankCustomers(filter) {
    return new Promise((resolve, reject) => {
      ApiService.post(CUSTOMERS_API.GET_LIST, { filter })
        .then(res => {
          this.context.commit('SAVE_BANK_CUSTOMERS_FILTER', filter);
          this.context.commit('SAVE_CUSTOMERS_DATA', res.data);
          resolve(res);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    });
  }

  @Action
  async fetchBankCustomerDetails({ business_id, filter }) {
    return new Promise((resolve, reject) => {
      ApiService.post(CUSTOMERS_API.GET_DETAILS, {business_id, filter})
          .then(res => {
            if (res && res.status === 200 && res.data.err) {
              NotificationService.swalError(res.data.err);
              redirectToRouteWithName('bank-dashboard');
              reject();
            } else {
              this.context.commit('SAVE_BANK_SINGLE_CUSTOMERS_FILTER', { ...filter, business_id });
              resolve(res);
            }
          })
          .catch(error => {
            NotificationService.swalError(error);
            reject();
          })
    });
  }

  @Action
  async inviteBankCustomer(payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(CUSTOMERS_API.INVITE_CUSTOMER, payload)
        .then(res => {
          diagLog('RES => ', res);
          resolve(res);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    })
  }

  @Action
  async fetchBankInvitations() {
    return new Promise((resolve, reject) => {
      ApiService.query(CUSTOMERS_API.CUSTOMER_INVITATIONS, {})
        .then(res => {
          resolve(new CustomerInvitationsContainer(res.data));
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    })
  }

  @Action
  async updateBankInvitations(payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(CUSTOMERS_API.CUSTOMER_INVITE_UPDATE, payload)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    })
  }

  @Action
  async fetchBankInvitationsUsers(payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(CUSTOMERS_API.CUSTOMER_USERS, payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    })
  }

  @Action
  async fetchBankDetails() {
    return new Promise((resolve, reject) => {
      ApiService.query(BANK_API.GET_BANK_DETAILS, {})
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    })
  }

  @Action
  async updateBankDetails(data) {
    return new Promise((resolve, reject) => {
      ApiService.post(BANK_API.UPDATE_BANK_DETAILS, data)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    })
  }

  @Action
  fetchBankData() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(BANK_API.GET_BANK_DETAILS)
        .then(res => {
          resolve({
            countries: Object.keys(res.data.countries),
            productAreas: Object.keys(res.data.product_areas)
          })
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  fetchBankDashboardDataLevelTwo({ filter, type }) {
    return new Promise((resolve, reject) => {
      ApiService.post(BANK_API.GET_SUB_MENUS_DATA, {filter, type})
        .then(res => {
          switch (type) {
            case BankPageTypes.COUNTRY:
              this.context.commit("SAVE_COUNTRIES_DASHBOARD_FILTER", filter);
              break;
            case BankPageTypes.PRODUCT_AREA:
              this.context.commit("SAVE_PRODUCT_AREAS_DASHBOARD_FILTER", filter);
              break;
          }
          resolve(res.data);
        }).catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    })
  }
}
