import { numberDecimalsFormat } from "@/core/helpers/GlobalHelper";

export default class CircleChartModel {
    score: string;
    importance: string;
    changePercentage: string | null;
    titleBottom?: string | null;
    gap?: number | null;
    color: string;
    isEmpty = false as boolean;

    constructor(obj: any) {
        this.isEmpty = !obj || Object.keys(obj).length === 0 || !obj.score || !obj.importance;
        this.score = this.getRounded(obj["score"]);
        this.importance = this.getRounded(obj["importance"]);
        this.changePercentage = obj["change_percentage"] ? this.getRounded(obj["change_percentage"]) : null;
        this.titleBottom = obj["product_area"] || obj["bank_name"] || null;
        this.gap = obj["gap"] ? obj["gap"] : numberDecimalsFormat(+this.score - +this.importance);
        this.color = this.getColor();
    }

    getRounded(val: number, count: number = 2): string {
        if (!val) {
            return '0.00';
        }
        return (Math.round(val * 100) / 100).toFixed(count);
    }

    getColor(): string {
        switch (true) {
            case Number(this.score) <= 2.49:
                return "#E22D21";
            case Number(this.score) >= 2.50 && Number(this.score) <= 3.49:
                return "#FAD03B";
            case Number(this.score) >= 3.50:
                return "#2BC490";
            default:
                return "#E22D21";
        }
    }
}
