import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { PROFILE_API } from "@/router/BusinessApi";
import NotificationService from "@/buying-teams/services/NotificationService";


@Module
export default class UserProfileModule extends VuexModule {
  authUserProfileInfoData = {} as any;

  get authUserProfileInfo() {
    return this.authUserProfileInfoData;
  }

  @Mutation
  SET_AUTH_USER_PROFILE_INFO(payload) {
    this.authUserProfileInfoData = payload;
  }

  @Action
  async updateProfileDetails(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(PROFILE_API.UPDATE_PROFILE, data)
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async deactivateAccount(email) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(PROFILE_API.DEACTIVATE_ACCOUNT, { email: email })
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async getAuthUserProfileInfo(user_id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(PROFILE_API.PROFILE_STATS, user_id ? { user_id } : {})
        .then(res => {
          if (!user_id) {
            this.context.commit('SET_AUTH_USER_PROFILE_INFO', res.data);
          }
          resolve(res.data);
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }
}
