import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { API_AUTH } from "@/router/ApiRoutes";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import NotificationService from "@/buying-teams/services/NotificationService";
import { UserModel } from "@/store/models/UserModel";
import store from "@/store";
import { RegisterStatusEnum } from "@/store/enums/RegisterStatusEnum";
import { diagLog, translateGlobal } from "@/core/helpers/GlobalHelper";
import { BusinessFilterService } from "@/core/services/BusinessFilterService";
import { DataService } from "@/core/services/DataService";
import { BankFilterService } from "@/core/services/BankFilterService";
import { DEFAULT_PLATFORM_TYPE } from "@/core/config/constants";
import InitialDataResolver from "@/core/services/InitialDataResolver";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";

@Module
export default class AuthModule extends VuexModule {
  authControlData = {
    email: "",
    password: ""
  };
  errors = {};
  user = DataService.getAuthUserData();
  isAuthenticated = !!JwtService.getToken();
  registerSetupData = {};

  get registerSetupInformation() {
    return this.registerSetupData;
  }

  get authControlConfigs() {
    return this.authControlData;
  }

  @Mutation
  setRegisterSetupData(setupData) {
    this.registerSetupData = setupData;
  }

  @Mutation
  setAuthConfigEmail(email) {
    this.authControlData.email = email;
  }

  @Mutation
  setAuthConfigPassword(password) {
    this.authControlData.password = password;
  }

  /**
   * Get current user object
   * @returns User
   */
  get currentUser() {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  STORE_ACCESS_TOKEN(token) {
    JwtService.saveToken(token);
  }

  @Mutation
  STORE_REFRESH_TOKEN(token) {
    JwtService.saveRefreshToken(token);
  }

  @Mutation
  [Mutations.SET_AUTH]() {
    this.isAuthenticated = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_USER](userData) {
    this.user = new UserModel(userData);
    DataService.storeAuthUserData(this.user);
  }

  // @Mutation
  // [Mutations.SET_PASSWORD](password) {
  //   this.user.password = password;
  // }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = null;
    this.errors = [];
    JwtService.destroyToken();
    JwtService.destroyRefreshToken();
    JwtService.removeUserDataMin();
    BusinessFilterService.removeAllFilters();
    BankFilterService.removeAllFilters();
    DataService.removeAllData();
  }


  @Action
  async [Actions.LOGOUT]() {
    if (JwtService.getImpersonateToken()) {
      await this.context.dispatch(Actions.HANDLE_IMPERSONATION_EXPIRE);
    } else {
      this.context.commit(Mutations.SET_PLATFORM_CONFIGS, DEFAULT_PLATFORM_TYPE);
      ApiService.setHeader();
      ApiService.post("/auth/logout", {})
          .finally(async () => {
            await redirectToRouteWithName("sign-in");
            this.context.commit(Mutations.PURGE_AUTH);
          });
    }
  }

  @Action
  async [Actions.HANDLE_IMPERSONATION_EXPIRE]() {
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    JwtService.destroyImpersonateToken();
    ApiService.deleteImpersonateHeader();

    await InitialDataResolver.verifyAuthUser(PlatformTypeEnum.SUPER_ADMIN);
    window.location.href = `${window.location.origin}/super-admin/user-management`;
  }

  @Action
  async [Actions.VERIFY_AUTH_USER]() {
    return new Promise((resolve, reject) => {
      ApiService.get(API_AUTH.GET_AUTH_USER_DATA)
          .then(res => {
            diagLog('VERIFY_AUTH_USER RES => ', res);
            resolve(res.data);
          })
          .catch((error) => {
            NotificationService.swalError(error);
            reject()
          })
    })
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  async [Actions.VERIFY_AUTH](payload) {
    if (!JwtService.getToken()) {
      await redirectToRouteWithName("sign-in");
      this.context.commit(Mutations.PURGE_AUTH);
    }
    // if (JwtService.getToken()) {
    //   ApiService.setHeader();
    //   ApiService.post("verify_token", payload)
    //     .then(({ data }) => {
    //       this.context.commit(Mutations.SET_AUTH, data);
    //     })
    //     .catch(({ response }) => {
    //       this.context.commit(Mutations.SET_ERROR, response.data.errors);
    //       this.context.commit(Mutations.PURGE_AUTH);
    //     });
    // } else {
    //   this.context.commit(Mutations.PURGE_AUTH);
    //   redirectToRouteWithName('sign-in')
    // }
  }

  //

  @Action
  [Actions.CHECK_EMAIL](payload) {
    return ApiService.post(API_AUTH.CHECK_EMAIL, payload);
  }

  @Action
  [Actions.CHANGE_EMAIL](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(API_AUTH.CHANGE_EMAIL, payload)
        .then(res => {
          if (res.status === 200) {
            this.context.commit('setAuthConfigEmail', payload.email);
            resolve(res);
          } else {
            reject()
          }
        })
        .catch((error) => {
          NotificationService.swalError(error);
          reject()
        })
    })
  }

  @Action
  [Actions.RECOVER_PASSWORD](payload) {
    return ApiService.post(API_AUTH.VERIFY_EMAIL, {
      ...payload,
      isPasswordReset: true
    });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(API_AUTH.RESET_PASSWORD, payload)
        .then(res => {
          if (res.status === 200) {
            redirectToRouteWithName("sign-in");
          }
          resolve(res)
        })
        .catch((error) => {
          NotificationService.swalError(error);
          this.context.commit(Mutations.SET_ERROR, error);
          reject()
        })
    })
  }

  @Action
  [Actions.CHANGE_PASSWORD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(API_AUTH.CHANGE_PASSWORD, payload)
        .then(res => {
          resolve(res)
        })
        .catch((error) => {
          NotificationService.swalError(error);
          this.context.commit(Mutations.SET_ERROR, error);
          reject()
        })
    })
  }

  @Action
  verifyEmail(data) {
    return ApiService.post(API_AUTH.VERIFY_EMAIL, data);
  }

  @Action
  registerUser(data) {
    return ApiService.post(API_AUTH.REGISTER_USER, data);
  }

  @Action
  deleteProfile(data) {
    return ApiService.post(API_AUTH.DELETE_USER, data);
  }

  @Action
  refreshToken(refreshData) {
    return ApiService.post(API_AUTH.REFRESH_TOKEN, refreshData);
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post(API_AUTH.LOGIN, credentials)
        .then(response => {
          if (response.data.error) {
            resolve(response.data);
          } else if (response.data.data) {
            this.context.commit("STORE_ACCESS_TOKEN", response.data.data.token);
            this.context.commit("STORE_REFRESH_TOKEN", response.data.data.refresh_token);

            this.context.commit(Mutations.SET_AUTH, response.data.data.company);
            this.context.commit(Mutations.SET_USER, response.data.data.info);

            if (response.data.data.is_setup_finished) {
              // if user blocked
              if (response.data.data.info.is_blocked) {
                this.context.dispatch(Actions.LOGOUT).then(() => {
                  NotificationService.swalError(translateGlobal("Your Account Blocked"));
                  redirectToRouteWithName("sign-in");
                });
              }
              if (response.data.data.info.is_super_admin) {
                // TODO save some data;
              } else {
                if (response.data.data.info.is_bank_user) {
                  store.commit("SAVE_BANK_INFO", response.data.data.company);
                } else {
                  store.commit("SAVE_BUSINESS_INFO", response.data.data.company);
                }
              }

              window.location.href = '/dashboard'
            } else {
              switch (response.data.data.status) {
                case RegisterStatusEnum.CREATE_BUSINESS_USER:
                  store.commit("SAVE_REGISTRATION_DATA", response.data.data);

                  if (response.data.data.is_password_change_required) {
                    redirectToRouteWithName("change-password");
                  } else {
                    redirectToRouteWithName("business-register/personal-info");
                  }
                  break;
                case RegisterStatusEnum.CREATE_BUSINESS:
                  // TODO add registrationData
                  // TODO REDIRECT-> is_domain_exist ? 'personal-info' : 'company-info'
                  store.commit("SAVE_REGISTRATION_DATA", response.data.data);
                  redirectToRouteWithName("business-register/company-info");
                  break;
                case RegisterStatusEnum.CREATE_BANK_USER:
                  if (response.data.data.is_password_change_required && !response.data.data.is_setup_finished) {
                    redirectToRouteWithName("bank-register/personal-info");
                  } else {
                    NotificationService.swalError(translateGlobal("Something Went Wrong"));
                  }
                  break;

                case RegisterStatusEnum.CREATE_INV_BUSINESS_USER:
                  store.commit("SAVE_REGISTRATION_DATA", response.data.data);

                  redirectToRouteWithName("business-register/ext-personal-info");
                  break;
                case RegisterStatusEnum.CREATE_INV_BUSINESS:
                  store.commit("SAVE_REGISTRATION_DATA", response.data.data);

                  redirectToRouteWithName("business-register/ext-company-info");
                  break;

                default:
                  this.context.dispatch(Actions.LOGOUT).then(() => {
                    NotificationService.swalError(translateGlobal("Something Went Wrong"));
                  });
              }
            }
          }
        })
        .catch(error => {
          NotificationService.swalError(error);
          this.context.commit(Mutations.SET_ERROR, error);
          reject();
        });
    });
  }
}
