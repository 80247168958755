import { Action, Module, VuexModule } from "vuex-module-decorators";
import { CUSTOM_QUESTION } from "@/router/SharedApi";
import ApiService from "@/core/services/ApiService";
import NotificationService from "@/buying-teams/services/NotificationService";

@Module
export default class CustomQuestionsModule extends VuexModule {

    @Action
    addCustomQuestion(data) {
        ApiService.setHeader();
        return new Promise((resolve) => {
            ApiService.post(CUSTOM_QUESTION.CUSTOM_QUESTION_ADD, {
                questions: data
            })
                .then(res => {
                    resolve(res.data ? res.data?.addedQuestions : []);
                })
                .catch(() => {
                    resolve([]);
                })
        })
    }

    @Action
    getCustomQuestions(filter) {
        ApiService.setHeader();
        return new Promise((resolve) => {
            ApiService.post(CUSTOM_QUESTION.CUSTOM_QUESTION_GET, filter)
                .then(res => {
                    resolve(res.data);
                })
                .catch(() => {
                    resolve([]);
                })
        })
    }

    @Action
    addCustomQuestionToSurvey(data) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(CUSTOM_QUESTION.SURVEY_CUSTOM_QUESTION_ADD, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                })
        })
    }
}
