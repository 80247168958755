
export class BusinessSettingsModel {
  notifications: any = {
    new_users: {push: 0, email: 0},
    new_banks: {push: 0, email: 0},
    sys_updates: {push: 0, email: 0},
    newsletters: {push: 0, email: 0},
    gdpr: {push: 0, email: 0}
  };

  constructor(businessSettingsData: any) {
    this.notifications = this.defineBusinessNotifications(businessSettingsData.notifications);
  }

  defineBusinessNotifications(businessNotifications: any) {
    let notifications = {};

    for (let notificationKey in this.notifications) {
      if (businessNotifications[notificationKey]) {
        notifications[notificationKey] = businessNotifications[notificationKey];
      }
    }

    return notifications;
  }
}
