import { BaseModel } from "@/store/models/base/BaseModel";

export class CustomQuestionsAnalyticsBaseModel extends BaseModel {
    question: string;
    question_id: string;
    productArea: string;
    isCustom: boolean = true;

    constructor(data: any) {
        super(data);

        this.question_id = data.survey_question_id;
        this.question = data.text || '';
        this.productArea = data.product_area || '';
    }
}
