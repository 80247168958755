enum StaticDataEnum {
  PRODUCT_AREAS = 'product_areas',
  COUNTRIES = 'countries',
  INDUSTRIES = 'industries',
  EMPLOYEE_COUNTS = 'employee_counts',
  REVENUES = 'revenues',
  JOB_TITLES = 'job_titles',
  PHASES = 'phases',
  BANKS = 'banks',
}

export { StaticDataEnum };
