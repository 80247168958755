import moment from "moment";
import { translateGlobal } from "@/core/helpers/GlobalHelper";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import { InviteStatusEnum } from "@/store/models/bank/BankSettingsContainer";
import { BaseModel } from "@/store/models/base/BaseModel";

export class InvitedUserModel extends BaseModel {
  date: string;
  email: string;
  role: any;
  status: any;
  status_id: string;

  constructor(obj) {
    super(obj);

    this.date = moment(obj.date ? obj.date : new Date()).format('DD/MM/YYYY');
    this.email = obj.email;
    this.status_id = obj.status;
    this.role = this.setRole(obj.role);
    this.status = this.setStatus(obj.status);
  }

  setStatus(status) {
    switch (status) {
      case InviteStatusEnum.PENDING:
        return {
          text: translateGlobal('Pending'),
          className: 'invite-status-pending'
        }
      case InviteStatusEnum.BOUNCED:
        return {
          text: translateGlobal('Bounced'),
          className: 'invite-status-bounced'
        }
    }
  }

  setRole(role) {
    switch (role) {
      case UserRoleEnum.USER:
        return {
          text: translateGlobal('User'),
          key: UserRoleEnum.USER
        };
      case UserRoleEnum.ADMIN:
        return {
          text: translateGlobal('Admin'),
          key: UserRoleEnum.ADMIN
        }
    }
  }
}
