import { DateRangePickerInterface } from "@/store/interfaces/DateTimeInterface";

export class BaseBusinessFilterModel {
  bank_ids: number[];
  product_areas: string[];
  countries: string[];
  time_period: DateRangePickerInterface;

  constructor(obj: any) {
    this.bank_ids = obj['bank_ids'];
    this.product_areas = obj['product_areas'];
    this.countries = obj['countries'];
    this.time_period = obj['time_period'];
  }
}
