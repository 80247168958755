import { Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { COMMENTS_API } from "@/router/BusinessApi";
import NotificationService from "@/buying-teams/services/NotificationService";

@Module
export default class CommentsModule extends VuexModule {
  @Action
  async saveComment(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(COMMENTS_API.SAVE_COMMENT, data)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async updateComment(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(COMMENTS_API.UPDATE_COMMENT, data)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async deleteComment(id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(COMMENTS_API.DELETE_COMMENT, {id})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async getComments(id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(COMMENTS_API.GET_COMMENTS, {
        "intent_id": id,
        "page": 0
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }
}
