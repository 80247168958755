import {BaseModel} from "@/store/models/base/BaseModel";
import {translateGlobal} from "@/core/helpers/GlobalHelper";
import {SurveyStatuses} from "@/store/models/SurveyModel";
import {SurveyStatusesEnum} from "@/store/enums/SurveyStatusesEnum";
import {Constants} from "@/core/config/constants";

export class BankFeedbackModel extends BaseModel {
  bank_id: Number;
  countries: String[];
  created_at: Number;
  created_by: Number;
  description: String;
  session_start: Date;
  session_end: Date;
  is_bank_survey: Boolean;
  is_draft: Boolean;
  is_shared: Boolean;
  length: String;
  phases: String[];
  product_areas: String[];
  recurrence_type: any;
  respondents_count: Number;
  should_share_with_bank: Boolean;
  title: String;
  updated_at: String;
  updated_by: Number;
  status: Object;
  stats: any;
  creator: BankFeedbackCreatorModel | null;

  constructor(obj) {
    super(obj);

    this.bank_id = obj['bank_id'];
    this.countries = obj['countries'] ? this.sortCountriesByAlphabetically(obj['countries']) : [];
    this.created_at = obj['start_time'] * 1000;
    this.created_by = obj['created_by'];
    this.description = obj['description'];
    this.session_start = new Date(obj['start_time'] * 1000);
    this.session_end = new Date(obj['end_time'] * 1000);
    this.is_bank_survey = obj['is_bank_survey'];
    this.is_draft = obj['is_draft'];
    this.is_shared = obj['is_shared'];
    this.length = obj['length'];
    this.phases = obj['phases'];
    this.product_areas = obj['product_areas'];
    this.recurrence_type = obj['recurrence_type'];
    this.respondents_count = obj['stats']?.respondents;
    this.should_share_with_bank = obj['should_share_with_bank'];
    this.title = obj['title'];
    this.updated_at = obj['updated_at'];
    this.updated_by = obj['updated_by'];
    this.status = this.getStatus(obj['intent_status']);
    this.stats = obj['stats'];
    this.creator = obj['creator'] ?  new BankFeedbackCreatorModel(obj['creator']) : null;
  }

  getStatus(intent_status: string) {
    switch (intent_status) {
      case SurveyStatusesEnum.LIVE:
        return {statusName: translateGlobal(SurveyStatuses.LIVE), statusClass: 'live'};
      case SurveyStatusesEnum.EXPIRED:
        return {statusName: translateGlobal(SurveyStatuses.EXPIRED), statusClass: 'secondary'};
      case SurveyStatusesEnum.EXPIRES_SOON:
        return {statusName: translateGlobal(SurveyStatuses.EXPIRING_SOON), statusClass: 'danger'};
      case SurveyStatusesEnum.UPCOMING:
        return {statusName: translateGlobal(SurveyStatuses.UPCOMING), statusClass: 'primary'};
      case SurveyStatusesEnum.COMPLETED:
        return {statusName: translateGlobal(SurveyStatuses.COMPLETED), statusClass: 'success'};
      default:
        return {statusName: '', statusClass: ''};
    }
  }

  sortCountriesByAlphabetically(data: any) {
    return data.sort(function(a,b){
      return a.localeCompare(b);
    })
  }
}

class BankFeedbackCreatorModel extends BaseModel {
  first_name: string;
  last_name: string;
  full_name: string;
  name: string;
  icon_path: string;

  constructor(obj) {
    super(obj);

    this.first_name = obj.user_first_name || obj.first_name;
    this.last_name = obj.user_last_name || obj.last_name;
    this.full_name = `${this.first_name} ${this.last_name}`;
    this.name = obj.name || '';
    this.icon_path = obj.icon_path ? obj.icon_path : Constants.USER_TMP_LOGO;
  }
}
