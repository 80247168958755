import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { IDEAS_API } from "@/router/BusinessApi";
import NotificationService from "@/buying-teams/services/NotificationService";
import { BusinessFilterService } from "@/core/services/BusinessFilterService";
import { IdeaModel } from "@/store/models/request/IdeaModel";
import {diagLog} from "@/core/helpers/GlobalHelper";

@Module
export default class IdeasModule extends VuexModule {
  toggleIdeaStatusLoading = ''

  get businessIdeaFilter() {
    return BusinessFilterService.getIdeaFilter();
  }

  get getToggleIdeaStatusLoading() {
    return this.toggleIdeaStatusLoading
  }

  @Mutation
  TOGGLE_IDEA_STATUS_LOADING(value) {
    this.toggleIdeaStatusLoading = value
  }

  @Action
  async createIdea(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(IDEAS_API.CREATE_IDEA, data)
        .then(res => {
          diagLog('CREATE_REQUEST = ', res);
          resolve(res.data)
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async updateIdea(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(IDEAS_API.UPDATE_IDEA, data)
        .then(res => {
          diagLog('CREATE_REQUEST = ', res);
          resolve(res.data)
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async getIdeas(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(IDEAS_API.GET_IDEAS, data)
        .then(res => {
          this.context.commit('SAVE_BUSINESS_IDEAS_FILTER', res.data.filter)
          resolve(res.data.list.map(el => new IdeaModel(el)))
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async getIdeaDetails(id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(IDEAS_API.GET_IDEA_BY_ID, {
        params: { intent_id: id }
      })
        .then(res => {
          if (res.data) {
            resolve(new IdeaModel(res.data))
          } else {
            resolve(null)
          }
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async toggleIdeaStatus(params) {
    this.context.commit('TOGGLE_IDEA_STATUS_LOADING', params.id)
    ApiService.setHeader();

    return new Promise((resolve, reject) => {
      ApiService.post(IDEAS_API.TOGGLE_IDEA_STATUS, params)
        .then(res => {
          this.context.commit('TOGGLE_IDEA_STATUS_LOADING', '')
          resolve(res.status === 200);
        })
        .catch(error => {
          this.context.commit('TOGGLE_IDEA_STATUS_LOADING', '')
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }
}
