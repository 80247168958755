
export class BankSettingsModel {
  sharing: any = {
    inv_customers: {enabled: 1},

    fa_st_feed: {enabled: 1},
    fa_cm_req: {enabled: 1},
    fa_cm_idea: {enabled: 1},
    fa_oc_it: {enabled: 1},

    pi_share_fullname: {enabled: 1},
    pi_share_jt: {enabled: 1},
    pi_share_cnt: {enabled: 1},
    pi_share_pp: {enabled: 1}
  }

  constructor(bankSettingsData: any) {
    this.sharing = this.defineBankSharingPolicy(bankSettingsData.sharing);
  }

  defineBankSharingPolicy(bankSharing: any) {
    let sharing = {};

    for (let sharingKey in this.sharing) {
      if (bankSharing[sharingKey]) {
        sharing[sharingKey] = bankSharing[sharingKey];
      }
    }

    return sharing;
  }
}
