import { UserModel } from "@/store/models/UserModel";
import { CookieComponent } from "@/assets/ts/components";

const ID_TOKEN_KEY = "id_token" as string;
const ID_IMPERSONATE_TOKEN_KEY = "id_impersonate_token" as string;
const ID_REFRESH_TOKEN_KEY = "id_refresh_token" as string;
const USER_DATA_KEY = "user_data_key" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | undefined => {
  return CookieComponent.get(ID_TOKEN_KEY);

  // return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getImpersonateToken = (): string | undefined => {
  return CookieComponent.get(ID_IMPERSONATE_TOKEN_KEY);

  // return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description get refresh token form localStorage
 */
export const getRefreshToken = (): string | undefined => {
  return CookieComponent.get(ID_REFRESH_TOKEN_KEY);

  // return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  const expireTime = new Date().getSeconds() + (10);

  CookieComponent.set(ID_TOKEN_KEY, token, { expires: expireTime });

  // window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveImpersonateToken = (token: string): void => {
  const expireTime = new Date().getSeconds() + (10);

  CookieComponent.set(ID_IMPERSONATE_TOKEN_KEY, token, { expires: expireTime });

  // window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description save refresh token into localStorage
 * @param token: string
 */
export const saveRefreshToken = (token: string): void => {
  const expireTime = new Date().getSeconds() + (30);

  CookieComponent.set(ID_REFRESH_TOKEN_KEY, token, { expires: expireTime });
  // window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  CookieComponent.delete(ID_TOKEN_KEY);

  // window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyImpersonateToken = (): void => {
  CookieComponent.delete(ID_IMPERSONATE_TOKEN_KEY);

  // window.localStorage.removeItem(ID_TOKEN_KEY);
};

/**
 * @description remove refresh token form localStorage
 */
export const destroyRefreshToken = (): void => {
  CookieComponent.delete(ID_REFRESH_TOKEN_KEY);

  // window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
};

/**
 * @description store users min data
 */
export const saveUserDataMin = (userData: UserModel): void => {
  const userDataMin = JSON.stringify(userData);

  const expireTime = new Date().getSeconds() + (20);

  CookieComponent.set(USER_DATA_KEY, userDataMin, { expires: expireTime });

  // window.localStorage.setItem(USER_DATA_KEY, userDataMin);
};

/**
 * @description get user min data
 */
export const getUserDataMin = () => {
  return JSON.parse(CookieComponent.get(USER_DATA_KEY)!);

  // return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};

/**
 * @description remove user min data
 */
export const removeUserDataMin = (): void => {
  CookieComponent.delete(USER_DATA_KEY);

  // window.localStorage.removeItem(USER_DATA_KEY);
};

export default {
  getToken,
  getImpersonateToken,
  getRefreshToken,
  saveToken,
  saveImpersonateToken,
  saveRefreshToken,
  destroyToken,
  destroyImpersonateToken,
  destroyRefreshToken,
  saveUserDataMin,
  getUserDataMin,
  removeUserDataMin,
};
