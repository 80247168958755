import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export class BusinessPermissionGroup {
    id: number;
    name: string;
    isGroup: boolean = true;
    functionalPermissions: BusinessUserPermissionTypeEnum[];
    allowedProductAreas: string[];
    allowedCountries: string[];
    includedUsers: number[];
    blockedBanks: number[];

    constructor(obj: any) {
        this.id = obj.group_id;
        this.name = obj.group_name;
        this.isGroup = obj.is_group;
        this.functionalPermissions = obj.functional_permissions || [];
        this.allowedProductAreas = obj.access_permissions?.allowed_product_areas ?? [];
        this.allowedCountries = obj.access_permissions?.allowed_countries ?? [];
        this.blockedBanks = obj.access_permissions?.blocked_banks ?? [];
        this.includedUsers = obj.users ?? [];
    }
}

export const filterAllowedBanks = (idsData1: number[], idsData2: number[]) => {
    return idsData2.filter((element) => !idsData1.includes(element));
}
