import {BaseBankFilterModel} from "@/store/models/base/BaseBankFilterModel";

export class BankRequestFilterModel extends BaseBankFilterModel {
  show_closed_items: boolean;
  priority: object;

  constructor(obj: any) {
    super(obj);

    this.show_closed_items = obj['show_closed_items'] || false;
    this.priority = obj['priority'] || [];
  }
}
