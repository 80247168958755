import { BaseModel } from "@/store/models/base/BaseModel";
import { BankModel } from "@/store/models/bank/BankModel";
import { Constants } from "@/core/config/constants";
import { sortByAlphabetically } from "@/core/helpers/GlobalHelper";
import { InvitedUserModel } from "@/store/models/base/InvitedUserModel";

export enum InviteStatusEnum {
    PENDING = "pending",
    BOUNCED = "bounced",
}

export class BankSettingsContainer extends BaseModel {
    bankInfo: BankModel;
    bankName: string;
    countriesBank: string[];
    usersNumber: number;
    customersNumber: number;
    activeProductAreas: object[];
    allCountries: any[];
    stats: object;
    teamUsers: TeamUsers;
    invitedUsers: InvitedUserModel[];
    noLicenceCountries: any[];
    noLicenceProductAreas: any[];
    permissionGroups: BankSettingsPermissionGroupsModel[];

    constructor(obj: any) {
        super(obj);

        this.bankName = obj.name;
        this.countriesBank = obj.countries ? sortByAlphabetically(Object.keys(obj.countries)) : [];
        this.usersNumber = obj.team.length;
        this.customersNumber = Object.keys(obj.customers).length;
        this.activeProductAreas = this.convertObjectToArray(obj.product_areas);
        this.bankInfo = obj.bankInfo;
        this.allCountries = this.convertObjectToArray(obj.countries);
        this.stats = obj.stats;
        this.teamUsers = { admins: [], users: [] };
        this.setTeamData(obj.team);
        this.invitedUsers = [];
        this.noLicenceCountries = this.convertObjectToArray(obj.other_countries);
        this.noLicenceProductAreas = this.convertObjectToArray(obj.other_product_areas);
        this.permissionGroups = obj && obj.permission_groups ?
          obj.permission_groups.map(pg => new BankSettingsPermissionGroupsModel(pg)) : [];

        if (obj.invited) {
            this.setInvitedUsers(obj.invited);
        }
    }

    setInvitedUsers(invited) {
        this.invitedUsers = invited.map(i => new InvitedUserModel(i));
    }

    convertObjectToArray(value: any) {
        const data: any[] = [];
        for (let [key, item] of Object.entries(value)) {
            data.push({
                name: key,
                ...(item as any)
            });
        }
        return sortByAlphabetically(data, "name");
    }

    setTeamData(data: any[]) {
        data.forEach((val: any) => {
            if (val.is_admin) {
                this.teamUsers.admins.push(new BankTeamModel(val));
            } else {
                this.teamUsers.users.push(new BankTeamModel(val));
            }
        });
    }

    getFilteredUsers(type: string, search: string) {
        const data: BankTeamModel[] = this.teamUsers[type];
        return search
          ? data.filter((item) => {
              return item.full_name.toLowerCase().includes(search.toLowerCase());
          })
          : data;
    }

    promoteUser(userId: number) {
        this.teamUsers.users.forEach((val: BankTeamModel, index: number) => {
            if (val.id === userId) {
                val.is_admin = true;
                this.teamUsers.users.splice(index, 1);
                this.teamUsers.admins.push(val);
            }
        });
    }

    demoteUser(userId: number) {
        this.teamUsers.admins.forEach((val: BankTeamModel, index: number) => {
            if (val.id === userId) {
                val.is_admin = false;
                this.teamUsers.admins.splice(index, 1);
                this.teamUsers.users.push(val);
            }
        });
    }

    removeUser(userId: number, is_admin: boolean) {
        this.teamUsers[is_admin ? "admins" : "users"].forEach((val: BankTeamModel, index: number) => {
            if (val.id === userId) {
                this.teamUsers[is_admin ? "admins" : "users"].splice(index, 1);
            }
        });
    }

    addNewInvitedUsers(user: any) {
        this.invitedUsers.unshift(new InvitedUserModel(user));
    }

    removeInvitedUsers(user: InvitedUserModel) {
        this.invitedUsers = this.invitedUsers.filter(u => u.id !== user.id);
    }

    removePermissionGroup(groupId: number) {
        this.permissionGroups = this.permissionGroups.filter(p => p.id !== groupId);
    }
}

interface TeamUsers {
    admins: BankTeamModel[],
    users: BankTeamModel[]
}

class BankTeamModel extends BaseModel {
    country: string;
    email: string;
    first_name: string;
    followed_countries: string[];
    followed_product_areas: string[];
    icon_path: string;
    is_admin: boolean;
    job_title: string;
    last_name: string;
    full_name: string;

    constructor(obj) {
        super(obj);

        this.country = obj.country;
        this.email = obj.email;
        this.first_name = obj.first_name;
        this.followed_countries = obj.followed_countries;
        this.followed_product_areas = obj.followed_product_areas;
        this.icon_path = obj.icon_path ? obj.icon_path : Constants.USER_TMP_LOGO;
        this.is_admin = obj.is_admin;
        this.job_title = obj.job_title;
        this.last_name = obj.last_name;
        this.full_name = `${this.first_name || "-"} ${this.last_name || "-"}`;
    }
}

class BankSettingsPermissionGroupsModel extends BaseModel {
    countries: number;
    customers: number;
    name: string;
    productAreas: number;
    users: number;

    constructor(data: any) {
        super(data);

        this.countries = data.countries;
        this.customers = data.customers;
        this.name = data.name;
        this.productAreas = data.product_areas;
        this.users = data.users;
    }
}
