interface BarChart {
  positive?: boolean;
  fullSize?: number;
  score: number;
  scoreColor?: string;
  importance: number;
  importanceColor?: string;
  gap: number;
  gapColor?: string;
}

export interface BarChartItem {
  value: number;
  color: string;
  width: number;
}

const colors = {
  SCORE: '#435BF4',
  IMPORTANCE: '#CED4F8',
  GAP_POSITIVE: '#1DC28B',
  GAP_NEGATIVE: '#D01212'
}

export default class BarChartModel {
  positive: boolean;
  fullSize: number;
  score: BarChartItem;
  importance: BarChartItem;
  gap: BarChartItem;

  constructor(obj: BarChart) {
    this.positive = obj['positive'] || false;
    this.fullSize = obj['fullSize'] || 5;
    this.positive = Number(obj['gap']) >= 0;

    this.score = {
      value: obj['score'],
      color: obj['scoreColor'] || colors.SCORE,
      width: this.getPercentage(obj['score'])
    };

    this.importance = {
      value: obj['importance'],
      color: obj['importanceColor'] || colors.IMPORTANCE,
      width: this.getPercentage(obj['importance'])
    };

    this.gap = {
      value: obj['gap'],
      color: this.positive ? colors.GAP_POSITIVE : colors.GAP_NEGATIVE,
      width: this.getPercentage(obj['gap'])
    };

  }

  getPercentage(partialValue) {
    let num = Number(partialValue);
    return (100 * (num < 0 ? -num : num)) / this.fullSize;
  }
}
