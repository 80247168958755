const superAdminRoutes = [
    {
        path: "/super-admin/dashboard",
        name: "super-admin-dashboard",
        component: () => import("@/buying-teams/pages/super-admin/dashboard/SuperAdminDashboard.vue")
    },
    {
        path: "/super-admin/feedback/feedback-session/create",
        name: "super-admin/create-feedback",
        component: () => import("@/buying-teams/pages/super-admin/feedbacks/feedbacks-creation/SuperAdminNewFeedbackSession.vue")
    },
    {
        path: "/super-admin/user-management",
        name: "super-admin-user-management",
        component: () => import("@/buying-teams/pages/super-admin/user-management/SuperAdminUserManagement.vue")
    }
]

export default superAdminRoutes;
