import { BaseBusinessFilterModel } from "@/store/models/base/BaseBusinessFilterModel";

export class BusinessRequestFilterModel extends BaseBusinessFilterModel {
  display_closed_items: boolean;
  priority: object;

  constructor(obj: any) {
    super(obj);

    this.display_closed_items = obj['display_closed_items'] || false;
    this.priority = obj['priority'] || [];
  }
}
