import { BaseRequestIdeaModel } from "@/store/models/base/BaseRequestIdeaModel";
import { BusinessIntentsTypeEnum } from "@/store/enums/BusinessIntentsTypeEnum";

export class IdeaModel extends BaseRequestIdeaModel {
    type: BusinessIntentsTypeEnum;

    constructor(obj: any) {
        super(obj);

        this.type = BusinessIntentsTypeEnum.IDEA;
    }
}
