import {
    CustomQuestionsAnalyticsBaseModel
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsBaseModel";

interface chartDataInterface {
    series: chartDataSeriesInterface[];
    categories: string[];
}

interface chartDataSeriesInterface {
    name: string;
    data: number[]
}

export class CustomQuestionsAnalyticsMultipleChoiceGridModel extends CustomQuestionsAnalyticsBaseModel {
    chartData: chartDataInterface = {
        series: [],
        categories: []
    }

    constructor(data) {
        super(data);

        if (data.stats && data.stats.length) {
            data.stats.forEach(stat => {
                this.chartData.categories.push(stat.row);

                for (let j = 0; j < stat.answers.length; j ++) {
                    let opt = stat.answers[j];

                    if(!this.chartData.series[j]) {
                        this.chartData.series[j] = {
                            name: opt.column,
                            data: [opt.percentage]
                        }
                    } else {
                        this.chartData.series[j].data.push(opt.percentage)
                    }
                }
            })
        } else {
            this.chartData.categories = data.rows.map(row => row.label);
            this.chartData.series = data.columns.map(col => {
                return {
                    name: col.label,
                    data: []
                }
            })
        }
    }
}
