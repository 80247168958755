import {Module, VuexModule, Action, Mutation} from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { SIGN_UP } from "@/router/BankApi";
import NotificationService from "@/buying-teams/services/NotificationService";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

@Module
export default class BankSignUpModule extends VuexModule {

  @Action
  bankCreateNewPassword({newPassword = ''}) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(SIGN_UP.POST_NEW_PASSWORD, {new_password: newPassword})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  bankUserSetup(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(SIGN_UP.POST_USER_SETUP, data)
        .then(res => {
          store.commit(Mutations.SET_AUTH);
          store.commit(Mutations.SET_USER, res.data.info);
          store.commit("SAVE_BANK_INFO", res.data.bank);
          resolve(res);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  bankUserUpdate(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(SIGN_UP.POST_USER_UPDATE, data)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        });
    })
  }


}
