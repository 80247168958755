export class BankFeedbackFilterModel {
  product_areas: string[];
  time_period: string;
  countries: string[];

  constructor(obj: any) {
    this.product_areas = obj['product_areas'] || [];
    this.time_period = obj['time_period'] || '*';
    this.countries = obj['countries'] || [];
  }
}
