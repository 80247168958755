import store from "@/store";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import ApiService from "@/core/services/ApiService";
import NotificationService from "@/buying-teams/services/NotificationService";
import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { BANK_FILTERS } from "@/router/BankApi";
import { BankFilterService } from "@/core/services/BankFilterService";
import { BankOverviewDashboardFilterModel } from "@/store/models/filter/bank/BankOverviewDashboardFilterModel";
import { BankRequestFilterModel } from "@/store/models/filter/bank/BankRequestFilterModel";
import { BankIdeaFilterModel } from "@/store/models/filter/bank/BankIdeaFilterModel";
import { BankFeedbackFilterModel } from "@/store/models/filter/bank/BankFeedbackFilterModel";
import { BankSingleQuestionsFilterModel } from "@/store/models/filter/bank/BankSingleQuestionsFilterModel";
import { BankCustomersFilterModel } from "@/store/models/filter/bank/BankCustomersFilterModel";
import { BankSingleCustomersFilterModel } from "@/store/models/filter/bank/BankSingleCustomersFilterModel";
import { BankSingleSurveyFilterModel } from "@/store/models/filter/bank/BankSingleSurveyFilterModel";
import { DashboardProductAreaCountriesFilterModel } from "@/store/models/filter/bank/DashboardProductAreaCountriesFilterModel";
import { BankProductAreaOverviewFilterModel } from "@/store/models/filter/bank/BankProductAreaOverviewFilterModel";
import { BankCountryOverviewFilterModel } from "@/store/models/filter/bank/BankCountryOverviewFilterModel";
import { BusinessModelMin } from "@/store/models/BusinessModelMin";

interface BankFiltersMutationInterface {
    type: BankFiltersTypeEnum,
    data: any,
    needSave: boolean,
    payload: any,
}

interface BankFiltersInitInterface {
    type: BankFiltersTypeEnum,
    payload?: object,
    needSave?: boolean
}

export const DEFAULT_TIME_PERIOD = {
    start_date: new Date(new Date('1.1.2022')),
    end_date: new Date()
};

export const setQuestionPageFilterMatchData = (allData: any[] = [], filterDara = [], selfValue = true) => {
    if (!selfValue) {
        allData = allData.map((sv: any) => sv.id);
    }
    if (!filterDara.length) return allData;
    let data = [];
    filterDara.forEach((ct) => {
        if (allData.includes(ct)) {
            data.push(ct);
        }
    })
    return data;
}

interface InterfaceSavedFilterPrevPage {
    page: BankFiltersTypeEnum,
    filters: object;
}

const filtersConvert = (filters: object, prevFilters: object) => {
    const newFilters = {...filters};

    for (let [key, item] of Object.entries(filters)) {
        if (prevFilters.hasOwnProperty(key)) {
            if (key === 'time_period' && item.start_date && item.end_date) {
                newFilters[key] = filters[key];
            } else {
                if (typeof filters[key] === "string" || typeof filters[key] === "number") {
                    newFilters[key] = filters[key]
                } else {
                    newFilters[key] = setQuestionPageFilterMatchData(filters[key], prevFilters[key])
                }
            }
        }
    }

    return { ...newFilters };
};

@Module
export default class BankFiltersModule extends VuexModule {
    bankOverviewFiltersState = {} as BankOverviewDashboardFilterModel;
    productAreaOverviewFiltersState = {} as BankProductAreaOverviewFilterModel;
    countryOverviewFiltersState = {} as BankCountryOverviewFilterModel;
    countriesFiltersState = {} as DashboardProductAreaCountriesFilterModel;
    productAreasFiltersState = {} as DashboardProductAreaCountriesFilterModel;
    bankSingleQuestionsState = {} as BankSingleQuestionsFilterModel;
    bankSurveyState = {} as BankFeedbackFilterModel;
    bankRequestState = {} as BankRequestFilterModel;
    bankIdeaState = {} as BankIdeaFilterModel;
    bankCustomersState = {} as BankCustomersFilterModel;
    bankSingleSurveyState = {} as BankSingleSurveyFilterModel;
    bankSingleCustomerState = {} as BankSingleCustomersFilterModel;
    bankProductAreas: string[] = [];
    bankCountries: string[] = [];
    bankCustomersList: { id: number; name: string }[] = [];
    savedFilterPrevPage: InterfaceSavedFilterPrevPage | null = null;

    get getBankFiltersState() {
        return {
            bankOverview: this.bankOverviewFiltersState,
            productAreaOverview: this.productAreaOverviewFiltersState,
            countryOverview: this.countryOverviewFiltersState,
            countries: this.countriesFiltersState,
            productAreas: this.productAreasFiltersState,
            singleQuestions: this.bankSingleQuestionsState,
            survey: this.bankSurveyState,
            requests: this.bankRequestState,
            ideas: this.bankIdeaState,
            customerList: this.bankCustomersState,
            singleSurvey: this.bankSingleSurveyState,
            singleCustomer: this.bankSingleCustomerState,
        }
    }

    get getBankProductAreas() {
        return this.bankProductAreas;
    }

    get getBankCustomersList() {
        return this.bankCustomersList;
    }

    get getBankCountries() {
        return this.bankCountries;
    }

    get getBankDefaultTimePeriod() {
        return DEFAULT_TIME_PERIOD;
    }

    get getSavedFilterPrevPage() {
        return this.savedFilterPrevPage && this.savedFilterPrevPage.page && this.savedFilterPrevPage.filters && Object.keys(this.savedFilterPrevPage.filters).length
            ? this.savedFilterPrevPage
            : null;
    }

    @Action
    initFiltersData({type, payload, needSave}: BankFiltersInitInterface) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            let pageType = type;
            if (
                type === BankFiltersTypeEnum.COUNTRIES ||
                type === BankFiltersTypeEnum.PRODUCT_AREAS
            ) {
                pageType = BankFiltersTypeEnum.DASHBOARD;
            }
            ApiService.post(BANK_FILTERS.POST_FETCH_BANK_FILTERS, {
                page: pageType,
                ...payload
            })
                .then(res => {
                    this.context.commit('SAVE_BANK_INIT_FILTERS', {
                        type,
                        data: res.data.filters,
                        needSave,
                        payload
                    });
                    resolve(res);
                })
                .catch(error => {
                    console.log(error, '---error');
                    NotificationService.swalError(error);
                    reject()
                })
        })
    }

    @Action
    singleQuestionRedirectFilterState({ questionId, filterData }) {
        return new Promise(async (resolve) => {
            try {
                await store.dispatch('initFiltersData', {
                    payload: {question_id: questionId },
                    type: BankFiltersTypeEnum.SINGLE_QUESTION,
                    needSave: true
                });
                const singleQuestionsFilterState = store.getters.getBankFiltersState.singleQuestions;
                const filters = {
                    question_id: questionId,
                    customers: setQuestionPageFilterMatchData(singleQuestionsFilterState.customers, filterData.customers, false),
                    time_period: filterData.time_period || singleQuestionsFilterState.time_period || DEFAULT_TIME_PERIOD,
                    tiers: filterData.tiers || singleQuestionsFilterState.tiers || [],
                    customers_hq: setQuestionPageFilterMatchData(singleQuestionsFilterState.customers_hq, filterData.customers_hq),
                    industries: setQuestionPageFilterMatchData(singleQuestionsFilterState.industries, filterData.industries),
                    product_areas: setQuestionPageFilterMatchData(singleQuestionsFilterState.product_areas, filterData.product_areas),
                    countries: setQuestionPageFilterMatchData(singleQuestionsFilterState.countries, filterData.countries),
                    survey_ids: setQuestionPageFilterMatchData(singleQuestionsFilterState.survey_ids, filterData.survey_ids, false),
                };

                resolve(filters);
            } catch {
                resolve(null);
            }
        })
    }

    @Action
    reInitFiltersData() {
        const types = [
            BankFiltersTypeEnum.DASHBOARD,
            BankFiltersTypeEnum.SURVEY,
            BankFiltersTypeEnum.REQUEST,
            BankFiltersTypeEnum.IDEA,
            // TODO add another pages that we need to re-init storage data
        ]

        return new Promise((resolve, reject) => {
            types.forEach(async (type: BankFiltersTypeEnum) => {
                return await this.context.dispatch('initFiltersData', {type, needSave: true})
                    .then(r => resolve(r))
                    .catch(() => reject())
            })
        })
    }

    @Mutation
    SET_SAVED_FILTERS_PREV_PAGE(payload: InterfaceSavedFilterPrevPage) {
        this.savedFilterPrevPage = payload;
    }

    @Mutation
    SET_BANK_PRODUCT_AREAS(bankProductAreas) {
        this.bankProductAreas = bankProductAreas || [];
    }

    @Mutation
    SET_BANK_CUSTOMERS(bankCustomersList) {
        this.bankCustomersList = bankCustomersList ? bankCustomersList.map(c => new BusinessModelMin(c)) : [];
    }

    @Mutation
    SET_BANK_COUNTRIES(bankCountries) {
        this.bankCountries = bankCountries || [];
    }

    @Mutation
    SAVE_BANK_INIT_FILTERS(params: BankFiltersMutationInterface) {

        switch (params.type) {

            // Bank overview
            case BankFiltersTypeEnum.DASHBOARD: {
                const filters = BankFilterService.getBankOverviewDashboardFilter();
                const newFilters = {...params.data};
                if (newFilters.customers && newFilters.customers.length) {
                    newFilters.customers = newFilters.customers.map(c => c.id);
                }

                if (!filters || params.needSave) {
                    store.commit('SAVE_BANK_OVERVIEW_DASHBOARD_FILTER', newFilters)
                } else if (filters) {
                    store.commit('SAVE_BANK_OVERVIEW_DASHBOARD_FILTER', filtersConvert(filters, newFilters));
                }
                return this.bankOverviewFiltersState = new BankOverviewDashboardFilterModel(params.data);
            }

            // Product area overview
            case BankFiltersTypeEnum.PRODUCT_AREA_OVERVIEW: {
                const filters = BankFilterService.getBankProductAreaOverviewFilter();
                const filtersState = {
                    ...params.data,
                    product_area: params.payload.product_area
                }
                let newFilters = {...filtersState};
                if (newFilters.customers && newFilters.customers.length) {
                    newFilters.customers = newFilters.customers.map(c => c.id);
                }

                if (store.getters.getSavedFilterPrevPage && store.getters.getSavedFilterPrevPage.page === BankFiltersTypeEnum.PRODUCT_AREA_OVERVIEW) {
                    newFilters = filtersConvert(newFilters, store.getters.getSavedFilterPrevPage.filters);
                    store.commit('SAVE_PRODUCT_AREA_OVERVIEW_FILTER', newFilters);
                    store.commit('SET_SAVED_FILTERS_PREV_PAGE', null);
                } else if (!filters || params.needSave || (filters && filters.product_area !== filtersState.product_area)) {
                    store.commit('SAVE_PRODUCT_AREA_OVERVIEW_FILTER', newFilters);
                } else if (filters) {
                    store.commit('SAVE_PRODUCT_AREA_OVERVIEW_FILTER', filtersConvert(filters, newFilters));
                }

                return this.productAreaOverviewFiltersState = new BankProductAreaOverviewFilterModel(filtersState);
            }

            // Country overview
            case BankFiltersTypeEnum.COUNTRY_OVERVIEW: {
                const filters = BankFilterService.getBankCountryOverviewFilter();
                const filtersState = {
                    ...params.data,
                    country: params.payload.country
                }
                let newFilters = {...filtersState};
                if (newFilters.customers && newFilters.customers.length) {
                    newFilters.customers = newFilters.customers.map(c => c.id);
                }

                if (store.getters.getSavedFilterPrevPage && store.getters.getSavedFilterPrevPage.page === BankFiltersTypeEnum.COUNTRY_OVERVIEW) {
                    newFilters = filtersConvert(newFilters, store.getters.getSavedFilterPrevPage.filters);
                    store.commit('SAVE_COUNTRY_OVERVIEW_FILTER', newFilters);
                    store.commit('SET_SAVED_FILTERS_PREV_PAGE', null);
                } else if (!filters || params.needSave || (filters && filters.country !== filtersState.country)) {
                    store.commit('SAVE_COUNTRY_OVERVIEW_FILTER', newFilters);
                } else if (filters) {
                    store.commit('SAVE_COUNTRY_OVERVIEW_FILTER', filtersConvert(filters, newFilters));
                }

                return this.countryOverviewFiltersState = new BankCountryOverviewFilterModel(filtersState);
            }

            // Product areas
            case BankFiltersTypeEnum.PRODUCT_AREAS: {
                const filters = BankFilterService.getProductAreasDashboardFilter();
                const newFilters = {...params.data};
                if (newFilters.customers && newFilters.customers.length) {
                    newFilters.customers = newFilters.customers.map(c => c.id);
                }
                if (!filters || params.needSave) {
                    store.commit('SAVE_PRODUCT_AREAS_DASHBOARD_FILTER', newFilters);
                } else if (filters) {
                    store.commit('SAVE_PRODUCT_AREAS_DASHBOARD_FILTER', filtersConvert(filters, newFilters));
                }
                return this.productAreasFiltersState = new DashboardProductAreaCountriesFilterModel(params.data);
            }

            // Countries
            case BankFiltersTypeEnum.COUNTRIES: {
                const filters = BankFilterService.getCountriesDashboardFilter();
                const newFilters = {...params.data};
                if (newFilters.customers && newFilters.customers.length) {
                    newFilters.customers = newFilters.customers.map(c => c.id);
                }
                if (!filters || params.needSave) {
                    store.commit('SAVE_COUNTRIES_DASHBOARD_FILTER', newFilters);
                } else if (filters) {
                    store.commit('SAVE_COUNTRIES_DASHBOARD_FILTER', filtersConvert(filters, newFilters));
                }
                return this.countriesFiltersState = new DashboardProductAreaCountriesFilterModel(params.data);
            }

            // Feedback
            case BankFiltersTypeEnum.SURVEY: {
                const filters = BankFilterService.getBankFeedbackFilter();
                if (!filters || params.needSave) {
                    store.commit('SAVE_BANK_FEEDBACK_FILTER', params.data)
                }
                return this.bankSurveyState = new BankFeedbackFilterModel(params.data);
            }

            // Request
            case BankFiltersTypeEnum.REQUEST: {
                const filters = BankFilterService.getBankRequestFilter();
                const newFilters = {...params.data};
                if (!filters || params.needSave) {
                    if (newFilters.customers && newFilters.customers.length) {
                        newFilters.customers = newFilters.customers.map(c => c.id);
                    }
                    store.commit('SAVE_BANK_REQUEST_FILTER', newFilters)
                }
                return this.bankRequestState = new BankRequestFilterModel(params.data);
            }

            // Idea
            case BankFiltersTypeEnum.IDEA: {
                const filters = BankFilterService.getBankIdeaFilter();
                const newFilters = {...params.data};
                if (!filters || params.needSave) {
                    if (newFilters.customers && newFilters.customers.length) {
                        newFilters.customers = newFilters.customers.map(c => c.id);
                    }
                    store.commit('SAVE_BANK_IDEA_FILTER', newFilters)
                }
                return this.bankIdeaState = new BankIdeaFilterModel(params.data);
            }

            // Customers List
            case BankFiltersTypeEnum.CUSTOMERS: {
                const filters = BankFilterService.getBankCustomersFilter();
                params.data.survey_ids = params.data.surveys ? params.data.surveys : [];

                const newFilters = {
                    ...params.data,
                    customers: params.data.customers ? params.data.customers.map(c => c.id) : [],
                    survey_ids: params.data.survey_ids ? params.data.survey_ids.map(c => c.id) : [],
                };

                if (!filters || params.needSave) {
                    store.commit('SAVE_BANK_CUSTOMERS_FILTER', newFilters)
                } else if (filters) {
                    store.commit('SAVE_BANK_CUSTOMERS_FILTER', filtersConvert(filters, newFilters))
                }

                return this.bankCustomersState = new BankCustomersFilterModel(params.data);
            }

            // Single Question
            case BankFiltersTypeEnum.SINGLE_QUESTION: {
                const filters = BankFilterService.getBankSingleQuestionFilter();
                params.data.survey_ids = params.data.surveys ? params.data.surveys : [];
                const filtersState = {
                    ...params.data,
                    question_id: params.payload.question_id
                }
                const newFilters = {...filtersState};

                if (!filters || params.needSave || (filters && filters.question_id !== filtersState.question_id)) {
                    if (newFilters.customers && newFilters.customers.length) {
                        newFilters.customers = newFilters.customers.map(c => c.id);
                    }
                    if (newFilters.survey_ids && newFilters.survey_ids.length) {
                        newFilters.survey_ids = newFilters.survey_ids.map(c => c.id);
                    }

                    store.commit('SAVE_BANK_SINGLE_QUESTION_FILTER', newFilters)
                } else if (filters) {
                    store.commit('SAVE_BANK_SINGLE_QUESTION_FILTER', filters)
                }

                return this.bankSingleQuestionsState = new BankSingleQuestionsFilterModel(filtersState);
            }

            // Single Feedback
            case BankFiltersTypeEnum.SINGLE_SURVEY: {
                const filters = BankFilterService.getBankSingleSurveyFilter();
                const filtersState = {
                    ...params.data,
                    survey_id: params.payload.survey_id
                }
                if (!filters || params.needSave || (filters && filters.survey_id !== filtersState.survey_id)) {
                    store.commit('SAVE_BANK_SINGLE_SURVEY_FILTER', filtersState)
                } else if (filters) {
                    store.commit('SAVE_BANK_SINGLE_SURVEY_FILTER', filtersConvert(filters, filtersState))
                }

                return this.bankSingleSurveyState = new BankSingleSurveyFilterModel(filtersState);
            }

            // Single Customer
            case BankFiltersTypeEnum.SINGLE_CUSTOMER: {
                const filters = BankFilterService.getBankSingleCustomersFilter();
                params.data.survey_ids = params.data.surveys ? params.data.surveys : [];
                const filtersState = {
                    ...params.data,
                    business_id: params.payload.business_id
                }
                let newFilters = {...filtersState};
                if (newFilters.survey_ids && newFilters.survey_ids.length) {
                    newFilters.survey_ids = newFilters.survey_ids.map(c => c.id);
                }

                if (store.getters.getSavedFilterPrevPage && store.getters.getSavedFilterPrevPage.page === BankFiltersTypeEnum.SINGLE_CUSTOMER) {
                    newFilters = filtersConvert(newFilters, store.getters.getSavedFilterPrevPage.filters);
                    store.commit('SAVE_BANK_SINGLE_CUSTOMERS_FILTER', newFilters);
                    store.commit('SET_SAVED_FILTERS_PREV_PAGE', null);
                } else if (!filters || params.needSave || (filters && filters.business_id !== filtersState.business_id)) {
                    store.commit('SAVE_BANK_SINGLE_CUSTOMERS_FILTER', newFilters)
                } else if (filters) {
                    store.commit('SAVE_BANK_SINGLE_CUSTOMERS_FILTER', filtersConvert(filters, newFilters))
                }

                return this.bankSingleCustomerState = new BankSingleCustomersFilterModel(filtersState);
            }
        }
    }
}
