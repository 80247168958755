import { QuestionModel } from "@/store/models/bank/CompareProductAreaModel";
import BarChartModel from "@/store/models/BarChartModel";

export class CustomQuestionsAnalyticsScoreImportanceModel extends QuestionModel {
    isCustom: boolean = true;

    constructor(data) {
        super(data);
        this.question_id = data.survey_question_id;
        this.question = data ? data.text : "";

        if (data.stats && data.stats.stats) {
            this.score = data.stats.stats.score;
            this.scores = data.stats.stats.scores;
            this.gap = data.stats.stats.gap;
            this.importance = data.stats.stats.importance;
            this.commentsCount = data.stats.stats.comments || 0;
            this.chartData = new BarChartModel(data.stats.stats);
        }
    }
}
