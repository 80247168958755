import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import BusinessModule from "@/store/modules/BusinessModule";
import SurveysModule from "@/store/modules/SurveysModule";
import RequestsModule from "@/store/modules/RequestsModule";
import StaticDataModule from "@/store/modules/StaticDataModule";
import RegistrationModule from "@/store/modules/RegistrationModule";
import IdeasModule from "@/store/modules/IdeasModule";
import TeamModule from "@/store/modules/TeamModule";
import UserProfileModule from "@/store/modules/UserProfileModule";
import ReportsModule from "@/store/modules/ReportsModule";
import CommentsModule from "@/store/modules/CommentsModule";
import NotificationsModule from "@/store/modules/NotificationsModule";
import BankModule from "@/store/modules/bank/BankModule";
import BankTeamModule from "@/store/modules/bank/BankTeamModule";
import BankSurveysModule from "@/store/modules/bank/BankSurveysModule";
import BankRequestsModule from "@/store/modules/bank/BankRequestsModule";
import BankIdeasModule from "@/store/modules/bank/BankIdeasModule";
import BankSignUpModule from "@/store/modules/bank/BankSignUpModule";
import BankUserProfileModule from "@/store/modules/bank/BankUserProfileModule";
import BankFiltersModule from "@/store/modules/bank/BankFiltersModule";
import BankNotificationsModule from "@/store/modules/bank/BankNotificationsModule";
import SharedModule from "@/store/modules/shared/SharedModule";
import CompareModule from "@/store/modules/CompareModule";
import SuperAdminSurveysModule from "@/store/modules/super-admin/SuperAdminSurveysModule";
import BusinessFiltersModule from "@/store/modules/business/BusinessFiltersModule";
import CustomQuestionsModule from "@/store/modules/shared/CustomQuestionsModule";
import SuperAdminUserManagementModule from "@/store/modules/super-admin/SuperAdminUserManagementModule";
import BankPermissionsModule from "@/store/modules/bank/BankPermissionsModule";
import BusinessPermissionsModule from "@/store/modules/business/BusinessPermissionsModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    BusinessModule,
    SurveysModule,
    IdeasModule,
    StaticDataModule,
    RegistrationModule,
    RequestsModule,
    TeamModule,
    UserProfileModule,
    ReportsModule,
    CommentsModule,
    NotificationsModule,
    BankModule,
    BankTeamModule,
    BankSurveysModule,
    BankRequestsModule,
    BankIdeasModule,
    BankSignUpModule,
    BankNotificationsModule,
    BankUserProfileModule,
    BankFiltersModule,
    SharedModule,
    CompareModule,
    SuperAdminSurveysModule,
    BusinessFiltersModule,
    CustomQuestionsModule,
    SuperAdminUserManagementModule,
    BankPermissionsModule,
    BusinessPermissionsModule
  },
});

export default store;
