enum BusinessFiltersTypeEnum {
    DASHBOARD_OVERVIEW = 'dashboard-overview',
    DASHBOARD_BANKS = 'dashboard-banks',
    DASHBOARD_PRODUCT_AREAS = 'dashboard-product-ares',
    DASHBOARD_COUNTRIES = 'dashboard-countries',
    DASHBOARD_SINGLE_BANK = 'dashboard-single-bank',
    DASHBOARD_SINGLE_PRODUCT_AREA = 'dashboard-product-area',
    DASHBOARD_SINGLE_COUNTRY = 'dashboard-single-country',
    SINGLE_QUESTION = 'business-single-question',
    FEEDBACK_SESSION = 'business-feedback-session',
    REQUESTS = 'business-requests',
    IDEAS = 'business-ideas',
    TEAMS = 'business-teams',
}

export default BusinessFiltersTypeEnum;
