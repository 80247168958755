const bankRegisterRoutes = [
  {
    path: "/bank-register/personal-info",
    name: "bank-register/personal-info",
    component: () =>
        import("@/buying-teams/pages/authentication/setup/bank/BankPersonalInfoSetup.vue"),
  },
  {
    path: "/bank-register/bank-info",
    name: "bank-register/bank-info",
    component: () =>
        import("@/buying-teams/pages/authentication/setup/bank/BankSetup.vue"),
  },
  {
    path: "/bank-register/new-password",
    name: "bank-register/new-password",
    component: () =>
        import("@/buying-teams/pages/authentication/setup/bank/CreateNewPassword.vue"),
  },
];

export default bankRegisterRoutes;
