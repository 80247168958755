import CircleChartModel from "@/store/models/CircleChartModel";
import BarChartModel from "@/store/models/BarChartModel";
import {numberDecimalsFormat} from "@/core/helpers/GlobalHelper";
import {FeedbackSessionQuestionsTypeEnum} from "@/store/enums/FeedbackSessionQuestionsTypeEnum";

export class CompareProductAreaModel {
  productArea: string = "";
  analysisValue: string = "highest_score";
  score: CircleChartModel | null = null;
  scoreCount: number = 0;
  followed: ScoreLabelModel | null = null;
  high: ScoreLabelModel | null = null;
  low: ScoreLabelModel | null = null;
  questionList: [] = [];

  constructor(obj) {
    this.productArea = obj ? obj.product_area : "";
    this.score = obj ? new CircleChartModel(obj) : new CircleChartModel({score: 0, importance: 0});
    this.scoreCount = obj ? obj.scores : 0;
    this.followed = new ScoreLabelModel(obj ? obj.followed : null);
    this.high = new ScoreLabelModel(obj ? obj.high: null);
    this.low = new ScoreLabelModel(obj ? obj.low : null);
    if (obj && obj.questions) {
      this.questionList = obj.questions.map(val => new QuestionModel({
        ...val,
        ...val.stats
      }))
    }
  }

  async sortQuestionsBy(what: string | null): Promise<QuestionModel[]> {
    switch (what) {
      case 'highest_score':
        this.questionList = this.questionList.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.score) - parseFloat(q1.score));
        break;
      case 'lowest_score':
        this.questionList = this.questionList.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
        break;
      case 'highest_importance_score':
        this.questionList = this.questionList.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.importance) - parseFloat(q1.importance));
        break;
      case 'lowest_importance_score':
        this.questionList = this.questionList.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.importance) - parseFloat(q2.importance));
        break;
      case 'highest_negative_gap':
        this.questionList = this.questionList.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.gap) - parseFloat(q2.gap));
        break;
      case 'highest_positive_gap':
        this.questionList = this.questionList.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.gap) - parseFloat(q1.gap));
        break;
      case 'highest_standard_deviation':
        this.questionList = this.questionList.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
        break;
      case 'lowest_standard_deviation':
        this.questionList = this.questionList.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
        break;
    }

    return this.questionList;
  }
}

class ScoreLabelModel {
  score: string;
  value: string;

  constructor(data) {
    this.score = numberDecimalsFormat(data ? data.score : 0)
    this.value = numberDecimalsFormat(data ? data.importance : 0)
  }
}

export class QuestionModel {
  gap: string = "";
  id: any = null;
  question_id: any = null;
  importance: string = "";
  question: string = "";
  score: string = "";
  scores: string = "";
  chartData: BarChartModel | null = null;
  commentsCount: string;
  productArea: string;
  phase: string;
  tags?: string[];
  type: string;

  constructor(data) {
    this.id = data ? data.id : null;
    this.question_id = data ? data.question_id : null;
    this.score = data ? data.score : "";
    this.gap = data ? data.gap : "";
    this.importance = data ? data.importance : "";
    this.scores = data ? data.scores : "";
    this.question = data ? (data.question || data.text) : "";
    this.chartData = data ? new BarChartModel(data): new BarChartModel({score: 0, importance: 0, gap: 0});
    this.commentsCount = data.comments || 0;
    this.productArea = data ? data.product_area : '';
    this.phase = data ? data.phase : '';
    this.tags = data.tags;
    this.type = data.type ? data.type : FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE;
  }
}
