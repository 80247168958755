import {
    CustomQuestionsAnalyticsMultipleChoiceGridModel
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsMultipleChoiceGridModel";
import {
    CustomQuestionsAnalyticsMultipleChoiceModel
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsMultipleChoiceModel";
import {
    CustomQuestionsAnalyticsTextOnlyModel
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsTextOnlyModel";
import {
    CustomQuestionsAnalyticsScoreImportanceModel
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsScoreImportanceModel";
import {FeedbackSessionQuestionsTypeEnum} from "@/store/enums/FeedbackSessionQuestionsTypeEnum";

export class CustomQuestionsAnalyticsContainer {
    questionsLength: number = 0;
    redirectLoading: number | null = null;
    scoreImportanceQuestions: CustomQuestionsAnalyticsScoreImportanceModel[] = [];
    multipleChoiceGridQuestions: CustomQuestionsAnalyticsMultipleChoiceGridModel[] = [];
    multipleChoiceQuestions: CustomQuestionsAnalyticsMultipleChoiceModel[] = [];
    textOnlyQuestions: CustomQuestionsAnalyticsTextOnlyModel[] = [];

    constructor() {
    }

    resetAllQuestionsData() {
        this.scoreImportanceQuestions = [];
        this.multipleChoiceGridQuestions = [];
        this.multipleChoiceQuestions = [];
        this.textOnlyQuestions = [];
    }

    setCustomQuestionsAnalyticsData(data: any[] = []) {
        this.resetAllQuestionsData();

        if (data && data.length) {
            this.questionsLength = data.length;

            data.forEach(data => {
                switch (data.type) {
                    case FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE: {
                        this.scoreImportanceQuestions.push(new CustomQuestionsAnalyticsScoreImportanceModel(data));
                        break;
                    }
                    case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE: {
                        this.multipleChoiceQuestions.push(new CustomQuestionsAnalyticsMultipleChoiceModel(data));
                        break;
                    }
                    case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID: {
                        this.multipleChoiceGridQuestions.push(new CustomQuestionsAnalyticsMultipleChoiceGridModel(data));
                        break;
                    }
                    case FeedbackSessionQuestionsTypeEnum.TEXT_ONLY: {
                        this.textOnlyQuestions.push(new CustomQuestionsAnalyticsTextOnlyModel(data));
                        break;
                    }

                }
            })
        }
    }
}
