import { Constants } from "@/core/config/constants";

export class BusinessModelMin {
  id: number;
  name: string;
  icon_path: string;
  revenue?: string;
  industries?: string[];
  country?: string;

  constructor(obj: any) {
    this.id = obj.id;
    this.name = obj.name;
    this.icon_path = obj.icon_path || Constants.BUSINESS_TMP_LOGO;
    this.revenue = obj.revenue;
    this.industries = obj.industries;
    this.country = obj.country || obj.hq;
  }
}
