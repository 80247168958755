import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { API_REGISTER } from "@/router/ApiRoutes";
import store from "@/store";
import NotificationService from "@/buying-teams/services/NotificationService";

@Module
export default class RegistrationModule extends VuexModule {
  registrationData = {};

  get registrationInfo() {
    return this.registrationData;
  }

  @Mutation
  SAVE_REGISTRATION_DATA(payload) {
    this.registrationData = payload;
  }

  @Action
  createBusiness(businessData) {
    ApiService.setHeader();
    return ApiService.post(API_REGISTER.BUSINESS_CREATE, businessData);
  }

  @Action
  setupBusinessUser(businessUserData) {
    ApiService.setHeader();
    return ApiService.post(API_REGISTER.BUSINESS_SETUP_USER, businessUserData);
  }

  @Action
  updateInvBusiness(businessData) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_REGISTER.BUSINESS_INV_UPDATE, businessData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          NotificationService.swalError(error);
          reject();
        })
    });
  }
}
