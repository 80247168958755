import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import { SentryService } from "@/core/services/SentryService";
/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL + process.env.VUE_APP_VERSION_API;

    ApiService.vueInstance.axios.defaults.headers.common[
      "x-api-key"
      ] = `${process.env.VUE_APP_X_API_KEY}`;

    // ApiService.vueInstance.axios.defaults.headers.common[
    //   "user-vers"
    //   ] = `${process.env.VUE_APP_CLIENT_KEY}`;

    ApiService.vueInstance.axios.interceptors.response.use(
      response => response,
      error => this.handleError(error)
    );

    // for Authorized Calls
    if (JwtService.getToken()) {
      ApiService.setHeader();
    }

    // For impersonation
    const impersonateToken = JwtService.getImpersonateToken();
    if (impersonateToken) {
      ApiService.setImpersonateHeader(impersonateToken);
    }
  }

  public static async handleError(error) {
    // TODO add handle functionality
    console.error('error', error.response)
    try {
      SentryService.captureException(error.response);
    } catch (e) {
      console.error('Sentry capture failed ', e);
    }

    switch (error.response.status) {
      case undefined:
      case 401:
        console.log(error.response);
        const prevRoute = error.response.config.url;

        // const userData = DataService.getAuthUserData();
        // console.log(userData);
        // const refreshData = {
        //   email: userData.email,
        //   token: getRefreshToken()
        // }

        JwtService.destroyImpersonateToken();
        store.commit(Mutations.PURGE_AUTH);

        setTimeout(() => {
          window.location.href = '/sign-in';
        }, 1500);
        return Promise.reject(error.response.data.error);

        // await store.dispatch('refreshToken', refreshData).then(res => {
        //   console.log('refreshToken = ', res);
        //   // TODO add check, is response is success, redirect to "prevRoute"
        // })
        // redirectToRouteWithName('continue-to-login')
        // store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "sign-in" }));
        break;
      case 500:
        console.error('HANDLE ERROR STATUS = ', error.response.status);
        break;
      case 410:
        console.error('HANDLE ERROR STATUS = ', error.response.status);
        await redirectToRouteWithName('define');
        return Promise.reject(error.response.data.error);
      case 498:
        console.error('HANDLE ERROR STATUS = ', error.response.status);
        await store.dispatch(Actions.HANDLE_IMPERSONATION_EXPIRE);
        return Promise.reject(error.response.data.error);
      case 405:
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 500);
        return Promise.reject(error.response.data.error);
      default:
        console.error('HANDLE ERROR STATUS = ', error.response.status)
        return Promise.reject(error.response.data.error)
    }
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
      ] = `${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  public static setMultipartHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
      ] = `${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] =
      "multipart/form-data";
  }

  public static setImpersonateHeader(token: string) {
    ApiService.vueInstance.axios.defaults.headers.common["X-Impersonate-Token"] =
        token;
  }

  public static deleteImpersonateHeader() {
    delete axios.defaults.headers.common["X-Impersonate-Token"];
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig | any
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
