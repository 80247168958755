import { RequestIdeaCommentModel } from "@/store/models/request/RequestIdeaCommentModel";

interface Bs {
  comments: []
}
export class RequestIdeaCommentContainerModel {
  comments: Map<number, RequestIdeaCommentModel>;

  constructor(obj: Bs) {
    this.comments = new Map() as Map<number, RequestIdeaCommentModel>;
    obj.comments.map((cm: any) => this.comments.set(cm.id, new RequestIdeaCommentModel(cm)));
  }

  deleteComment(id: number) {
    if (this.comments.has(id)) this.comments.delete(id);
  }

  getComments() {
    return Array.from(this.comments.values()).sort(
        (objA, objB) => Number(objB.created_at) - Number(objA.created_at),
    );
  }

  updateComment(data: any) {
    let comment = this.comments.get(data.id);

    if (comment) this.comments.set(data.id, new RequestIdeaCommentModel(data));
  }

  addComment(data: any) {
    this.comments.set(data.id, new RequestIdeaCommentModel(data))
  }

}
