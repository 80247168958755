import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

export class BankPermissionGroup {
    id: number;
    name: string;
    isGroup: boolean = true;
    functionalPermissions: BankUserPermissionTypeEnum[];
    allowedProductAreas: string[];
    allowedCountries: string[];
    includedUsers: number[];
    blockedCustomers: number[];

    constructor(obj: any) {
        this.id = obj.group_id;
        this.name = obj.group_name;
        this.isGroup = obj.is_group;
        this.functionalPermissions = obj.functional_permissions || [];
        this.allowedProductAreas = obj.access_permissions?.allowed_product_areas ?? [];
        this.allowedCountries = obj.access_permissions?.allowed_countries ?? [];
        this.blockedCustomers = obj.access_permissions?.blocked_customers ?? [];
        this.includedUsers = obj.users ?? [];
    }
}

export const filterAllowedCustomers = (idsData1: number[], idsData2: number[]) => {
    return idsData2.filter((element) => !idsData1.includes(element));
}
