import { UserModel } from "@/store/models/UserModel";
import { PermissionModel } from "@/store/models/base/PermissionModel";
import { DataService } from "@/core/services/DataService";
import { Constants } from "@/core/config/constants";

export class RequestIdeaCommentModel {
  id: number;
  intent_creator_id: number;
  body: string;
  creator: UserModel;
  created_at: Date;
  updated_at: Date;
  permissions: PermissionModel;
  business: {
    business_name: string,
    icon_path: string
  }

  constructor(obj: any) {
    this.id = obj['id'];
    this.intent_creator_id = obj['intent_creator_id'];
    this.body = obj['body'];
    this.creator = new UserModel(obj['commenter'] ? obj['commenter'] : {});
    this.business = {
      business_name: obj['business_name'],
      icon_path: obj['business_icon_path'] ? obj['business_icon_path'] : Constants.BUSINESS_TMP_LOGO
    }
    this.created_at = new Date(obj['created_at']);
    this.updated_at = new Date(obj['updated_at']);
    this.permissions = this.definePermissions();
  }

  definePermissions(forWho: UserModel | null = null): PermissionModel {
    const user = forWho || this.authUser();

    return new PermissionModel({
      can_edit: user!.id === this.creator.id,
      can_delete: user!.id === this.creator.id || user!.id === this.intent_creator_id || user!.isAdmin
    });
  }

  protected authUser(){
    return DataService.getAuthUserData()
  }
}
