export class BankSingleSurveyFilterModel {
    survey_id: number;
    customers_hq: string[];
    industries: string[];
    product_areas: string[];
    tiers: number[];

    constructor(obj: any) {
        this.survey_id = obj.survey_id;
        this.customers_hq = obj.customers_hq || [];
        this.industries = obj.industries || [];
        this.product_areas = obj.product_areas || [];
        this.tiers = obj.tiers || [];
    }
}
