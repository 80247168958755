import { BaseModel } from "@/store/models/base/BaseModel";
import { Constants } from "@/core/config/constants";
import { RequestStatusModel } from "@/store/models/request/RequestStatusModel";
import { RequestPriorityModel } from "@/store/models/request/RequestPriorityModel";
import { UserModel } from "@/store/models/UserModel";
import { BankRequestIdeaCommentContainerModel } from "@/store/models/bank/request/BankRequestIdeaCommentContainerModel";

export class BankBaseRequestIdeaModel extends BaseModel {
  bank_id: number;
  business_icon_path: string;
  business_name: string;
  closed_at: string;
  comments_count: string;
  country: string;
  created_at: string;
  created_by: number;
  description: string;
  opened_at: string;
  priority: RequestPriorityModel;
  product_areas: string[];
  status: RequestStatusModel;
  title: string;
  type: string
  updated_at: string;
  updated_by: number;
  creator: UserModel;
  commentsContainer: BankRequestIdeaCommentContainerModel;

  constructor(obj) {
    super(obj);

    this.bank_id = obj.bank_id;
    this.business_icon_path = obj.business_icon_path ? obj.business_icon_path : Constants.BUSINESS_TMP_LOGO;
    this.business_name = obj.business_name;
    this.closed_at = obj.closed_at;
    this.comments_count = obj.comments_count;
    this.country = obj.country;
    this.created_at = obj.created_at;
    this.created_by = obj.created_by;
    this.description = obj.description;
    this.opened_at = obj.opened_at;
    this.priority = new RequestPriorityModel(obj.priority);
    this.product_areas = obj.product_areas ? obj.product_areas : [];
    this.status = new RequestStatusModel(obj.status);
    this.title = obj.title;
    this.type = obj.type;
    this.updated_at = obj.updated_at;
    this.updated_by = obj.updated_by;
    this.commentsContainer = new BankRequestIdeaCommentContainerModel({comments: obj.comments ? obj.comments : [] });

    if (obj.creator) {
      this.creator = new UserModel(obj.creator);
      this.business_icon_path = obj.creator?.business_icon_path || Constants.BUSINESS_TMP_LOGO;
    } else {
      this.creator = new UserModel({
        id: obj.created_by,
        first_name: obj.first_name,
        last_name: obj.last_name,
        job_title: obj.job_title,
        icon_path: obj.icon_path
      })
    }
  }
}
