import { Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { REPORTS_API } from "@/router/BusinessApi";
import NotificationService from "@/buying-teams/services/NotificationService";

@Module
export default class ReportsModule extends VuexModule {

  @Action
  async generateReport(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(REPORTS_API.GENERATE_REPORTS, data)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }
}
