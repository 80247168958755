import {BankFeedbackModel} from "@/store/models/bank/feedback/BankFeedbackModel";
import CircleChartModel from "@/store/models/CircleChartModel";
import {QuestionModel} from "@/store/models/bank/CompareProductAreaModel";
import {Constants} from "@/core/config/constants";
import {
    CustomQuestionsAnalyticsContainer
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsContainer";

export class BankSingleFeedbackContainer {
    id: number;
    feedbacksSessionData: BankFeedbackModel;
    feedbackSessionScore: CircleChartModel;
    allQuestions: QuestionModel[];
    businesses: any[];
    replyRate: string;
    invitedCustomers: number = 0;
    invitedRespondents: number = 0;
    repliedCustomers: number = 0;
    repliedRespondents: number = 0;
    customQuestionsSectionData: CustomQuestionsAnalyticsContainer = new CustomQuestionsAnalyticsContainer();

    constructor(obj) {
        this.id = obj.survey.id;
        this.feedbacksSessionData = new BankFeedbackModel(obj['survey']);
        this.feedbackSessionScore = new CircleChartModel(obj['stats']);
        this.allQuestions = obj && obj['questions'] ? obj['questions'].map(q => new QuestionModel({
            ...q,
            ...q.stats
        })) : [];
        this.replyRate = obj && obj['invites'] ? (obj['invites'].reply_rate || "0") : "";
        this.businesses = obj && obj['invites'] && obj['invites'].businesses ? obj['invites'].businesses.map(
            bs => new BusinessInfoModel(bs)
        ) : [];

        this.setCustomersStats(obj['invites'].businesses);

        if (obj.customQuestions) {
            this.customQuestionsSectionData.setCustomQuestionsAnalyticsData(obj.customQuestions);
        }
    }

    async sortQuestionsBy(what: string | null): Promise<QuestionModel[]> {
        switch (what) {
            case 'highest_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.score) - parseFloat(q1.score));
                break;
            case 'lowest_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
            case 'highest_importance_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.importance) - parseFloat(q1.importance));
                break;
            case 'lowest_importance_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.importance) - parseFloat(q2.importance));
                break;
            case 'highest_negative_gap':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.gap) - parseFloat(q2.gap));
                break;
            case 'highest_positive_gap':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.gap) - parseFloat(q1.gap));
                break;
            case 'highest_standard_deviation':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
            case 'lowest_standard_deviation':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
        }

        return this.allQuestions;
    }

    setCustomersStats(businesses: []) {
        if (businesses) {
            this.invitedCustomers = businesses.length;
            let allInvitedRespondents: number = 0;
            let allRepliedCustomers: number = 0;
            let allRepliedRespondents: number = 0;

            businesses.forEach((bs: any) => {
                allInvitedRespondents += bs.users.length;
                if (bs.replied_count && bs.replied_count > 0) {
                    allRepliedCustomers++;
                    allRepliedRespondents += bs.users.filter((user: any) => user.is_replied).length;
                }
            })

            this.invitedRespondents = allInvitedRespondents;
            this.repliedCustomers = allRepliedCustomers;
            this.repliedRespondents = allRepliedRespondents;
        }
    }
}

class BusinessInfoModel {
    id: string;
    name: string;
    icon_path: string;
    replied: boolean;
    usersReplied: number;
    allUsers: number;
    usersList: any[];

    constructor(obj: any) {
        this.id = obj.id;
        this.name = obj.name;
        this.icon_path = obj['icon_path'] ? obj['icon_path'] : Constants.BUSINESS_TMP_LOGO;
        this.replied = obj['replied_count'] && obj['replied_count'] > 0;
        this.allUsers = obj['users'] ? obj['users'].length : 0;
        this.usersReplied = obj['users'] ? obj['users'].filter((u: any) => u.is_replied).length : 0;
        this.usersList = obj['users'] ? obj.users.map(us => new BusinessInfoUserModel(us)) : [];
    }
}

class BusinessInfoUserModel {
    id: number;
    job_title: string;
    country: string;
    first_name?: string
    last_name?: string;
    business_id: number;
    icon_path?: string
    email: string;
    is_replied: boolean;

    constructor(obj: any) {
        this.id = obj.id;
        this.job_title = obj.job_title;
        this.country = obj.country;
        this.first_name = obj.first_name;
        this.last_name = obj.last_name;
        this.business_id = obj.business_id;
        this.icon_path = obj.icon_path;
        this.email = obj.email;
        this.is_replied = obj.is_replied;
    }

    get displayName() {
        let name = '';
        if (this.first_name) name = this.first_name;
        if (this.last_name) name += this.last_name;
        if (!name) name = this.email;

        return name;
    }
}
