import { BaseModel } from "@/store/models/base/BaseModel";
import { Constants } from "@/core/config/constants";
import { BusinessSettingsModel } from "@/store/models/BusinessSettingsModel";
import { BusinessBankModel } from "@/store/models/BusinessBankModel";

export class BusinessModel extends BaseModel {
  country: String;
  created_at: Date;
  created_by: Number;
  domain: String;
  icon_path: String;
  industries: String[];
  is_requests_confirmation_mandatory: Boolean;
  name: String;
  num_employees: String;
  revenue: String;
  settings: BusinessSettingsModel;
  updated_at: Date;
  updated_by: Number;
  banks = [] as BusinessBankModel[];

  constructor(obj: any) {
    super(obj);

    this.country = obj['country'];
    this.created_at = new Date(obj['created_at']);
    this.created_by = obj['created_by'];
    this.domain = obj['domain'];
    this.icon_path = obj['icon_path'] ? obj['icon_path'] : Constants.BUSINESS_TMP_LOGO;
    this.industries = obj['industries'];
    this.is_requests_confirmation_mandatory = obj['is_requests_confirmation_mandatory'];
    this.name = obj['name'];
    this.num_employees = obj['num_employees'];
    this.revenue = obj['revenue'];
    this.settings = new BusinessSettingsModel(obj['settings']);
    this.updated_at = new Date(obj['updated_at']);
    this.updated_by = obj['updated_by'];
  }

  updateSettings(businessSettingsData) {
    this.settings = new BusinessSettingsModel(businessSettingsData);
  }

  setBanks(banksData) {
    this.banks= banksData.banks.map(bank => new BusinessBankModel(bank));
  }
}
