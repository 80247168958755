import { BusinessBankModel } from "@/store/models/BusinessBankModel";
import { UserModel } from "@/store/models/UserModel";
import { RequestPriorityModel } from "@/store/models/request/RequestPriorityModel";
import { RequestStatusModel } from "@/store/models/request/RequestStatusModel";
import { PermissionModel } from "@/store/models/base/PermissionModel";
import { DataService } from "@/core/services/DataService";
import { BaseModel } from "@/store/models/base/BaseModel";
import { BusinessModelMin } from "@/store/models/BusinessModelMin";
import { RequestIdeaCommentContainerModel } from "@/store/models/request/RequestIdeaCommentContainerModel";

export class BaseRequestIdeaModel extends BaseModel{
  bank: BusinessBankModel;
  closed_at: Date;
  created_at: Date;
  creator: UserModel;
  description: String;
  opened_at: Date;
  priority: RequestPriorityModel;
  product_areas: String[];
  status: RequestStatusModel;
  title: String;
  permissions: PermissionModel;
  comments_count: number;
  is_internal: boolean;
  creatorBusiness: BusinessModelMin;
  commentsContainer: RequestIdeaCommentContainerModel;

  constructor(obj: any) {
    super(obj);

    this.bank = new BusinessBankModel(obj['bank']);
    this.closed_at = new Date(obj['closed_at']);
    this.created_at = new Date(obj['created_at']);
    this.creator = new UserModel(obj['creator']);
    this.description = obj['description'];
    this.opened_at = new Date(obj['opened_at']);
    this.priority = new RequestPriorityModel(obj['priority']);
    this.product_areas = obj['product_areas'];
    this.status = new RequestStatusModel(obj['status']);
    this.title = obj['title'];
    this.permissions = this.definePermissions();
    this.comments_count = obj['comments_count'] ? obj['comments_count'] : 0;
    this.is_internal = obj['is_internal'];
    this.creatorBusiness = new BusinessModelMin({id: obj.creator.business_id, icon_path: obj.creator.business_icon_path, name: obj.creator.business_name});
    this.commentsContainer = new RequestIdeaCommentContainerModel({comments: obj.comments ? obj.comments : [] });
  }

  definePermissions(forWho: UserModel | null = null): PermissionModel {
    const user = forWho || this.authUser();

    return new PermissionModel({
      can_open: user!.isAdmin || user!.id === this.creator.id,
      can_close: user!.isAdmin || user!.id === this.creator.id,
      can_edit: user!.id === this.creator.id,
      can_delete: false
    });
  }

  protected authUser(){
    return DataService.getAuthUserData()
  }

  detectCommentAction(type: string): void {
    switch (type) {
      case 'add':
        this.comments_count ++;
        break;
      case 'delete':
        if (this.comments_count > 0) this.comments_count --;
        break;
    }
  }
 }
