import { BusinessFilterService } from "@/core/services/BusinessFilterService";
import { DataService } from "@/core/services/DataService";
import { BankFilterService } from "@/core/services/BankFilterService";

export const ERROR_TYPES = {
  INVALID_USER_DATA: 'invalid_user_data',
  INVALID_BUSINESS_DATA: 'invalid_business_data',
  INVALID_BANK_DATA: 'invalid_bank_data',
  USER_NOT_FOUND: "user_not_found",
  INVALID_DATA: "invalid_data",
  INVALID_FILTERS: "invalid_filters"
};

export class ErrorHandlerService {

  static handleError = (errorType: string): void => {
    // console.log("handleError ====> " + errorType + " <=====  ");
    switch (errorType) {
      case ERROR_TYPES.INVALID_DATA:
        BusinessFilterService.removeAllFilters();
        BankFilterService.removeAllFilters();
        DataService.removeAllData();
        break;
      case ERROR_TYPES.USER_NOT_FOUND:
        BusinessFilterService.removeAllFilters();
        BankFilterService.removeAllFilters();
        DataService.removeAllData();
        break;
      case ERROR_TYPES.INVALID_USER_DATA:
        BusinessFilterService.removeAllFilters();
        BankFilterService.removeAllFilters();
        DataService.removeAllData();
        break;
    }
  };

}
