import { BaseModel } from "@/store/models/base/BaseModel";
import { Constants } from "@/core/config/constants";

export class BusinessBankModel extends BaseModel{
  name: string;
  countries: string[];
  icon_path: string;

  constructor(obj: any) {
    super(obj);

    this.name = obj['name'];
    this.countries = Array.isArray(obj['countries']) ? obj['countries'] : [obj['country']];
    this.icon_path = obj['icon_path'] ? obj['icon_path'] : Constants.BANK_TMP_LOGO
  }

  addCountries(countries: []) {
    countries.forEach((ct: string) => {
      if (this.countries && this.countries.length && !this.countries.includes(ct)) this.countries.push(ct);
    })
  }
}
