import { BaseModel } from "@/store/models/base/BaseModel";
import { Constants } from "@/core/config/constants";
import { BankCustomerModel } from "@/store/models/bank/BankCustomerModel";
import { BankSettingsModel } from "@/store/models/bank/BankSettingsModel";

export class BankModel extends BaseModel {
  name: string;
  icon_path: string;
  domain: string;
  is_customer: boolean;
  product_areas: string[];
  created_at: Date;
  updated_at: Date;
  customers: BankCustomerModel[];
  settings: BankSettingsModel;

  constructor(obj: any) {
    super(obj);

    this.name = obj['name'];
    this.icon_path = obj['icon_path'] ? obj['icon_path'] : Constants.BANK_TMP_LOGO;
    this.domain = obj['domain'];
    this.is_customer = obj['is_customer'];
    this.product_areas = [];
    this.created_at = new Date(obj['created_at']);
    this.updated_at = new Date(obj['updated_at']);
    this.customers = [];
    this.settings = new BankSettingsModel(obj['settings']);
  }

  updateSettings(bankSettingsData) {
    this.settings = new BankSettingsModel(bankSettingsData);
  }

  updateProductAreas(data) {
    if (data.product_areas_stats && data.product_areas_stats.length) {
      this.product_areas = data.product_areas_stats.map(pa => pa.product_area)
    }
  }

  updateProfileData(name, image) {
    this.name = name;
    this.icon_path = image ? image : Constants.BANK_TMP_LOGO;
  }

  adCustomers(customerData) {
    this.customers = [];
    if (Array.isArray(customerData)) {
      customerData.forEach(ct => {
        this.customers.push(new BankCustomerModel(ct));
      })
    } else {
      this.customers.push(new BankCustomerModel(customerData));
    }
  }

  getCustomersSortedBy(what: string) {
    // what = [filter options: 'high', 'low'*]
    switch (what) {
      case 'high':
        return this.customers
      case 'low':
        return this.customers
      default:
        return this.customers
    }
  }

  addRemoveProductArea(productArea: string) {
    let prs = new Set(this.product_areas);
    if (prs.has(productArea)) {
      prs.delete(productArea)
    } else {
      prs.add(productArea);
    }
    this.product_areas = Array.from(prs.values());
  }

  removeProductArea(productArea: string) {
    let prs = new Set(this.product_areas);
    if (prs.has(productArea)) prs.delete(productArea);
    this.product_areas = Array.from(prs.values());
  }
}
