import { Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { SHARED_API_USER } from "@/router/SharedApi";
import NotificationService from "@/buying-teams/services/NotificationService";

@Module
export default class UserProfileModule extends VuexModule {

  @Action
  async verifyUserNewEmail(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(SHARED_API_USER.VERIFY_NEW_EMAIL, data)
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject();
        })
    })
  }
}
