const BUSINESS_API = {
    GET_DASHBOARD_DATA: "/business/dashboard",
    UPDATE_BUSINESS_DATA: "/business/update",
    BUSINESS_STATS: "/business/stats",
    BUSINESS_INITIAL_DATA: "/business/user_info",
    BUSINESS_BANKS_DATA: "/business/dashboard/sub_menus",
    BUSINESS_SURVEY_QUESTIONS: "/business/questions",
    SPECIFIC_BANK_PAGE: "/business/dashboard/sub_menus/banks",
    SPECIFIC_COUNTRY_PAGE: "/business/dashboard/sub_menus/countries",
    SPECIFIC_PRODUCT_AREA_PAGE: "/business/dashboard/sub_menus/product_areas",
    SPECIFIC_QUESTION_PAGE: "/business/question",
    SPECIFIC_QUESTION_COMMENTS: "/business/users/stats"
};

const PROFILE_API = {
    UPDATE_PROFILE: "/business/users/update",
    DEACTIVATE_ACCOUNT: "/auth/delete_user",
    PROFILE_STATS: "/business/user/stats"
};

const SURVEY_API = {
    SAVE_DRAFT: "/surveys/create_draft",
    QUESTIONS_TOGGLE: "/surveys/questions/toggle",
    ADD_CUSTOM_QUESTIONS: "/surveys/questions/custom/add",
    DELETE_CUSTOM_QUESTIONS: "/surveys/questions/custom/delete",
    UPDATE_CUSTOM_QUESTIONS: "/surveys/questions/custom/update",
    CREATE_FEEDBACK: "/surveys/submit",
    // GET_FEEDBACKS_LIST: '/feedback/intents/list',
    GET_FEEDBACKS_LIST: "/surveys/list",
    GET_SURVEY_BY_ID: "/surveys",
    GET_SURVEY_QUESTIONS: "/surveys/questions",
    GET_SURVEY_QUESTION_BY_ID: "/surveys/questions",
    SAVE_REPLY_SURVEY: "/surveys/reply",
    SKIP_QUESTION: "/surveys/skip",
    GET_SURVEY_STATS: "/surveys/questions/stats",
    GET_SURVEY_QUESTION_DETAILS: "/surveys/questions/details",
    GET_SURVEY_GET_NO_AUTH: "/surveys/get_no_auth",
    SAVE_SURVEY_NO_AUTH: "/business/update_no_auth",
    SAVE_REPLY_SURVEY_NO_AUTH: "/surveys/reply_no_auth",
    SKIP_QUESTION_NO_AUTH: "/surveys/skip_no_auth",
    GET_SURVEY_STATS_NO_AUTH: "/surveys/stats_no_auth",
    GET_SURVEY_STATS_AUTH: "/surveys/stats",
    GET_SURVEYS_BASED_ON_QUESTION: "/surveys/stats",
    GET_SUPER_ADMIN_SURVEYS: "/super_admin/surveys",
};

const REQUESTS_API = {
    CREATE_REQUEST: "/feedback/intents/create",
    UPDATE_REQUEST: "/feedback/intents/update",
    GET_REQUESTS: "/feedback/intents/list",
    TOGGLE_REQUEST_STATUS: "/feedback/intents/update",
    GET_REQUEST_BY_ID: "/feedback/intents/details"
};
const IDEAS_API = {
    CREATE_IDEA: "/feedback/intents/create",
    GET_IDEAS: "/feedback/intents/list",
    UPDATE_IDEA: "/feedback/intents/update",
    TOGGLE_IDEA_STATUS: "/feedback/intents/update",
    GET_IDEA_BY_ID: "/feedback/intents/details"
};

const COMMENTS_API = {
    SAVE_COMMENT: "/feedback/intents/comments/add",
    GET_COMMENTS: "/feedback/intents/comments/list",
    UPDATE_COMMENT: "/feedback/intents/comments/update",
    DELETE_COMMENT: "/feedback/intents/comments/delete"
};

const API_TEAMS = {
    INVITE_USER: "/business/teams/add",
    GET_TEAM_LIST: "/business/teams/list",
    GET_TEAM_DETAILS: "/business/teams/details",
    DELETE_USER: "/business/teams/delete/",
    UNBLOCK_USER: "/business/teams/unblock",
    BLOCK_USER: "/business/teams/block",
    UPDATE_USER_DETAILS: "/business/teams/update",
    GET_TEAM_STANDARD_USERS: "/business/teams/get",
};

const REPORTS_API = {
    GENERATE_REPORTS: "/business/generate_reports"
};

const NOTIFICATIONS_API = {
    GET_USER_SETTINGS: "/settings/user/get",
    UPDATE_USER_SETTINGS: "settings/user/update",
    GET_BUSINESS_SETTINGS: "settings/business/get",
    UPDATE_BUSINESS_SETTINGS: "settings/business/update"
};

const COMPARE_API = {
    GET_PRODUCT_AREA_QUESTIONS: "/surveys/questions",
    GET_QUESTION_STATS: "/surveys/questions/data"
};

export {
    BUSINESS_API,
    SURVEY_API,
    REQUESTS_API,
    IDEAS_API,
    API_TEAMS,
    PROFILE_API,
    REPORTS_API,
    COMMENTS_API,
    NOTIFICATIONS_API,
    COMPARE_API
};
