 export class BreadcrumbModel {
   title: string | null;
   subTitle: string | null;
   counter: number | string | null;
   logo: string | null;

   constructor(obj: any) {
     this.title = obj['title'] || null;
     this.subTitle = obj['subTitle'] || null;
     this.counter = obj['counter'] || null;
     this.logo = obj['logo'] || null;
   }
 }
