import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import BusinessRoutes from "@/router/routes/BusinessRoutes";
import BankRoutes from "@/router/routes/BankRoutes";
import AuthRoutes from "@/router/routes/AuthRoutes";
import RegisterRoutes from "@/router/routes/RegisterRoutes";
import superAdminRoutes from "@/router/routes/SuperAdminRoutes";
import { RouteGuards } from "@/router/RouteGuards";
import { DataService } from "@/core/services/DataService";
import JwtService from "@/core/services/JwtService";
import "@/core/firebase/index.ts"
import {analytics} from "@/core/firebase";
import { logEvent } from "firebase/analytics";
import { useGtag } from "vue-gtag-next";
import bankRegisterRoutes from "@/router/routes/BankRegisterRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: '/dashboard',
    component: () => import("@/buying-teams/layouts/Layout.vue"),
    children: []
  },
  {
    path: '/dashboard',
    name: 'define',
    component: () => import("@/buying-teams/layouts/Define.vue"),
  },
  {
    path: "/",
    component: () => import("@/buying-teams/layouts/Auth.vue"),
    children: AuthRoutes
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import("@/buying-teams/layouts/Registration.vue"),
    children: RegisterRoutes
  },
  {
    path: '/bank-register',
    name: 'bank-register',
    component: () =>
      import("@/buying-teams/layouts/BankRegistration.vue"),
    children: bankRegisterRoutes
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/buying-teams/shared-components/error-pages/Error404.vue"),
  },
  {
    path: '/feedback-reply',
    name: 'feedback-reply',
    component: () => import('@/buying-teams/pages/business/feedbacks/reply-without-account/pages/ReplyPage.vue')
  },
  {
    path: '/feedback-reply-sg',
    name: 'feedback-reply-sg',
    component: () => import('@/buying-teams/pages/business/feedbacks/reply-without-account/pages/SocGenScreen.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const userData = DataService.getAuthUserData()
if (userData && userData!.is_super_admin) {
  routes[0].children?.push({
    path: '/super-admin',
    redirect: '/super-admin/dashboard',
    component: () => import("@/buying-teams/layouts/SuperAdminLayout.vue"),
    children: superAdminRoutes
  })
} else {
  if (userData && userData!.is_bank_user) {
    routes[0].children?.push({
      path: '/bank',
      redirect: '/bank/dashboard',
      component: () => import("@/buying-teams/layouts/BankLayout.vue"),
      children: BankRoutes
    })
  } else if (userData && !userData!.is_bank_user) {
    routes[0].children?.push({
      path: '/business',
      redirect: '/business/dashboard',
      component: () => import("@/buying-teams/layouts/BusinessLayout.vue"),
      children: BusinessRoutes
    })
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const routeGuards: any = to.meta && to.meta.guards ? to.meta.guards : []

  logEvent(analytics, 'page_view', {
    page_path: to.path
  })

  useGtag().pageview({ page_path: to.path })

  if (routeGuards.includes(RouteGuards.AUTH)) {
    if (!!JwtService.getToken() && DataService.getAuthUserData()) {
      next();
    } else {
      next({name: 'sign-in'});
    }
  }
  else if (routeGuards.includes(RouteGuards.ADMIN)){
    const user = DataService.getAuthUserData();
    if (!user || !user.isAdmin) {
      // TODO change to 403
      next({name: '404'})
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
