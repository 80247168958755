export const english = {
  dashboard: "Dashboard",
  feedback: "Feedback",
  feedbackSessions: "Feedback sessions",
  compareBanks: "Compare Banks",
  requests: "Requests",
  ideas: "Ideas",
  loginRegister: "Login/Register",
  email: "Email",
  continue: "Continue",
  password: "Password",
  pleaseWait: "Please wait...",
  recoverPassword: "Recover Password",
  loginToContinue: "Login to Continue",
  hi: "Hi",
  notifications: "Notifications",
  Filters: "Filters",
  Banks: "Banks",
  Countries: "Countries",
  Apply: "Apply",
  Score: "Score",
  Importance: "Importance",
  Source: "Source",
  Pending: "Pending",
  New: "New",
  "Time Period": "Time Period",
  "Reply Now": "Reply Now",
  "View All": "View All",
  "Manage Product Areas": "Manage Product Areas",
  logIn: "Log In",
  register: "Register",
  atLeastOneUppercaseLetter: "At least one uppercase letter, one number",
  createPassword: "Create Password",
  confirmPassword: "Confirm Password",
  yourMailDomainIsAlreadyRegistered: "Your mail domain is already registered to a company with an account in CXFacts. Please request access",
  verifyToContinue: "Verify to Continue",
  pleaseTypeTheVerificationCode: "Please type the verification code sent to",
  notReceived: "Not Received?",
  resendCode: "Resend Code",
  verify: "Verify",
  success: "Success",
  somethingWentWrong: "Something Went Wrong",
  welcomeBack: "Welcome Back!",
  codeHasBeenSent: "Code has been sent",
  "Close Request": "Close Request",
  "Close Idea": "Close Idea",
  "ID": "ID",
  "Search requests...": "Search requests...",
  "critical": "Critical",
  "Date": "Date",
  "Low": "Low",
  "Normal": "Normal",
  "High": "High",
  "Critical": "Critical",
  "Priority": "Priority",
  "Select Banks": "Select Banks",
  "Sort by who created the request": "Sort by who created the request",
  "Edit Request": "Edit Request",
  "Apply Filters": "Apply Filters",
  "Country of the Bank": "Country of the Bank",
  "Product Area": "Product Area",
  "Get started": "Get started",
  "By entering your company details": "By entering your company details",
  "Existing User ?": "Existing User ?",
  "Log in": "Log in",
  "Group Company Name": "Group Company Name",
  "Industry": "Industry",
  "Country of Global Headoffice": "Country of Global Headoffice",
  "Number of Employees": "Number of Employees",
  "Global revenue": "Global revenue",
  "Country of Global headquarter": "Country of Global headquarter",
  "Terms & Conditions": "Terms & Conditions",
  "Privacy Policy": "Privacy Policy",
  "Select Industry": "Select Industry",
  "Select Global revenue": "Select Global revenue",
  "For eg. `Denmark`": "For eg. `Denmark`",
  "Added": "Added",
  "Company": "Company",
  "Continue": "Continue",
  "Selected": "Selected",
  "Search banks": "Search banks",
  "Save": "Save",
  "Title": "Title",
  "Request": "Request",
  "Cancel": "Cancel",
  "Ideas": "Ideas",
  "Share": "Share",
  "Internally": "Internally",
  "Post Comment": "Post Comment",
  "Leave your comment here...": "Leave your comment here...",
  "Select all": "Select all",
  "Job Title": "Job Title",
  "Country of Residence": "Country of Residence",
  "Product Areas": "Product Areas",
  "Last Name": "Last Name",
  "First Name": "First Name",
  "Add Bank": "Add Bank",
  "Choose which banks, and which countries of the banks you work with": "Choose which banks, and which countries of the banks you work with",
  "Choose your Banks": "Choose your Banks",
  "This helps in creating your personalised profile": "This helps in creating your personalised profile",
  "Let us get to know you": "Let us get to know you",
  "Bank Name": "Bank Name",
  "Bank Country": "Bank Country",
  "Enter your Company Name (Group level)": "Enter your Company Name (Group level)",
  "Select Number of Employees": "Select Number of Employees",
  "All Banks": "All Banks",
  "All Countries": "All Countries",
  "Comments": "Comments",
  "Search ideas...": "Search ideas...",
  "Create Idea": "Create Idea",
  "Enter Idea Description": "Enter Idea Description",
  "Name": "Name",
  "Select Country": "Select Country",
  "Questions": "Questions",
  "Respondents": "Respondents",
  "Delivery": "Delivery",
  "Phase": "Phase",
  "Live": "Live",
  "of": "of",
  "Step": "Step",
  "Now": "Now",
  "Later": "Later",
  "standard": "standard",
  "Please input Product Area": "Please input Product Area",
  "Please select Countries": "Please select Countries",
  "Please select Bank": "Please select Bank",
  "Please select Predefined Questions": "Please select Predefined Questions",
  "Please select Phases": "Please select Phases",
  "Back": "Back",
  "Admins": "Admins",
  "Users": "Users",
  "Company Activity": "Company Activity",
  "Company Details": "Company Details",
  "Define your company sharing policy": "Define your company sharing policy",
  "Choose the message you’d like to receive": "Choose the message you’d like to receive",
  "See Profile": "See Profile",
  "Add User": "Add User",
  "Close the Company Account": "Close the Company Account",
  "CRITICAL": "CRITICAL",
  "OPEN": "OPEN",
  "HIGH": "HIGH",
  "NORMAL": "NORMAL",
  "LOW": "LOW",
  "To avoid unintentional delete, this requires you to confirm your Email & Password before deletion.": "To avoid unintentional delete, this requires you to confirm your Email & Password before deletion.",
  "YOU ARE CLOSING YOUR COMPANY ACCOUNT": "YOU ARE CLOSING YOUR COMPANY ACCOUNT",
  "Change Password": "Change Password",
  "Select revenue range": "Select revenue range",
  "Discard": "Discard",
  "Save Changes": "Save Changes",
  "Edit Company": "Edit Company",
  "Overview": "Overview",
  "Edit": "Edit",
  "Verified": "Verified",
  "Profile Details": "Profile Details",
  "Logo": "Logo",
  "Add Product Areas": "Add Product Areas",
  "Edit Profile": "Edit Profile",
  "Select Product Area": "Select Product Area",
  "Company Name": "Company Name",
  "Global Headquarters": "Global Headquarters",
  "Close Company Account": "Close Company Account",
  "Sign - in Method": "Sign - in Method",
  "Remove From List": "Remove From List",
  "Settings": "Settings",
  "Notifications": "Notifications",
  "Active Banks": "Active Banks",
  "Sharing Policy": "Sharing Policy",
  "Registered Banks": "Registered Banks",
  "User Management": "User Management",
  "View Privacy Policy": "View Privacy Policy",
  "GDPR Export": "GDPR Export",
  "Secure your account": "Secure your account",
  "How to Videos / Guides": "How to Videos / Guides",
  "Contact Support": "Contact Support",
  "Average Gap": "Average Gap",
  "Average Importance": "Average Importance",
  "Average Score": "Average Score",
  "Add Question": "Add Question",
  "You can add your own personalized questions in the Session.": "You can add your own personalized questions in the Session.",
  "Custom Questions": "Custom Questions",
  "Choose the questions from the selected questionnaires.": "Choose the questions from the selected questionnaires.",
  "Set up the Questions": "Set up the Questions",
  "Enter Session Description": "Enter Session Description",
  "All Product Areas": "All Product Areas",
  "Detailed 5-7 Mins": "Detailed 5-7 Mins",
  "Quick 2-3 Mins": "Quick 2-3 Mins",
  "Select a Phase(s):": "Select a Phase(s):",
  "Select Date & Time": "Select Date & Time",
  "Schedule for": "Schedule for",
  "Idea Name": "Idea Name",
  "Completed": "Completed",
  "Create Feedback": "Create Feedback",
  "Search feedback...": "Search feedback...",
  "No comments": "No comments",
  "Global Revenue": "Global Revenue",
  "Password": "Password",
  "Country": "Country",
  "Your Activity": "Your Activity",
  "Your Country of residence": "Your Country of residence",
  "Input your Lat Name": "Input your Lat Name",
  "Input your First Name": "Input your First Name",
  "Full Name": "Full Name",
  "Change Email": "Change Email",
  "Password must be at least 8 character and contain symbols": "Password must be at least 8 character and contain symbols",
  "Delete Account": "Delete Account",
  "Email Address": "Email Address",
  "Please wait...": "Please wait...",
  "Select relevant range": "Select relevant range",
  "Select your Industry": "Select your Industry",
  "Input your Company Name": "Input your Company Name",
  "Back to Sessions": "Back to Sessions",
  "Create Request": "Create Request",
  "Please input title": "Please input title",
  "Please input description": "Please input description",
  "Invitations": "Invitations",
  "pi_share_cnt": "Allow users to share country of residence with bank",
  "pi_share_jt": "Allow users to share job title with bank",
  "pi_share_fullname": "Allow users to share full name with bank",
  "pi_share_pp": "Allow users to share profile picture with bank",
  "Personal Information": "Personal Information",
  "Allow users to share comments from feedback sessions with bank": "Allow users to share comments from feedback sessions with bank",
  "fa_share_ideas": "Allow users to share ideas with bank",
  "fa_share_comm": "Allow users to share comments  from feedback sessions with bank",
  "fa_share_req": "Allow users to share requests with bank",
  "fa_st_feed": "Allow users to start feedback sessions",
  "Function Access": "Function Access",
  "inv_others": "Allow users to invite other colleagues to CXFacts",
  "Email": "Email",
  "Define Access": "Define Access",
  "Customer Success Manager": "Customer Success Manager",
  "Select Product Areas": "Select Product Areas",
  "Select Product": "Select Product",
  "Filter": "Filter",
  "User will get verification link to this email id.": "User will get verification link to this email id.",
  "Avatar": "Avatar",
  "Include Ideas in Report": "Include Ideas in Report",
  "Include Requests in Report": "Include Requests in Report",
  "Generate Report": "Generate Report",
  "View": "View",
  "Add More": "Add More",
  "Add New Bank": "Add New Bank",
  "Add New User": "Add New User",
  "Country of residence": "Country of residence",
  "Total Members": "Total Members",
  "Invite Colleague": "Invite Colleague",
  "request Name": "Request Name",
  "request Description": "Request Description",
  "idea Name": "Idea Name",
  "idea Description": "Idea Description",
  "Enter request Description": "Enter Request Description",
  "Enter idea Description": "Enter Idea Description",
  "With Bank": "With Bank",
  "Please Select Priority": "Please Select Priority",
  "Please Select Bank": "Please Select Bank",
  "Please Select Country": "Please Select Country",
  "Please input Title": "Please input Title",
  "Skip": "Skip",
  "Deselect all": "Deselect all",
  "Admin": "Admin",
  "Standard": "Standard",
  "User": "User",
  "Description": "Description",
  "Total Number of potential respondents": "Total Number of potential respondents",
  "Total Number of Banks": "Total Number of Banks",
  "Selected Banks": "Selected Banks",
  "countries selected": "countries selected",
  "Expiration Date": "Expiration Date",
  "Launch this Session": "Launch this Session",
  "Characters": "Characters",
  "Feedback session is active for:": "Feedback session is active for:",
  "Select Deadline": "Select Deadline",
  "You can select a repeat frequency": "You can select a repeat frequency",
  "No Data": "No Data",
  "Show closed requests": "Show closed requests",
  "Show closed ideas": "Show closed ideas",
  "Select Countries": "Select Countries",
  "Loading": "Loading",
  "Info Center": "Info Center",
  "completed": "completed",
  "Internal": "Internal",
  "See Results": "See Results",
  "By using PLATFORMNAME platform, you accept our": "By using {platformName} platform, you accept our",
  "Upcoming": "Upcoming",
  "Expired": "Expired",
  "Success": "Success",
  "Search Country": "Search Country",
  "Select Country you want reports on": "Select Country you want reports on",
  "Search Product Areas": "Search Product Areas",
  "Select Countries you want reports on": "Select Countries you want reports on",
  "Select Product Areas you want reports on": "Select Product Areas you want reports on",
  "Select Bank": "Select Bank",
  "Expiring soon": "Expiring soon",
  "Go to Dashboard": "Go to Dashboard",
  "Update": "Update",
  "Role": "Role",
  "questions replied": "questions replied",
  "“Questions replied monthly” by": "“Questions replied monthly” by",
  "My Profile": "My Profile",
  "Questions Replied": "Questions Replied",
  "Requests & Ideas": "Requests & Ideas",
  "Active Product Areas": "Active Product Areas",
  "Changing Email Address": "Changing Email Address",
  "Enter your new Email Address. We recommend using work mails.": "Enter your new Email Address. We recommend using work mails.",
  "New Email Address": "New Email Address",
  "Confirm  Password": "Confirm  Password",
  "Enter New Email Address": "Enter New Email Address",
  "Enter your current Password": "Enter your current Password",
  "Update Email": "Update Email",
  "Changing Password": "Changing Password",
  "Use strong passwords to avoid any security issues.": "Use strong passwords to avoid any security issues.",
  "Current Password": "Current Password",
  "New Password": "New Password",
  "Confirm New Password": "Confirm New Password",
  "Type in your Old Password": "Type in your Old Password",
  "Create New Password": "Create New Password",
  "Update Password": "Update Password",
  "Total Score": "Total Score",
  "Search Banks": "Search Banks",
  "Comment as": "Comment as",
  "Edit Comment": "Edit Comment",
  "Delete Comment": "Delete Comment",
  "Enter your Country Headquarter": "Enter your Country Headquarter",
  "EUR": "EUR",
  "Delete my account": "Delete my account",
  "Close": "Close",
  "Delete": "Delete",
  "Confirm deleting your account": "Confirm deleting your account",
  "Deleting Profile": "Deleting Profile",
  "Confirm deleting this company account, this action cannot be undone": "Confirm deleting this company account, this action cannot be undone",
  "Deleting Business": "Deleting Business",
  "Remember me": "Remember me",
  "Open Request": "Open Request",
  "Please input Country Name": "Please input Country Name",
  "Open Idea": "Open Idea",
  "Block": "Block",
  "Unblock": "Unblock",
  "Please select Country": "Please select Country",
  "Users List": "Users List",
  "Admins list": "Admins list",
  "Edit profile": "Edit profile",
  "View My Profile": "View My Profile",
  "View Profile": "View Profile",
  "Promote to admin": "Promote to admin",
  "OK": "OK",
  "Expires in Days": "Expires in {day} Days",
  "role.admin": "Admin",
  "role.standard": "Standard",
  "Deleting Bank": "Deleting Bank",
  "Are you sure, you want to delete this bank": "Are you sure, you want to delete this bank",
  "Recover Password": "Recover Password",
  "Send": "Send",
  "If an account is associated with the entered email, you will shortly receive an email to reset your password.": "If an account is associated with the entered email, you will shortly receive an email to reset your password.",
  "Not Received?": "Not Received?",
  "Resend Link": "Resend Link",
  "Product Area Overview": "Product Area Overview",
  "Customers": "Customers",
  "View Customers List": "View Customers List",
  "Picture": "Picture",
  "Coming Soon": "Coming Soon",
  "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking 'Accept All', you consent to the use of ALL the cookies.": "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept All”, you consent to the use of ALL the cookies.",
  "However, you may visit 'Cookie Settings' to provide a controlled consent or": "However, you may visit \"Cookie Settings\" to provide a controlled consent or",
  "Read More": "Read More",
  "Accept All": "Accept All",
  "No market reports yet...": "No market reports yet...",
  "You have not received any market reports yet. We will let you know once they arrive!": "You have not received any market reports yet. We will let you know once they arrive!",
  "Market reports is based on your bank(s) and product area(s) selection.": "“Market reports is based on your bank(s) and product area(s) selection.”",
  "Generate Custom Report": "Generate Custom Report",
  "FAQ & Guides": "FAQ & Guides",
  "Followed Countries": "Followed Countries",
  "Followed Product Areas": "Followed Product Areas",
  "Registered Bank": "Registered Bank",
  "Bank's Customers": "Bank’s Customers",
  "New Market Reports": "New Market Reports",
  "new_market_report": "New Market Reports",
  "New Feedback Session": "New Feedback Session",
  "new_feedback_session": "New Feedback Session",
  "New Request": "New Request",
  "new_request": "New Request",
  "New Idea": "New Idea",
  "new_idea": "New Idea",
  "New Comment Notification": "New Comment Notification",
  "new_comment": "New Comment Notification",
  "Search Countries": "Search Countries",
  "There are no active/live feedback sessions": "There are no active/live feedback sessions",
  "Create Feedback ": "Create Feedback ",
  "Add Product Area": "Add Product Area",
  "Use the button below to add your first product area and leverage real-time ratings on them.": "Use the button below to add your first product area and leverage real-time ratings on them.",
  "Requests": "Requests",
  "Shared": "Shared",
  "high": "High",
  "normal": "Normal",
  "low": "Low",
  "Please select bank": "Please select bank",
  "Please select countries": "Please select countries",
  "Bank(s)": "Bank(s)",
  "Country of Global Head Office": "Country of Global Head Office",
  "Select Customer you want reports on": "Select Customer you want reports on",
  "Search Customers": "Search Customers",
  "filters applied": "{n} filters applied",
  "Clear all": "Clear all",
  "Select Companies": "Select Companies",
  "Search Companies": "Search Companies",
  "Bank Details": "Bank Details",
  "Get Started": "Get Started",
  "Follow Product Areas": "Follow Product Areas",
  "Personal Details": "Personal Details",
  "Search user": "Search user",
  "Please select Country of Residence": "Please select Country of Residence",
  "Please select country": "Please select country",
  "Please input First name": "Please input First name",
  "Length should be 1 to 60": "Length should be 1 to 60",
  "Please input Last name": "Please input Last name",
  "Please select Product Area": "Please select Product Area",
  "Please input Job Title": "Please input Job Title",
  "Please select Country of Residence*": "Please select Country of Residence*",
  "Please input bank name": "Please input bank name",
  "Enter your First Name": "Enter your First Name",
  "Enter your Last Name": "Enter your Last Name",
  "Select which Bank Product Areas you work with": "Select which Bank Product Areas you work with",
  "Select your Country of your residence": "Select your Country of your residence",
  "Please input Group Company Name": "Please input Group Company Name",
  "Please input Company Headquarters": "Please input Company Headquarters",
  "Please input Industry": "Please input Industry",
  "Please input Number of Employees": "Please input Number of Employees",
  "Please input Revenue": "Please input Revenue",
  "Please select Industry": "Please select Industry",
  "Please select Number of Employees": "Please select Number of Employees",
  "Please select Global revenue": "Please select Global revenue",
  "Please input Country of Global headquarter": "Please input Country of Global headquarter",
  "Please upload company logo": "Please upload company logo",
  "reports": "Reports",
  marketReports: "Market Report",
  customReports: "Custom Report",
  teams: "Teams",
  "All Users": "All Users",
  "Please select Time Period": "Please select Time Period",
  "Idea": "Idea",
  "Please input Email": "Please input Email",
  "Please input correct email": "Please input correct email",
  "Please select User Type": "Please select User Type",
  "Please select Product Areas": "Please select Product Areas",
  "Please select Country of residence": "Please select Country of residence",
  "Please input First Name": "Please input First Name",
  "Please input Last Name": "Please input Last Name",
  "Edit Idea": "Edit Idea",
  "File size exceeds maximum limit": "File size exceeds maximum limit {limit} MB",
  "You have uploaded an invalid image file type": "You have uploaded an invalid image file type",
  "Select creators": "Select creators",
  "Search creators": "Search creators",
  "Closing": "Closing",
  "Opening": "Opening",
  "Password is a required field": "Password is a required field",
  "Confirm Password is a required field": "Confirm Password is a required field",
  "Passwords must match": "Passwords must match",
  "Select": "Select",
  "Search": "Search",
  "For extra security you are requested to change your password.": "For extra security you are requested to change your password.",
  "Select Customers": "Select Customers",
  "All Customers": "All Customers",
  "Select Tier": "Select Tier",
  "All": "All",
  "Tier num": "Tier {num}",
  "Please Input": "Please Input",
  "Please input Job title": "Please input Job title",
  "Score or review your Banks' performance": "Score or review your Banks' performance",
  "Requests for your Banks (action, change, other)": "Requests for your Banks (action, change, other)",
  "Ideas for your Banks (improvements, developments, other)": "Ideas for your Banks (improvements, developments, other)",
  "A subtext line to explain what the title means": "A subtext line to explain what the title means",
  "Please define the content of your Report": "Please define the content of your Report",
  "Market Reports": "Market Reports",
  "Custom Reports": "Custom Reports",
  "Feedback Sessions": "Feedback Sessions",
  "New Feedback session": "New Feedback session",
  "Define the settings of Bank Users": "Define the settings of Bank Users",
  "Delete inactive users after": "Delete inactive users after",
  "number months": "{n} months",
  "Contact PLATFORMNAME": "Contact {platformName}",
  "to add more Product Areas": "to add more Product Areas",
  "GDPR export": "GDPR export",
  "View privacy policy": "View privacy policy",
  "Countries of the Bank": "Countries of the Bank",
  "Number of Users": "Number of Users",
  "Allow users to invite customers to CXFacts sessions": "Allow users to invite customers to CXFacts sessions",
  "Allow users to start Feedback Sessions": "Allow users to start Feedback Sessions",
  "Allow users to reply to requests": "Allow users to reply to requests",
  "Allow users to reply to ideas": "Allow users to reply to ideas",
  "Following": "Following",
  "Follow Product Area": "Follow Product Area",
  "Sign Out": "Sign Out",
  "Global Number of Employees": "Global Number of Employees",
  "Arriving Soon...": "Arriving Soon...",
  "We are now generating your report. Please wait a few minutes.": "We are now generating your report. Please wait a few minutes.",
  "Generated Report will be sent on": "Generated Report will be sent on",
  "as a link once done.": "as a link once done.",
  "Hide": "Hide",
  "Bank Score": "Bank Score",
  "Top": "Top",
  "Compare Product Areas": "Compare Product Areas",
  "Number of Customers": "Number of Customers",
  "GAP ANALYSIS": "GAP ANALYSIS",
  "Scores": "Scores",
  "View Details": "View Details",
  "Gap": "Gap",
  "Question": "Question",
  "No Questions yet": "No Questions yet",
  "Follow Customers": "Follow Customers",
  "No Customer Added": "No Customer Added",
  "You are yet to add your first customer.": "You are yet to add your first customer.",
  "Add users using the “Invite Customer” in your customer list": "Add users using the “Invite Customer” in your customer list",
  "No Requests yet": "No Requests yet",
  "There are no registered requests yet.": "There are no registered requests yet.",
  "There are no registered ideas yet.": "There are no registered ideas yet.",
  "No Ideas yet": "No Ideas yet",
  "Followed": "Followed",
  "Select Bank you want to filter on": "Select Bank you want to filter on",
  "Select Country you want to filter on": "Select Country you want to filter on",
  "Add Product Area to Compare": "Add Product Area to Compare",
  "View All Customers": "View All Customers",
  "View All Requests": "View All Requests",
  "Sort Customers By": "Sort Customers By",
  "View All Ideas": "View All Ideas",
  "Confirm deleting this comment, this action cannot be undone": "Confirm deleting this comment, this action cannot be undone",
  "No comments yet": "No comments yet",
  "Score the bank, product area and phase. Add comments for additional inputs. Your opinion matters.": "Score the bank, product area and phase. Add comments for additional inputs. Your opinion matters.",
  "Create your own feedback sessions": "Create your own feedback sessions",
  "Change to user": "Change to user",
  "New Users": "New Users",
  "new_users": "New Users",
  "New Banks": "New Banks",
  "new_banks": "New Banks",
  "System Updates": "System Updates",
  "sys_updates": "System Updates",
  "Newsletters": "Newsletters",
  "newsletters": "Newsletters",
  "GDPR Exports": "GDPR Exports",
  "gdpr": "GDPR Exports",
  "Bank Settings": "Bank Settings",
  "Change to User": "Change to User",
  "Delete User": "Delete User",
  "Something Went Wrong": "Something Went Wrong",
  "Your Account Blocked": "Your Account Blocked",
  "Simple. Clean. Effective.": "Simple. Clean. Effective.",
  "CXFacts is quite self-explanatory. let us guide you in 5 easy steps for a seamless experience!": "CXFacts is quite self-explanatory. let us guide you in <b>5 easy steps</b> for a seamless experience!",
  "Let's get started": "Let’s get started",
  "Following through all steps is recommended": "Following through all steps is recommended",
  "Introduction": "Introduction",
  "Skip All": "Skip All",
  "CLICK ON FEEDBACK SESSIONS TO CONTINUE": "CLICK ON FEEDBACK SESSIONS TO CONTINUE",
  "Side Navigation": "Side Navigation",
  "Product Area Scores": "Product Area Scores",
  "Let's create a Feedback Session": "Let’s create a Feedback Session",
  "Almost Done!": "Almost Done!",
  "This is your control panel, jump into any section of your application hassle-free!": "This is your control panel, jump into any section of your application hassle-free!",
  "Filter through Banks, Countries, and Time Period to tailor your dashboard according to your need.": "Filter through Banks, Countries, and Time Period to tailor your dashboard according to your need.",
  "Quick glance to most relevant data tailored for you in this section and quickly reply to live feedback sessions.": "Quick glance to most relevant data tailored for you in this section and quickly reply to live feedback sessions.",
  "Scan through valuable, all-time product area performance on your banking partners. Use tabs on top to switch between stats sequences.": "Scan through valuable, all-time product area performance on your banking partners. Use tabs on top to switch between stats sequences.",
  "This section shows all the Requests and Ideas made to your Banking partners globally. You can view all open requests/ideas to gain a better insight for your next dialogue meeting.": "This section shows all the Requests and Ideas made to your Banking partners globally. You can view all open requests/ideas to gain a better insight for your next dialogue meeting.",
  "It is super easy to create your own feedback session and push it forward. These sessions add huge insights to your knowledge and help in more impactful team discussions.": "It is super easy to create your own feedback session and push it forward. These sessions add huge insights to your knowledge and help in more impactful team discussions.",
  "Click on Create Feedback and follow through the steps to launch your first feedback session!": "Click on Create Feedback and follow through the steps to launch your first feedback session!",
  "View Session": "View Session",
  "Please select Tiers": "Please select Tiers",
  "Segment": "Segment",
  "All Tiers": "All Tiers",
  "In case you cannot change these settings, your company admin has restricted sharing. Please contact your company for assistance": "In case you cannot change these settings, your company admin has restricted sharing. Please contact your company for assistance",
  "user_pi_share_fullname": "Share full name with bank",
  "user_pi_share_jt": "Share job title with bank",
  "user_pi_share_pp": "Share profile picture with bank",
  "user_pi_share_cnt": "Share country of residence with bank",
  "Define your sharing policy": "Define your sharing policy",
  "Invite Customers": "Invite Customers",
  "Tier": "Tier",
  "Industries": "Industries",
  "Follow/Unfollow": "Follow/Unfollow",
  "Customer Tier": "Customer Tier",
  "All Lowest Scored": "All Lowest Scored",
  "High 5 Customers": "High 5 Customers",
  "Low 5 Customers": "Low 5 Customers",
  "All Highest Scored": "All Highest Scored",
  "Search Customer Name": "Search Customer Name",
  "Advanced Search": "Advanced Search",
  "Feedback Countries of the Bank": "Feedback Countries of the Bank",
  "Customer’s Global Headquarters": "Customer’s Global Headquarters",
  "Reply rate": "Reply rate",
  "Product Areas Rated":"Product Areas Rated",
  "Last Feedback Date":"Last Feedback Date",
  "Follow Company":"Follow Company",
  "Feedback Replies":"Feedback Replies",
  "Company Stats":"Company Stats",
  "Active Users":"Active Users",
  "Back to Customer List": "Back to Customer List",
  "Invite new users": "Invite new users",
  "Enter the company name here": "Enter the company name here",
  "Invite New Customer": "Invite New Customer",
  "Sent Invitations": "Sent Invitations",
  "Search Customer": "Search Customer",
  "All invites disappear from this list in 30 days.": "All invites disappear from this list in 30 days.",
  "Sent on": "Sent on",
  "Please select user role": "Please select user role",
  "Select user role": "Select user role",
  "User Role": "User Role",
  "This user was deleted": "This user was deleted",
  "Lowest Score": "Lowest Score",
  "Highest Importance Score": "Highest Importance Score",
  "Lowest Importance Score": "Lowest Importance Score",
  "Highest Negative Gap Score": "Highest Negative Gap Score",
  "Highest Positive Gap Score": "Highest Positive Gap Score",
  "Highest Standard Deviation": "Highest Standard Deviation",
  "Lowest Standard Deviation": "Lowest Standard Deviation",
  "More filters": "More filters",
  "Collapse": "Collapse",
  "Feedback Session Score": "Feedback Session Score",
  "The results are based on the filters applied.": "The results are based on the filters applied.",
  "Replied": "Replied",
  "Invited": "Invited",
  "Negative Gap": "Negative Gap",
  "Positive Gap": "Positive Gap",
  "No customers found": "No customers found",
  "Not Followed": "Not Followed",
  "Customer’s Country of Global Headquarters": "Customer’s Country of Global Headquarters",
  "Follow Customer": "Follow Customer",
  "Guides": "Guides",
  "No comments was made on this question": "No comments was made on this question",
  "You will see the customers who made comments in the list on your left. You can select and see their comments once made.": "You will see the customers who made comments in the list on your left. \n You can select and see their comments once made.",
  "No Comments": "No Comments",
  "Select All": "Select All",
  "No Customers Found": "No Customers Found",
  "Please check your filters": "Please check your filters",
  "It seems we can’t find customers who scored for this question. Usually this happens due to certain filter parameters. Please check and try again.": "It seems we can’t find customers who scored for this question. \n Usually this happens due to certain filter parameters.\n Please check and try again.",
  "Headquarters country": "Headquarters country",
  "All Comments": "All Comments",
  "You can see relevant questions here once you receive feedback": "You can see relevant questions here once you receive feedback",
  "Requests from Customers across all countries and products": "Requests from Customers across all countries and products",
  "Ideas for your Customers (improvements, developments, other)": "Ideas for your Customers (improvements, developments, other)",
  "Search & Filters": "Search & Filters",
  "Highest score": "Highest score",
  "Select Industries": "Select Industries",
  "Select Industries you want to filter on": "Select Industries you want to filter on",
  "Search Industries": "Search Industries",
  "Knowledge Base": "Knowledge Base",
  "Edit Bank": "Edit Bank",
  "Follow Bank Country": "Follow Bank Country",
  "Select Product Areas you want filter on": "Select Product Areas you want filter on",
  "Get valuable insights from your customers": "Get valuable insights from your customers",
  "Normal 3-5 Mins": "Normal 3-5 Mins",
  "How many predefined questions per Product Area?": "How many predefined questions per Product Area?",
  "Quick Info": "Quick Info",
  "Product Areas Included": "Product Areas Included",
  "Countries Included": "Countries Included",
  "No Data Found": "No Data Found",
  "Customers Included": "Customers Included",
  "Product Areas Match": "Product Areas Match",
  "Countries Match": "Countries Match",
  "Country Match": "Country Match",
  "No Matches": "No Matches",
  "Matched & Selected": "Matched & Selected",
  "You need to select a minimum of one": "You need to select a minimum of one {name}",
  "Feedback Session successfully created": "Feedback Session successfully created",
  "Selected Customers": "Selected Customers",
  "Total Number of Customers": "Total Number of Customers",
  "Enter Session Title": "Enter Session Title",
  "Customers included": "Customers included",
  "Help": "Help",
  "Please enter company name": "Please enter company name",
  "Bounced": "Bounced",
  "Accepted": "Accepted",
  "Invitation successfully sent": "Invitation successfully sent",
  "Invitation successfully resent": "Invitation successfully resent",
  "No Customer Invited": "No Customer Invited",
  "Please invite your customers via Email": "Please invite your customers via Email",
  "Feedback Session": "Feedback Session",
  "View All Invited": "View All Invited",
  "Current": "Current",
  "All Invited": "All Invited",
  "All Replied": "All Replied",
  "country and product area": "country and product area",
  "Back to Requests": "Back to Requests",
  "Back to Ideas": "Back to Ideas",
  "View All Replied": "View All Replied",
  "Replied Customers": "Replied Customers",
  "Please input Password": "Please input Password",
  "Please input Confirm Password": "Please input Confirm Password",
  "Passwords not match": "Passwords not match",
  "You can make any changes later as well": "You can make any changes later as well",
  "Search Admins": "Search Admins",
  "See All": "See All",
  "Make Admin": "Make Admin",
  "Follow Country": "Follow Country",
  "Question Score": "Question Score",
  "Development over time": "Development over time",
  "Clear All": "Clear All",
  "Filters Selected": "Filters Selected",
  "Email doesn't match!": "Email doesn't match!",
  "Re-enter new email address to confirm (new@mail.now)": "Re-enter new email address to confirm",
  "Verify new email": "Verify new email",
  "Confirm new email": "Confirm new email",
  "Verify to change email": "Verify to change email",
  "Please type the verification code here:": "Please type the verification code here:",
  "We have sent an email to your new email address i.e": "We have sent an email to your new email address i.e {email}",
  "Resend Code": "Resend Code",
  "Please input Code": "Please input Code",
  "Code is incorrect": "Code is incorrect",
  "Select relevant banks": "Select relevant banks",
  "Select relevant companies": "Select relevant companies",
  "User Details": "User Details",
  "Search request...": "Search request...",
  "See all Invited": "See all Invited",
  "Invited Users": "Invited Users",
  "You can find all the users who accepted the invite in the user/admin list": "You can find all the users who accepted the invite in the user/admin list",
  "Deleting user invite": "Deleting user invite",
  "When deleted, the person invited will no longer be able to set-up their profile using the generated credentials.": "When deleted, the person invited will no longer be able to set-up their profile using the generated credentials.",
  "Delete Invitation": "Delete Invitation",
  "Invited Colleagues": "Invited Colleagues",
  "Skip question?": "Skip question?",
  "Skip question": "Skip question",
  "bank_user_pi_share_fullname": "Allow users to share full name with customers",
  "bank_user_pi_share_jt": "Allow users to share job title with customers",
  "bank_user_pi_share_cnt": "Allow users to share country of residence with bank",
  "bank_user_pi_share_pp": "Allow users to share profile picture with customers",
  "Define your bank’s sharing policy": "Define your bank’s sharing policy",
  "bank_inv_customers": "Allow users to invite customers to CXFacts",
  "bank_fa_st_feed": "Allow users to start feedback sessions",
  "bank_fa_cm_req": "Allow users to comment on requests with bank",
  "bank_fa_cm_idea": "Allow users to  comment on ideas with bank",
  "bank_fa_oc_it": "Close/Open request and ideas",
  "bank_pi_share_fullname": "Allow users to share full name with customers when commenting on requests/ideas",
  "bank_pi_share_jt": "Allow users to share job title with customers",
  "bank_pi_share_pp": "Allow users to share profile picture with customers",
  "bank_pi_share_cnt": "Allow users to share country of residence with bank",
  "Created on": "Created on",
  "No User Invited": "No User Invited",
  "Create": "Create",
  "New Request or Idea": "New Request or Idea",
  "Description must be between MIN and MAX characters": "Description must be between {min} and {max} characters",
  "Crop Image": "Crop Image",
  "Confirm your image upload by properly adjusting your Profile Picture": "Confirm your image upload by properly adjusting your Profile Picture",
  "Confirm your image upload by properly adjusting your Logo": "Confirm your image upload by properly adjusting your Logo",
  "Request for Bank": "Request for Bank",
  "Sharing not allowed": "Sharing not allowed",
  "You cannot share this ITEM with bank as it is contradicting with your company sharing policies.": "You cannot share this {item} with bank as it is contradicting with your company sharing policies.",
  "Contact your Admin": "Contact your Admin",
  "Idea for Bank": "Idea for Bank",
  "idea": "idea",
  "request": "request",
  "Average Score over time (All Product Areas)": "Average Score over time (All Product Areas)",
  "Company Users": "Company Users",
  "Sort Users By": "Sort Users By",
  "All Invited Customers": "All Invited Customers",
  "Search Customer or Employee": "Search Customer or Employee",
  "Please Provide Feedback": "Please Provide Feedback",
  "Product Area Involved": "Product Area Involved",
  "Leave Feedback Session": "Leave Feedback Session",
  "You can always resume the feedback session before the expiry": "You can always resume the feedback session before the expiry",
  "Question Details": "Question Details",
  "Next Question": "Next Question",
  "Skip this question": "Skip this question",
  "NUM1 out of NUM2": "{num1} out of {num2}",
  "All banks in session": "All banks in session",
  "Skip this bank": "Skip this bank",
  "Reply per country": "Reply per country",
  "Give Score": "Give Score",
  "Comment for BANK on this question": "Comment for {bank} on this question",
  "Add Comment": "Add Comment",
  "Save Comment": "Save Comment",
  "Confirm deletion": "Confirm deletion",
  "Are you sure, you want to delete this comment?": "Are you sure, you want to delete this comment?",
  "Are you sure you want to leave this feedback session?": "Are you sure you want to leave this feedback session?",
  "Leave": "Leave",
  "Hey, it seems you either missed or didn't complete giving score to some of the countries.": "Hey, it seems you either missed or didn't complete giving score to some of the countries.",
  "Provide feedback": "Provide feedback",
  "Reply now or register to get all benefits of CXFacts platform": "Reply now or register to get all benefits of CXFacts platform",
  "Already a user?": "Already a user?",
  "Login": "Login",
  "Track the Service Performance of your Banks for Free": "Track the Service Performance of your Banks for Free",
  "Generate Insights that lead to Actions and Results": "Generate Insights that lead to Actions and Results",
  "Get free access to CXFacts platform. Keep and analyze data on your banks.": "Get free access to CXFacts platform. Keep and analyze data on your banks.",
  "Track performance across all banks, countries and products.": "Track performance across all banks, countries and products.",
  "Create and share relevant feedback with colleagues and banks. Prepare for bank meetings.": "Create and share relevant feedback with colleagues and banks. Prepare for bank meetings.",
  "See market benchmark on banks globally. Compare to your own banks.": "See market benchmark on banks globally. Compare to your own banks.",
  "Book Intro Meeting": "Book Intro Meeting",
  "Need help?": "Need help?",
  "Contact Us": "Contact Us",
  "Session": "Session",
  "Company Headquarters": "Company Headquarters",
  "Revenue": "Revenue",
  "Last updated by": "Last updated by",
  "Reply with info": "Reply with info",
  "Book Phone Interview": "Book Phone Interview",
  "Get in touch with CXFacts": "Get in touch with CXFacts",
  "Sign Up Today!": "Sign Up Today!",
  "Company Banks": "Company Banks",
  "Select banks": "SELECT BANK’S’",
  "You need to select atleast one bank to continue": "You need to select atleast one bank to continue",
  "No banks found": "No banks found",
  "Selected Banks & Countries": "Selected Banks & Countries",
  "Start Date": "Start Date",
  "End Date": "End Date",
  "Created by": "Created by",
  "Reply now": "Reply now",
  "Invitees": "Invitees",
  "Back to Feedback Sessions": "Back to Feedback Sessions",
  "Banks in Session": "Banks in Session",
  "Select More": "Select More",
  "Are you sure, you want to skip this question?": "Are you sure, you want to skip this question?",
  "Thank you for submitting your feedback": "Thank you for\n submitting your feedback",
  "Score per Bank": "Score per Bank",
  "Score per Country": "Score per Country",
  "Score per Product Area": "Score per Product Area",
  "You can easily save and generate data on all of your banking partners. Register now and explore more!": "You can easily save and generate data on all of your banking partners. Register now and explore more!",
  "Sign Up Today": "Sign Up Today",
  "Reply Summary": "Reply Summary",
  "Thank you for your valuable inputs, you can see more details on PLATFORMNAME!": "Thank you for your valuable inputs, you can see more details on {platformName}!",
  "Quick Reply": "Quick Reply",
  "Register & Reply Now": "Register & Reply Now",
  "or": "or",
  "Best Bank Management System": "Best Bank Management System",
  "Add Customer by Email": "Add Customer by Email",
  "Create a Company": "Create a Company",
  "Add Emails": "Add Emails",
  "What is this company called?": "What is this company called?",
  "Add Email": "Add Email",
  "Please input Company Name": "Please input Company Name",
  "Added Emails": "Added Emails",
  "No emails added": "No emails added",
  "Email already exists": "Email already exists",
  "Enter your email to continue with your PLATFORMNAME account.": "Enter your email to continue with your {platformName} account.",
  "Comments count": "Comments count",
  "at": "at",
  "Continue Session": "Continue Session",
  "By choosing 'reply now' you accept our": "By choosing “reply now” you accept our",
  "Deleting User": "Deleting User",
  "Are you sure you want to delete this user?": "Are you sure you want to delete this user?",
  "Pending more data": "Pending more data",
  "There is not enough data to show you development over time. Please check back later.": "There is not enough data to show you development over time.\nPlease check back later.",
  "Account successfully deleted.": "Account successfully deleted.",
  "Followed Portfolio Feature": "Followed Portfolio Feature",
  "You will soon be able to follow specific companies and compare your portfolio against rest of the bank ": "You will soon be able to follow specific companies and compare your portfolio against rest of the bank ",
  "Session ID": "Session ID",
  "Select relevant country of the bank": "Select relevant country of the bank",
  "No Request Found": "No Request Found",
  "There are no open requests yet. Please check back later.": "There are no open requests yet.\nPlease check back later.",
  "There are no open requests to be found. This might have happened due to the filters applied.": "There are no open requests to be found.\nThis might have happened due to the filters applied.",
  "No Idea Found": "No Idea Found",
  "There are no open ideas yet. Please check back later.": "There are no open ideas yet.\nPlease check back later.",
  "There are no open ideas to be found. This might have happened due to the filters applied.": "There are no open ideas to be found.\nThis might have happened due to the filters applied.",
  "No Session Found": "No Session Found",
  "There are no feedback sessions created yet. Please check back later or": "There are no feedback sessions created yet.\nPlease check back later or",
  "There are no feedback sessions to be found. It might have happened due to the filters applied. Please check filters or": "There are no feedback sessions to be found.\nIt might have happened due to the filters applied.\nPlease check filters or",
  "Bank List": "Bank List",
  "View my banks": "View my banks",
  "Please select product area": "Please select product area",
  "Product Area(s)": "Product Area(s)",
  "Select Product Areas you want to filter on": "Select Product Areas you want to filter on",
  "Search Product Area": "Search Product Area",
  "No Users": "No Users",
  "Reply Rate": "Reply Rate",
  "Bank user": "Bank user",
  "Blocked": "Blocked",
  "Unknown": "Unknown",
  "Based on all your Banks/filters applied": "Based on all your Banks/filters applied",
  "Call To Actions": "Call To Actions",
  "Imp": "Imp",
  "Your top COUNT Banks in COUNTRY": "Your top {count} Banks in {country}",
  "Your top COUNT Bank in COUNTRY": "Your top {count} Bank in {country}",
  "All Time": "All Time",
  "Current Month": "Current Month",
  "Previous Month": "Previous Month",
  "Current Quarter": "Current Quarter",
  "Previous Quarter": "Previous Quarter",
  "Current Year": "Current Year",
  "Previous Year": "Previous Year",
  "Add more": "Add more",
  "Banks included": "Banks included",
  "Product Areas included": "Product Areas included",
  "Open Requests": "Open Requests",
  "Open Ideas": "Open Ideas",
  "Click on any of the bank from the list for more details on it": "Click on any of the bank from the list for more details on it",
  "Company Score - All Bank(s)": "Company Score - All Bank(s)",
  "Based on all your Banks/filter applied": "Based on all your Banks/filter applied",
  "Manage Banks": "Manage Banks",
  "Click on any of the product area from the list for more details on it": "Click on any of the product area from the list for more details on it",
  "Company Score - All Product Area(s)": "Company Score - All Product Area(s)",
  "Based on all your Product Areas/filter applied": "Based on all your Product Areas/filter applied",
  "Click on any of the country from the list for more details on it": "Click on any of the country from the list for more details on it",
  "Manage Countries": "Manage Countries",
  "Company Score": "Company Score",
  "Based on all your Country/filter applied": "Based on all your Country/filter applied",
  "Version": "Version",
  "Back to [Previous Screen]": "Back to {previousScreen}",
  "Product Area Score": "Product Area Score",
  "Country Score": "Country Score",
  "Based on all your banks/filters applied": "Based on all your banks/filters applied",
  "Countries of the bank": "Countries of the bank",
  "Leasing - Advisory & Sales": "Leasing - Advisory & Sales",
  "Tags": "Tags",
  "No Active Product Area Found": "No Active Product Area Found",
  "No Country Found": "No Country Found",
  "Add Country": "Add Country",
  "No Banks Found": "No Banks Found",
  "Your company don't have any product areas reviewed for this bank. Add product areas and start a feedback session to get insights.": "Your company don’t have any product areas reviewed for this bank. Add product areas and start a feedback session to get insights.",
  "Your company don't have any countries reviewed for this bank. Add countries and start a feedback session to get insights.": "Your company don't have any countries reviewed for this bank. Add countries and start a feedback session to get insights.",
  "There are no data for this product area. Add bank(s) and create feedback session for this product area to get insights.": "There are no data for this product area. Add bank(s) and create feedback session for this product area to get insights.",
  "No Open Requests Found": "No Open Requests Found",
  "Your company don't have any open requests for this bank. Requests can be anything from blocked payments to other issues. Create requests to keep track of issues.": "Your company don't have any open requests for this bank. Requests can be anything from blocked payments to other issues. Create requests to keep track of issues.",
  "No Open Ideas Found": "No Open Ideas Found",
  "Your company don't have any open ideas for this bank. Ideas can be anything from blocked payments to other issues. Create ideas to keep track of issues.": "Your company don\'t have any open ideas for this bank. Ideas can be anything from blocked payments to other issues. Create ideas to keep track of issues.",
  "No questions to show": "No questions to show",
  "Your company don't have any data for this bank. All questions reviewed will be shown here. Create a feedback session to start reviewing and get insights into your banks performance.": "Your company don't have any data for this bank. All questions reviewed will be shown here. Create a feedback session to start reviewing and get insights into your banks performance.",
  "Your company don't have any data for the bank(s) selected. Create a feedback session to start reviewing and get insights into your banks performance.": "Your company don't have any data for the bank(s) selected. Create a feedback session to start reviewing and get insights into your banks performance.",
  "Your company don't have any data for the countries selected. Create a feedback session to start reviewing and get insights into your banks performance.": "Your company don't have any data for the countries selected. Create a feedback session to start reviewing and get insights into your banks performance.",
  "No Product Area(s) Found": "No Product Area(s) Found",
  "Your company don't have any data for the product area(s) selected. Create a feedback session to start reviewing and get insights into your banks performance.": "Your company don't have any data for the product area(s) selected. Create a feedback session to start reviewing and get insights into your banks performance.",
  "No Sessions Found": "No Sessions Found",
  "No Call To Actions found": "No Call To Actions found",
  "This section shows the critical questions with low scores. Right now it seems to have none. Going good!": "This section shows the critical questions with low scores. Right now it seems to have none. Going good!",
  "Add More Country(s)?": "Add More Country(s)?",
  "You can add more countries using the button below": "You can add more countries using the button below",
  "NUM of TOTAL completed": "{num} of {total} completed",
  "Bank stats": "Bank stats",
  "Score and Comment Made on": "Score and Comment Made on",
  "All Users & Comments": "All Users & Comments",
  "No Comment": "No Comment",
  "Registered Customer": "Registered Customer",
  "Not Registered": "Not Registered",
  "select customer's'": "select customer’s’",
  "You need to select at least one customer to continue": "You need to select at least one customer to continue",
  "User doesn't have [BANK NAME] in their account. Add product areas and countries to include in feedback session anyway.": "User doesn’t have {bankName} in their account. Add product areas and countries to include in feedback session anyway.",
  "Compare your banks": "Compare your banks",
  "Benchmark": "Benchmark",
  "Total": "Total",
  "Sort Column": "Sort Column",
  "Select the bank you want to compare": "Select the bank you want to compare",
  "All Bank(s)": "All Bank(s)",
  "Clear": "Clear",
  "Banks Selected (COUNT)": "Banks Selected ({count})",
  "All Sessions": "All Sessions",
  "The email you are trying to add here already exists.": "The email you are trying to add here already exists.",
  "The email you are trying to add here already exists - Go to {company} to add to feedback session": "The email you are trying to add here already exists - Go to {company} to add to feedback session",
  "Manually added - overriding user settings": "Manually added - overriding user settings",
  "Super Admin": "Super Admin",
  "Create Feedback Session": "Create Feedback Session",
  "Select which banks and countries to review in the feedback session": "Select which banks and countries to review in the feedback session",
  "Set Basic Info": "Set Basic Info",
  "Create Session description & deadline": "Create Session description & deadline",
  "Set Questionnaire": "Set Questionnaire",
  "Session Summary": "Session Summary",
  "The Title and Description of a session is never shared with the Banks.": "The Title and Description of a session is never shared with the Banks.",
  "Days": "Days",
  "Banks Included": "Banks Included",
  "You need to select a minimum of one country, product area and bank": "You need to select a minimum of one country, product area and bank",
  "Compared Time Period": "Compared Time Period",
  "Please select Compared Time Period": "Please select Compared Time Period",
  "Include Custom Questions": "Include Custom Questions",
  "Include Comments": "Include Comments",
  "Hide Full Name": "Hide Full Name",
  "Include Executive Summary": "Include Executive Summary",
  "Include Detailed View": "Include Detailed View",
  "Active Countries": "Active Countries",
  "Data on more countries found": "Data on more countries found",
  "Data on more Product Areas found": "Data on more Product Areas found",
  "Data unavailable in your system, as you don't have the license for it. Contact PLATFORMNAME to upgrade.": "Data unavailable in your system, as you don’t have the license for it. Contact {platformName} to upgrade.",
  "Back to step NUM": "Back to step {num}",
  "Add Previous Questions": "Add Previous Questions",
  "Continue with Selected Questions": "Continue with Selected Questions",
  "Select Type": "Select Type",
  "Type your Question here": "Type your Question here",
  "Required field": "Required field",
  "Duplicate": "Duplicate",
  "Add Option": "Add Option",
  "Allow respondents to select multiple answers": "Allow respondents to select multiple answers",
  "This is an open ended question, users can write upto 300 characters when they reply to answer this.": "This is an open ended question, users can write upto 300 characters when they reply to answer this.",
  "Statement": "Statement",
  "Statements": "Statements",
  "Option": "Option",
  "Options": "Options",
  "Text Only": "Text Only",
  "Single and Multiple Choice": "Single and Multiple Choice",
  "Likert": "Likert",
  "Score & Importance": "Score & Importance",
  "Add Statement": "Add Statement",
  "Previous Questions": "Previous Questions",
  "Search Questions or ID": "Search Questions or ID",
  "Question Type": "Question Type",
  "Previous questions cannot be edited.": "Previous questions cannot be edited.",
  "Create New": "Create New",
  "Add all selected": "Add all selected",
  "Cancel and back to step NUM": "Cancel and back to step {n}",
  "Are you sure you want to go back to step NUM, the selected questions will be discarded": "Are you sure you want to go back to step {n}, the selected questions will be discarded",
  "Discard changes": "Discard changes",
  "Create Custom Questions": "Create Custom Questions",
  "Please select Product Area to add this question to the session": "Please select Product Area to add this question to the session",
  "Please add at least 1 option": "Please add at least 1 option",
  "Please add at least 1 statement": "Please add at least 1 statement",
  "Please add at least 2 options": "Please add at least 2 options",
  "Please enter a question": "Please enter a question",
  "Custom Questions added successfully!": "Custom Questions added successfully!",
  "This question is marked mandatory and must be answered before continuing to the next question.": "This question is marked mandatory and must be answered before continuing to the next question.",
  "Type your answer here": "Type your answer here",
  "Custom Question": "Custom Question",
  "You can select multiple answers": "You can select multiple answers",
  "Question ID": "Question ID",
  "Respondent's choices": "Respondent's choices",
  "Respondent's Details": "Respondent's Details",
  "All Answers": "All Answers",
  "Text Answer for": "Text Answer for",
  "No Answers Found": "No Answers Found",
  "Reply graph": "Reply graph",
  "Benchmark Questions": "Benchmark Questions",
  "score_importance": "Score & Importance",
  "text_only": "Text Only",
  "Multiple Choice": "Multiple Choice",
  "Single Choice": "Single Choice",
  "multiple_choice": "Single and Multiple Choice",
  "multiple_choice_grid": "Likert",
  "Reply for this bank": "Reply for this bank",
  "Please input Countries": "Please input Countries",
  "Please modify or remove the duplicate question": "Please modify or remove the duplicate question",
  "Please select Feedback Session": "Please select Feedback Session",
  "To learn More": "To learn More",
  "Visit Our Webpage": "Visit Our Webpage",
  "Statement missing title. Please check": "Statement missing title. Please check",
  "Option missing title. Please check": "Option missing title. Please check",
  "Likert is used to gauge attitudes and opinions about a topic.": "Likert is used to gauge attitudes and opinions about a topic.",
  "Bank": "Bank",
  "Select Tiers": "Select Tiers",
  "No product areas": "No product areas",
  "The user don’t have any product areas with data yet": "The user don’t have any product areas with data yet",
  "Select Bank you want reports on": "Select Bank you want reports on",
  "Search Bank": "Search Bank",
  "Put in company name and add e-mails to it": "Put in company name and add e-mails to it",
  "New Customer": "New Customer",
  "Existing Customer": "Existing Customer",
  "Invited Mails": "Invited Mails",
  "Search for the company here": "Search for the company here",
  "Fully accepted companies disappear from this list in DAYS": "Fully accepted companies disappear from this list in {days}",
  "days": "days",
  "View user list": "View user list",
  "Company user list": "Company user list",
  "Icon": "Icon",
  "Impersonate": "Impersonate",
  "Businesses": "Businesses",
  "No selected": "No selected",
  "All Industries": "All Industries",
  "Feedback countries of the bank": "Feedback countries of the bank",
  "Customer’s Global Headquarter": "Customer’s Global Headquarter",
  "Revenue (EUR)": "Revenue (EUR)",
  "Multiple Selected": "Multiple Selected",
  "All Selected": "All Selected",
  "Permission Groups": "Permission Groups",
  "Search Permission Group": "Search Permission Group",
  "Create Permission Group": "Create Permission Group",
  "Permission Group": "Permission Group",
  "Permissions": "Permissions",
  "Permission Group Name": "Permission Group Name",
  "Search User": "Search User",
  "Actions": "Actions",
  "Functions": "Functions",
  "Create feedback session": "Create feedback session",
  "Create request": "Create request",
  "Create idea": "Create idea",
  "Reply to feedback session": "Reply to feedback session",
  "Invite customers": "Invite customers",
  "Invite/Edit/Delete Users": "Invite/Edit/Delete Users",
  "Edit Bank Settings": "Edit Bank Settings",
  "Access": "Access",
  "Action": "Action",
  "No Permission Group": "No Permission Group",
  "Add Customers": "Add Customers",
  "Select the customers to include in Permission Group.": "Select the customers to include in Permission Group.",
  "No Users Selected": "No Users Selected",
  "Choose users using the 'Add Users' button.": "Choose users using the 'Add Users' button.",
  "Add Users": "Add Users",
  "Select the users to whom you want to assign the Permission Group.": "Select the users to whom you want to assign the Permission Group.",
  "Add": "Add",
  "Delete Group": "Delete Group",
  "Are you sure you want to remove the Permission Group?": "Are you sure you want to remove the Permission Group?",
  "Comment on requests": "Comment on requests",
  "Comment on ideas": "Comment on ideas",
  "Open/close requests and ideas": "Open/close requests and ideas",
  "Edit Permission Group": "Edit Permission Group",
  "If no Permission Group is assigned, all permissions will be disabled for the user. They can be enabled later on.": "If no Permission Group is assigned, all permissions will be disabled for the user. They can be enabled later on.",
  "Invite user": "Invite user",
  "Edit user": "Edit user",
  "Promote/demote user": "Promote/demote user",
  "Delete user": "Delete user",
  "Edit bank": "Edit bank",
  "Change user permissions and permission groups": "Change user permissions and permission groups",
  "Change admin permissions": "Change admin permissions",
  "Invite admin": "Invite admin",
  "Edit admin": "Edit admin",
  "Delete admin": "Delete admin",
  "Action not permitted": "Action not permitted",
  "Permissions Group": "Permissions Group",
  "Give access to specific product areas, countries or customers by selecting them in the boxes on the right. Selecting all will give access to future additions also.": "Give access to specific product areas, countries or customers by selecting them in the boxes on the right. Selecting all will give access to future additions also.",
  "If all functions are disabled, user will be in a view-only mode.": "If all functions are disabled, user will be in a view-only mode.",
  "Reset to default": "Reset to default",
  "Permitted": "Permitted",
  "Select TYPE you want to allow access to": "Select {type} you want to allow access to",
  "Edit User": "Edit User",
  "View User": "View User",
  "Business Settings": "Business Settings",
  "Invite users": "Invite users",
  "Share requests with bank": "Share requests with bank",
  "Share ideas with bank": "Share ideas with bank",
  "Add Banks": "Add Banks",
  "Select the banks to include in Permission Group.": "Select the banks to include in Permission Group.",
  "Edit business": "Edit business",
  "Give access to specific product areas, countries or banks by selecting them in the boxes on the right. Selecting all will give access to future additions also.": "Give access to specific product areas, countries or banks by selecting them in the boxes on the right. Selecting all will give access to future additions also.",
  "Allow users to share full name with bank": "Allow users to share full name with bank",
  "Allow users to share job title with bank": "Allow users to share job title with bank",
  "Allow users to share profile picture with bank": "Allow users to share profile picture with bank",
  "Allow users to share country of residence with bank": "Allow users to share country of residence with bank",
};
