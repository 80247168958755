import { RequestStatusEnum } from "@/store/enums/request/RequestStatusEnum";
import { translateGlobal } from "@/core/helpers/GlobalHelper";

export class RequestStatusModel {
  statusName: string;
  statusClass: string;
  code: number;

  constructor(statusCode: number) {
    this.code = statusCode;
    this.statusName = translateGlobal(RequestStatusEnum[statusCode]);
    this.statusClass = this.defineStatusClass(statusCode);
  }

  defineStatusClass(statusCode: number) {
    let className = "" as string;

    switch (statusCode) {
      case RequestStatusEnum.OPEN:
        className = 'primary';
        break;
      case RequestStatusEnum.CLOSED:
        className = 'secondary';
        break;
    }

    return className;
  }
}
