import { RouteGuards } from "@/router/RouteGuards";

const bankRoutes = [
  {
    path: "/bank/dashboard",
    name: "bank-dashboard",
    component: () => import("@/buying-teams/pages/bank/dashboard/BankDashboard.vue"),
    redirect: '/bank/dashboard/bank-overview',
    children: [
      {
        path: "/bank/dashboard/bank-overview",
        name: "dashboard-bank-overview",
        component: () => import("@/buying-teams/pages/bank/dashboard/pages/BankOverview.vue")
      },
    ]
  },
  {
    path: "/bank/product-areas",
    name: "dashboard-bank-product-areas",
    component: () => import("@/buying-teams/pages/bank/dashboard/pages/ProductAreas.vue")
  },
  {
    path: "/bank/product-areas",
    name: "dashboard-bank-product-areas",
    component: () => import("@/buying-teams/pages/bank/dashboard/pages/ProductAreas.vue")
  },
  {
    path: "/bank/product-areas/:productArea",
    name: "dashboard-product-area-overview",
    component: () => import("@/buying-teams/pages/bank/dashboard/pages/BankDedicatedProductArea.vue")
  },
  {
    path: "/bank/countries",
    name: "dashboard-bank-countries",
    component: () => import("@/buying-teams/pages/bank/dashboard/pages/Countries.vue")
  },
  {
    path: "/bank/countries/:country",
    name: "dashboard-bank-country",
    component: () => import("@/buying-teams/pages/bank/dashboard/pages/BankDedicatedCountry.vue")
  },
  {
    path: "/bank/questions/:id",
    name: "bank-comment-item",
    component: () => import("@/buying-teams/pages/bank/dashboard/pages/BankDedicatedQuestion.vue")
  },
  {
    path: "/bank/feedback/requests",
    name: "bank-requests",
    component: () => import("@/buying-teams/pages/bank/requests/BankRequests.vue")
  },
  {
    path: "/bank/feedback/request/:id",
    name: "bank-request",
    component: () => import("@/buying-teams/pages/bank/requests/BankSingleRequest.vue")
  },
  {
    path: "/bank/feedback/ideas",
    name: "bank-ideas",
    component: () => import("@/buying-teams/pages/bank/ideas/BankIdeas.vue")
  },
  {
    path: "/bank/feedback/idea/:id",
    name: "bank-idea",
    component: () => import("@/buying-teams/pages/bank/ideas/BankSingleIdea.vue")
  },
  {
    path: "/bank/feedback/feedback-sessions",
    name: "bank-feedbacks",
    component: () => import("@/buying-teams/pages/bank/feedbacks/BankFeedbacks.vue")
  },
  {
    path: "/bank/feedback/feedback-session/create",
    name: "create-feedback",
    component: () => import("@/buying-teams/pages/bank/feedbacks/feedbacks-creation/BankFeedbackCreate.vue")
  },
  {
    path: "/bank/feedback/feedback-session/:id/result",
    name: "feedback",
    component: () => import("@/buying-teams/pages/bank/feedbacks/BankSingleFeedback.vue")
  },
  {
    path: "/bank/reports/custom-reports",
    name: "custom-reports",
    component: () => import("@/buying-teams/pages/bank/reports/CustomReports.vue")
  },
  {
    path: "/bank/reports/market-reports",
    name: "market-reports",
    component: () => import("@/buying-teams/pages/bank/reports/MarketReports.vue")
  },
  {
    path: "/bank/teams/all",
    name: "bank-teams",
    component: () => import("@/buying-teams/pages/bank/teams/AllUsers.vue")
  },
  {
    path: "/bank/customers/all",
    name: "bank-customers",
    component: () => import("@/buying-teams/pages/bank/customers/Customers.vue"),
  },
  {
    path: "/bank/customers/invite",
    name: "bank-customers-invite",
    component: () => import("@/buying-teams/pages/bank/customers/CustomersInvite.vue"),
  },
  {
    path: "/bank/teams/user/:id",
    name: "bank-teams-user",
    component: () => import("@/buying-teams/pages/bank/teams/SingleUser.vue")
  },
  {
    path: "/bank/customers/customer/:id",
    name: "bank-customer",
    component: () => import("@/buying-teams/pages/bank/customers/components/DedicatedCustomerPage.vue")
  },
  {
    path: "/bank/customers/customer/",
    redirect: "/bank/customers/customer/*"
  },
  {
    path: "/bank/settings/user-settings",
    redirect: "/bank/user-settings/overview",
    name: "bank-user-settings",
    component: () => import("@/buying-teams/pages/bank/settings/user/UserSettings.vue"),
    children: [
      {
        path: "/bank/settings/user-settings/overview",
        name: "bank-user-setting-overview",
        component: () => import("@/buying-teams/pages/bank/settings/user/Overview.vue")
      },
      {
        path: "/bank/settings/user-settings/settings",
        name: "user-setting-settings",
        component: () => import("@/buying-teams/pages/bank/settings/user/Settings.vue")
      },
      // {
      //   path: "/bank/settings/user-settings/notifications",
      //   name: "user-settings-notifications",
      //   component: () => import("@/buying-teams/pages/bank/settings/user/Notifications.vue")
      // },
      {
        path: "/bank/settings/user-settings/followed-countries",
        name: "user-settings-followed-countries",
        component: () => import("@/buying-teams/pages/bank/settings/user/FollowedCountries.vue")
      },
      {
        path: "/bank/settings/user-settings/followed-product-areas",
        name: "user-settings-followed-product-areas",
        component: () => import("@/buying-teams/pages/bank/settings/user/FollowedProductAreas.vue")
      },
      // {
      //   path: "/bank/settings/user-settings/sharing-policy",
      //   name: "user-settings-sharing-policy",
      //   component: () => import("@/buying-teams/pages/bank/settings/user/SharingPolicy.vue")
      // }
    ]
  },
  {
    path: "/bank/settings/bank-settings",
    redirect: "/bank/bank-settings/overview",
    name: "bank-settings",
    meta: {
      guards: [RouteGuards.ADMIN]
    },
    component: () => import("@/buying-teams/pages/bank/settings/bank/BankSettings.vue"),
    children: [
      {
        path: "/bank/settings/bank-settings/overview",
        name: "Overview",
        meta: {
          guards: [RouteGuards.ADMIN]
        },
        component: () => import("@/buying-teams/pages/bank/settings/bank/Overview.vue")
      },
      {
        path: "/bank/settings/bank-settings/settings",
        name: "Settings",
        meta: {
          guards: [RouteGuards.ADMIN]
        },
        component: () => import("@/buying-teams/pages/bank/settings/bank/Settings.vue")
      },
      {
        path: "/bank/settings/bank-settings/countries",
        name: "countries",
        meta: {
          guards: [RouteGuards.ADMIN]
        },
        component: () => import("@/buying-teams/pages/bank/settings/bank/Countries.vue")
      },
      {
        path: "/bank/settings/bank-settings/product-areas",
        name: "product-areas",
        meta: {
          guards: [RouteGuards.ADMIN]
        },
        component: () => import("@/buying-teams/pages/bank/settings/bank/ProductAreas.vue")
      },
      {
        path: "/bank/settings/bank-settings/user-management",
        name: "user-management",
        meta: {
          guards: [RouteGuards.ADMIN]
        },
        component: () => import("@/buying-teams/pages/bank/settings/bank/UserManagement.vue")
      },
      {
        path: "/bank/settings/bank-settings/permission-groups",
        name: "permission-groups",
        meta: {
          guards: [RouteGuards.ADMIN]
        },
        component: () => import("@/buying-teams/pages/bank/settings/bank/PermissionGroups.vue")
      },
      // {
      //   path: "/bank/settings/bank-settings/sharing-policy",
      //   name: "bank-sharing-policy",
      //   meta: {
      //     guards: [RouteGuards.ADMIN]
      //   },
      //   component: () => import("@/buying-teams/pages/bank/settings/bank/SharingPolicy.vue")
      // },
    ]
  },
  {
    path: "/bank/settings/bank-settings/permission-groups/add",
    name: "bank-settings-permission-groups-add",
    component: () => import("@/buying-teams/pages/bank/settings/bank/AddPermissionGroup.vue"),
    meta: {
      guards: [RouteGuards.ADMIN]
    },
  },
  {
    path: "/bank/settings/bank-settings/permission-groups/edit/:id",
    name: "bank-settings-permission-groups-edit",
    component: () => import("@/buying-teams/pages/bank/settings/bank/EditPermissionGroup.vue"),
    meta: {
      guards: [RouteGuards.ADMIN]
    },
  },
];

// TODO add (business, admin, super admin) guards
bankRoutes.forEach((route: any) => {
  if (!route["meta"]) {
    route["meta"] = {guards: [RouteGuards.AUTH, RouteGuards.BANK]}
  } else {
    if (route["meta"].guards) {
      route["meta"].guards.push(RouteGuards.AUTH, RouteGuards.BANK);
    } else {
      route["meta"].guards = [RouteGuards.AUTH, RouteGuards.BANK];
    }
  }
});
export default bankRoutes;
