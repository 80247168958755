import {
    CustomQuestionsAnalyticsBaseModel
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsBaseModel";

export class CustomQuestionsAnalyticsTextOnlyModel extends CustomQuestionsAnalyticsBaseModel {
    answers: number = 0;

    constructor(data) {
        super(data);

        this.answers = (data && data.stats) ? data.stats.length : 0;
    }

}
