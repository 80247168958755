export class BaseBankFilterModel {
  product_areas: string[];
  time_period: string;
  countries: string[];
  customers: number[];

  constructor(obj: any) {
    this.customers = obj['customers'];
    this.product_areas = obj['product_areas'];
    this.countries = obj['countries'];
    this.time_period = obj['time_period'] ? obj['time_period'] : '*';
  }
}
