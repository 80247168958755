import { UserModel } from "@/store/models/UserModel";
import { ERROR_TYPES, ErrorHandlerService } from "@/core/services/ErrorHandlerService";
import { BusinessModel } from "@/store/models/BusinessModel";
import { AESService } from "@/core/services/AESService";
import { BankModel } from "@/store/models/bank/BankModel";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { DEFAULT_PLATFORM_TYPE } from "@/core/config/constants";

const USER_DATA_KEY = "userData";
const BUSINESS_DATA_KEY = "businessData";
const BANK_DATA_KEY = "bankData";
const USER_FIRST_LOGIN = 'userFirstLogin'
const COOKIE_INFO_CHECKBOX = 'cookie-info-checkbox'
const storage = localStorage;

export class DataService {
  static storeAuthUserData(userData: UserModel): void {
    storage.setItem(USER_DATA_KEY, AESService.encodeJson(userData));
  }

  static getAuthUserData(): UserModel | null {
    let userData = AESService.decodeJson(storage.getItem(USER_DATA_KEY));

    try {
      return new UserModel(userData);
    } catch (e) {
      ErrorHandlerService.handleError(ERROR_TYPES.INVALID_USER_DATA);
    }

    return null;
  }

  static destroyAuthUserData(): void {
    storage.removeItem(USER_DATA_KEY);
  }

  static storeBusinessData(businessData: BusinessModel): void {
    storage.setItem(BUSINESS_DATA_KEY, AESService.encodeJson(businessData));
  }

  static getBusinessData(): BusinessModel | null {
    let businessData = AESService.decodeJson(storage.getItem(BUSINESS_DATA_KEY));

    try {
      return new BusinessModel(businessData);
    } catch (e) {
      ErrorHandlerService.handleError(ERROR_TYPES.INVALID_BUSINESS_DATA);
    }

    return null;
  }

  static destroyBusinessData(): void {
    storage.removeItem(BUSINESS_DATA_KEY);
  }

  static storeBankData(bankData: BankModel): void {
    storage.setItem(BANK_DATA_KEY, AESService.encodeJson(bankData));
  }

  static getBankData(): BankModel | null {
    let bankData = AESService.decodeJson(storage.getItem(BANK_DATA_KEY));

    try {
      return new BankModel(bankData);
    } catch (e) {
      ErrorHandlerService.handleError(ERROR_TYPES.INVALID_BANK_DATA);
    }

    return null;
  }

  static destroyBankData(): void {
    storage.removeItem(BANK_DATA_KEY);
  }

  static storeUserFirstLogin(): void {
    storage.setItem(USER_FIRST_LOGIN, AESService.encodeJson(true));
  }

  static getUserFirstLogin(): boolean {
    // Remove false flag for enabling walkthrough Modal
    return false
    return !!AESService.decodeJson(storage.getItem(USER_FIRST_LOGIN))
  }

  static destroyUserFirstLogin(): void {
    storage.removeItem(USER_FIRST_LOGIN);
  }

  static storeCookieCheckbox(): void {
    storage.setItem(COOKIE_INFO_CHECKBOX, 'yes');
  }

  static getCookieCheckbox(): boolean {
    return !!storage.getItem(COOKIE_INFO_CHECKBOX);
  }

  static getPlatformType(): PlatformTypeEnum {
    const userData = DataService.getAuthUserData();

    if (userData) {
      if (userData.is_bank_user) {
        return PlatformTypeEnum.BANK;
      } else {
        return PlatformTypeEnum.BUSINESS
      }
    } else {
      return DEFAULT_PLATFORM_TYPE;
    }
  }

  static removeAllData() {
    this.destroyAuthUserData();
    this.destroyBusinessData();
    this.destroyBankData();
  }
}
