import { DateRangePickerInterface } from "@/store/interfaces/DateTimeInterface";

export class BusinessSingleBankDashboardFilterModel {
    product_areas: string[];
    countries: string[];
    time_period: DateRangePickerInterface;

    constructor(obj: any) {
        this.product_areas = obj['product_areas'];
        this.countries = obj['countries'];
        this.time_period = obj['time_period'];
    }
}
