import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import NotificationService from "@/buying-teams/services/NotificationService";
import { BANK_PROFILE_API } from "@/router/BankApi";

@Module
export default class BankUserProfileModule extends VuexModule {
  @Action
  async updateBankUserProfileDetails(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(BANK_PROFILE_API.UPDATE_PROFILE, data)
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async updateBankUserFollows(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(BANK_PROFILE_API.EDIT_FOLLOWS, data)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }

  @Action
  async fetchBankUserData(user_id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(BANK_PROFILE_API.GET_BANK_USER, {
        params: {
          user_id
        }
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }
}
