import { Action, Module, VuexModule } from "vuex-module-decorators";
import { PERMISSION_API } from "@/router/BankApi";
import ApiService from "@/core/services/ApiService";
import NotificationService from "@/buying-teams/services/NotificationService";
import { BankPermissionGroup } from "@/store/models/bank/permissions/BankPermissionGroup";

@Module
export default class BankPermissionsModule extends VuexModule {
    @Action
    deletePermissionsGroup(groupId: number) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(PERMISSION_API.DELETE_PERMISSION_GROUP, {
                group_id: groupId
            }).then((res) => {
                resolve(res);
            }).catch((error) => {
                NotificationService.swalError(error);
                reject()
            })
        })
    }

    @Action
    addPermissionsGroup(payload: any) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(PERMISSION_API.ADD_PERMISSION_GROUP, payload)
              .then((res) => {
                  resolve(res);
              }).catch((error) => {
                NotificationService.swalError(error);
                reject()
            })
        })
    }

    @Action
    editPermissionsGroup(payload: any) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(PERMISSION_API.EDIT_PERMISSION_GROUP, payload)
              .then((res) => {
                  resolve(res);
              }).catch((error) => {
                NotificationService.swalError(error);
                reject()
            })
        })
    }

    @Action
    getPermissionsGroupById(groupId: number) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.query(PERMISSION_API.GET_PERMISSION_GROUP, {
                params: { group_id: groupId }
            })
              .then((res) => {
                  resolve(new BankPermissionGroup(res.data.data));
              }).catch((error) => {
                NotificationService.swalError(error);
                reject()
            })
        })
    }

    @Action
    getPermissionsGroups() {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.get(PERMISSION_API.GET_PERMISSION_GROUPS_LIST)
              .then((res) => {
                  const data = res.data.data || [];
                  resolve(data.map(g => new BankPermissionGroup(g)));
              }).catch((error) => {
                  resolve([]);
            })
        })
    }
}
