const API_AUTH = {
  CHECK_EMAIL: '/auth/check_email',
  LOGIN: '/auth/login',
  VERIFY_EMAIL: '/auth/verify_email',
  REGISTER_USER: '/auth/register_user',
  DELETE_USER: '/auth/delete_user',
  REFRESH_TOKEN: '/auth/refresh_token',
  RESET_PASSWORD: '/auth/password_reset',
  CHANGE_PASSWORD: '/auth/password_change',
  CHANGE_EMAIL: '/auth/change_email',
  GET_AUTH_USER_DATA: '/auth/user_data'
};

const API_REGISTER = {
  BUSINESS_CREATE: '/business/create',
  BUSINESS_SETUP_USER: '/business/setup_user',
  BUSINESS_INV_UPDATE: '/business/update',
}

export {
  API_AUTH,
  API_REGISTER
}
