import { Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { COMPARE_API } from "@/router/BusinessApi";
import NotificationService from "@/buying-teams/services/NotificationService";
import { diagLog } from "@/core/helpers/GlobalHelper";

@Module
export default class CompareModule extends VuexModule{
    @Action
    async fetchProductAreaQuestions(productArea) {
        diagLog('fetchProductAreaQuestions', productArea)

        return new Promise(async (resolve, reject) => {
            ApiService.post(COMPARE_API.GET_PRODUCT_AREA_QUESTIONS, { product_area: productArea })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.error(error);
                    NotificationService.swalError(error);
                    reject();
                });
        })
    }

    @Action
    async fetchQuestionStats(filters) {
        return new Promise(async (resolve, reject) => {
            ApiService.post(COMPARE_API.GET_QUESTION_STATS, {filter: filters})
                .then(res => {
                    diagLog('fetchQuestionStats RESULT', res);
                    resolve(res.data);
                })
                .catch(error => {
                    console.error(error);
                    NotificationService.swalError(error);
                    reject();
                });
        })
    }
}

