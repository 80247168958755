import {
    CustomQuestionsAnalyticsBaseModel
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsBaseModel";

interface optionsDataInterface {
    id: number;
    label: string;
    percentage: number;
}

export class CustomQuestionsAnalyticsMultipleChoiceModel extends CustomQuestionsAnalyticsBaseModel {
    optionsData: optionsDataInterface[] = [];

    constructor(data) {
        super(data);

        if (data.stats && data.stats.length) {
            data.stats.forEach(item => {
                this.optionsData.push({
                    id: item.id,
                    label: item.option,
                    percentage: item.percentage
                })
            })
        } else if (data.options && data.options.length) {
            data.options.forEach(item => {
                this.optionsData.push({
                    id: item.id,
                    label: item.label,
                    percentage: 0
                })
            })
        }
    }
}
