
const authRoutes = [
  {
    path: "/sign-in",
    name: "sign-in",
    component: () =>
      import("@/buying-teams/pages/authentication/SignIn.vue")
  },
  {
    path: "/register-sg",
    name: "register-sg",
    component: () =>
        import("@/buying-teams/pages/authentication/SGSignIn.vue")
  },
  {
    path: "/create-password",
    name: "create-password",
    component: () =>
      import("@/buying-teams/pages/authentication/CreatePassword.vue")
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () =>
        import("@/buying-teams/pages/authentication/ChangePassword.vue")
  },
  {
    path: "/recover-password",
    name: "recover-password",
    component: () =>
        import("@/buying-teams/pages/authentication/RecoverPassword.vue")
  },
  {
    path: "/continue-to-login",
    name: "continue-to-login",
    component: () =>
      import("@/buying-teams/pages/authentication/ContinueLogin.vue")
  },
  {
    path: "/verify",
    name: "verify",
    component: () =>
      import("@/buying-teams/pages/authentication/VerificationKey.vue")
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () =>
        import("@/buying-teams/pages/authentication/PasswordReset.vue"),
  },
];

export default authRoutes;
