import { countries } from "country-data";
import { lookup } from "country-data";
import { diagLog } from "@/core/helpers/GlobalHelper";

export class CountryUtil {
    static countryMapping: {};
    static countryImagePublicPath = '/media/countries';

    static init(countriesData: string[]): void {
        this.countryMapping = {};

        countriesData.forEach(cn => {
            const image = `${this.countryImagePublicPath}/${cn.split(' ').join('_')}.svg`;
            this.countryMapping[cn] = {
                name: cn,
                image
            }
        });
    }

    static checkCountryNames(countriesData) {
        let countryImages = require('../static-data/country-names.json');
        let changedNames = countriesData.map(cn => cn.split(' ').join('_').concat('.svg'));

        let existing = [] as any[];
        let missed = [] as any[];

        changedNames.forEach(chN => {
            if (countryImages.includes(chN)) {
                existing.push(chN);
            } else {
                existing.push(chN);
            }
        })

        diagLog('existing => ', existing);
        diagLog('missed => ', missed);
        diagLog('countryMapping => ', this.countryMapping);
    }

    static getCountryCode(countryName) {
        let country = this.countryMapping[countryName];

        if (!country) {
            console.error(countryName + " not found");
            return "";
        }
        return country.alpha2;
    }

    static getCountryImage(countryName) {
        let country = this.countryMapping[countryName];

        if (!country) {
            console.error(countryName + " not found");
            return "";
        }

        return country.image;
    }

    static fetchCountryInfoByName(countryName) {
        return lookup.countries({ name: countryName })[0];
    }
}
