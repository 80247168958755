import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { NOTIFICATIONS_API } from "@/router/BankApi";
import NotificationService from "@/buying-teams/services/NotificationService";
import store from "@/store";

@Module
export default class BankNotificationsModule extends VuexModule {
  bankSettingsLoading: boolean = true;
  bankUserSettingsLoading: boolean = true;

  get getBankSettingsLoading() {
    return this.bankSettingsLoading
  }

  get getBankUserSettingsLoading() {
    return this.bankUserSettingsLoading
  }

  @Mutation
  SET_BANK_SETTINGS_LOADING(value) {
    this.bankSettingsLoading = value
  }

  @Mutation
  SET_BANK_USER_SETTINGS_LOADING(value) {
    this.bankUserSettingsLoading = value
  }

  @Action
  async fetchBankUserSettings() {
    this.context.commit('SET_BANK_USER_SETTINGS_LOADING', true);

    let user = store.getters.currentUser;
    let bank = store.getters.bank;
    return new Promise((resolve, reject) => {
      ApiService.get(NOTIFICATIONS_API.GET_USER_SETTINGS)
        .then(res => {
          user.updateSettings(res.data);
          resolve(res.status === 200);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        })
        .finally(() => {
          this.context.commit('SET_BANK_USER_SETTINGS_LOADING', false);
        })
    })
  }

  @Action
  async getBankSettings() {
    this.context.commit('SET_BANK_SETTINGS_LOADING', true);

    return new Promise((resolve, reject) => {
      ApiService.get(NOTIFICATIONS_API.GET_BANK_SETTINGS)
        .then(res => {
          this.context.getters.bank.updateSettings(res.data)
          resolve(res);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        })
        .finally(() => {
          this.context.commit('SET_BANK_SETTINGS_LOADING', false);
        })
    })
  }

  @Action
  async updateBankSettings(payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(NOTIFICATIONS_API.UPDATE_BANK_SETTINGS, payload)
          .then(res => {
            resolve(res.status === 200);
          })
          .catch(error => {
            NotificationService.swalError(error);
            reject()
          });
    })
  }

  @Action
  updateBankUserSettings(payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(NOTIFICATIONS_API.UPDATE_USER_SETTINGS, payload)
        .then(res => {
          resolve(res.status === 200);
        })
        .catch(error => {
          NotificationService.swalError(error);
          reject()
        });
    })
  }

}
