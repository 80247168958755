import {Module, VuexModule, Action, Mutation} from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { REQUESTS_API } from "@/router/BankApi";
import NotificationService from "@/buying-teams/services/NotificationService";
import {BankRequestModel} from "@/store/models/bank/request/BankRequestModel";
import {BankFilterService} from "@/core/services/BankFilterService";
import {BankRequestFilterModel} from "@/store/models/filter/bank/BankRequestFilterModel";

@Module
export default class BankRequestsModule extends VuexModule {
  bankRequestFilterData = {} as BankRequestFilterModel;
  bankRequests: BankRequestModel[] = [];
  bankRequestsLoading: boolean = true;

  get bankRequestFilter() {
    return BankFilterService.getBankRequestFilter();
  }

  get getBankRequestsData() {
    return this.bankRequests;
  }

  get getBankRequestsLoading() {
    return this.bankRequestsLoading;
  }

  @Mutation
  SAVE_BANK_REQUEST_FILTER(filterData) {
    this.bankRequestFilterData = new BankRequestFilterModel(filterData);
    BankFilterService.storeBankRequestFilter(this.bankRequestFilterData);
  }

  @Mutation
  SAVE_BANK_REQUEST_DATA(data: BankRequestModel[]) {
    this.bankRequests = data;
  }

  @Mutation
  SAVE_BANK_REQUEST_LOADING(value: boolean) {
    this.bankRequestsLoading = value;
  }

  @Action
  getBankRequests(data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(REQUESTS_API.GET_REQUESTS, data)
        .then(res => {
          const requests = res.data.data.map(el => new BankRequestModel(el));
          this.context.commit('SAVE_BANK_REQUEST_FILTER', data.filter);
          this.context.commit('SAVE_BANK_REQUEST_DATA', requests);
          resolve(requests)
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        }).finally(() => {
          this.context.commit('SAVE_BANK_REQUEST_LOADING', false);
      });
    })
  }

  @Action
  async getBankRequestDetails(id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(REQUESTS_API.GET_REQUEST_BY_ID, `?intent_id=${id}`)
        .then(res => {
          console.log(res);
          if (res.data) {
            resolve(new BankRequestModel(res.data));
            // resolve(res.data);
          }
        })
        .catch(error => {
          console.log(error, '---error');
          NotificationService.swalError(error);
          reject()
        });
    })
  }
}
