import { Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import NotificationService from "@/buying-teams/services/NotificationService";
import { USER_MANAGEMENT_API } from "@/router/SuperAdminApi";
import JwtService from "@/core/services/JwtService";

@Module
export default class SuperAdminUserManagementModule extends VuexModule {
    @Action
    async getAllUsersList(data) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.get(USER_MANAGEMENT_API.GET_ALL_USERS, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject();
                });
        });
    }

    @Action
    async impersonate(userId) {
        return new Promise((resolve, reject) => {
            ApiService.post(USER_MANAGEMENT_API.IMPERSONATE, { user_id: userId })
                .then(res => {
                    const token = res.data.token;
                    JwtService.saveImpersonateToken(token);
                    ApiService.setImpersonateHeader(token);
                    resolve(true);
                })
                .catch(error => {
                    NotificationService.swalError(error);
                    reject(false);
                });
        });
    }
}
