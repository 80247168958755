import { BaseModel } from "@/store/models/base/BaseModel";
import { Constants } from "@/core/config/constants";

export class BankModelMin extends BaseModel {
  name: string;
  icon_path: string;
  countries?: string[];
  stats: any;

  constructor(obj: any) {
    super(obj);
    this.name = obj.name;
    this.icon_path = obj.icon_path || obj.icon || Constants.BUSINESS_TMP_LOGO;
    this.countries = obj.countries ? obj.countries : [];
    this.stats = obj.stats ? obj.stats : null;
  }
}
