import { Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { PERMISSION_API } from "@/router/BankApi";
import { BusinessPermissionGroup } from "@/store/models/business/permissions/BusinessPermissionGroup";
import NotificationService from "@/buying-teams/services/NotificationService";
import { API_TEAMS } from "@/router/BusinessApi";
import { UserModelMin } from "@/store/models/shared/UserModelMin";
import { BusinessSettingsPermissionGroupsModel } from "@/store/models/business/BusinessTeamUserModel";

@Module
export default class BusinessPermissionsModule extends VuexModule {
    @Action
    deleteBusinessPermissionsGroup(groupId: number) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(PERMISSION_API.DELETE_PERMISSION_GROUP, {
                group_id: groupId
            }).then((res) => {
                resolve(res);
            }).catch((error) => {
                NotificationService.swalError(error);
                reject()
            })
        })
    }

    @Action
    addBusinessPermissionsGroup(payload: any) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(PERMISSION_API.ADD_PERMISSION_GROUP, payload)
              .then((res) => {
                  resolve(res);
              }).catch((error) => {
                NotificationService.swalError(error);
                reject()
            })
        })
    }

    @Action
    editBusinessPermissionsGroup(payload: any) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.post(PERMISSION_API.EDIT_PERMISSION_GROUP, payload)
              .then((res) => {
                  resolve(res);
              }).catch((error) => {
                NotificationService.swalError(error);
                reject()
            })
        })
    }

    @Action
    async getBusinessPermissionsGroupById(groupId: number) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.query(PERMISSION_API.GET_PERMISSION_GROUP, {
                params: { group_id: groupId }
            })
              .then((res) => {
                  resolve(new BusinessPermissionGroup(res.data.data));
              }).catch((error) => {
                NotificationService.swalError(error);
                reject()
            })
        })
    }

    @Action
    async getBusinessPermissionsGroups() {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            ApiService.get(PERMISSION_API.GET_PERMISSION_GROUPS_LIST)
              .then((res) => {
                  const data = res.data.data || [];
                  resolve(data.map(g => new BusinessPermissionGroup(g)));
              }).catch((error) => {
                resolve([]);
            })
        })
    }

    @Action
    async getPermissionGroupUsersList() {
        ApiService.setHeader();
        //
        return new Promise((resolve, reject) => {
            ApiService.post(API_TEAMS.GET_TEAM_STANDARD_USERS, null)
              .then(res => {
                  const users: any[] = res.data?.data || [];
                  resolve(users.map(u => new UserModelMin(u)));
              })
              .catch(err => {
                  console.log("err = ", err);
                  NotificationService.swalError(err);
                  reject();
              });
        });
    }
}
